import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import uniq from 'lodash/uniq';
import { JIRA_STATUSES_ORDER } from 'utils/constants';
import TicketListItems from 'routes/Reports/routes/DailyRecap/components/TicketListItems';

const Wrapper = styled.div`
  li {
    font-size: 0.875rem;
  }
`;

const propTypes = {};

const defaultProps = {};

function TicketStatusAndTransitions({ data, jiraLinks }) {
  const { issuesInSprint, issuesOutsideSprint, completedIssuesOutsideSprint } = data;

  const statuses = uniq([
    ...Object.keys(issuesInSprint),
    ...Object.keys(issuesOutsideSprint),
    ...Object.keys(completedIssuesOutsideSprint),
  ]);

  const sortedStatuses = statuses.sort((a, b) => {
    const indexA = JIRA_STATUSES_ORDER.indexOf(a.toLowerCase()) || -1;
    const indexB = JIRA_STATUSES_ORDER.indexOf(b.toLowerCase()) || -1;

    return indexA - indexB;
  });

  return (
    <Wrapper>
      {sortedStatuses.map((status) => (
        <ul key={status}>
          <TicketListItems status={status} jiraLinks={jiraLinks} issues={issuesInSprint[status]} />
          <TicketListItems status={status} jiraLinks={jiraLinks} issues={issuesOutsideSprint[status]} showAsterisks />
          <TicketListItems
            status={status}
            jiraLinks={jiraLinks}
            issues={completedIssuesOutsideSprint[status]}
            showAsterisks
          />
        </ul>
      ))}
    </Wrapper>
  );
}

TicketStatusAndTransitions.propTypes = propTypes;
TicketStatusAndTransitions.defaultProps = defaultProps;

export default TicketStatusAndTransitions;

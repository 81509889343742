import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

const Table = styled.table`
  border-radius: 3px;
  table-layout: fixed;

  thead {
    th {
      background-color: #f4f1ef;
      color: ${(props) => props.theme.subTitleColor};
      font-family: ${(props) => props.theme.titleFontFamily};
      font-size: 14px;
      font-weight: 500;
      line-height: 1.07;
      letter-spacing: 0.4px;
      text-align: center;
      text-transform: uppercase;
      padding: 15px 28px;
    }
  }

  th,
  td {
    border: solid 1px #e1e1e1;
    text-align: center;
  }

  td {
    background: #ffffff;
  }
`;

const LabelCell = styled.td`
  font-size: 14px;
  color: #808080;
`;

const ValueCell = styled.td`
  font-size: 21px;
  color: ${(props) => props.theme.primaryFontColor};
  font-family: ${(props) => props.theme.titleFontFamily};
`;

function StatTable({ title, data }) {
  const numCols = data.length;
  const labels = [];
  const values = [];

  data.forEach(({ label, value }, index) => {
    labels.push(<LabelCell key={index}>{label}</LabelCell>);
    values.push(<ValueCell key={index}>{value}</ValueCell>);
  });

  return (
    <Table>
      <thead>
        <tr>
          <th colSpan={numCols}>{title}</th>
        </tr>
      </thead>
      <tbody>
        <tr>{labels}</tr>
        <tr>{values}</tr>
      </tbody>
    </Table>
  );
}

StatTable.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default StatTable;

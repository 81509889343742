import LoadingIndicator from 'components/LoadingIndicator';
import Banner from 'routes/ProjectPage/Banner';
import BannerStat from 'routes/ProjectPage/BannerStat';
import ProjectMeta from 'routes/ProjectPage/ProjectMeta';
import Toolbar from 'routes/ProjectPage/Toolbar';
import React from 'react';
import { Cell, Grid, GridContainer } from 'react-foundation';
import { FormattedDate } from 'react-intl';
import Currency from 'components/Currency/Currency';
import NumberStat from 'components/NumberStat/NumberStat';
import Percentage from 'components/Percentage/Percentage';
import { projectShape } from 'utils/shapes/project';
import { projectOverviewStatsShape } from 'utils/shapes/stats';

function OverviewStats({ project, overviewStats }) {
  const hasBudget = project.currentBudget > 0;
  const hasJira = !!project.jiraProject;
  return (
    <div>
      <Toolbar>
        <GridContainer>
          <ul>
            <li>
              <ProjectMeta
                name='Project Type'
                value={(project['budgetType'] && project['budgetType']['name']) || 'n/a'}
              />
            </li>
            <li>
              <ProjectMeta name='Hourly Rate' value={<Currency value={project.hourlyRate} />} />
            </li>
            <li>
              <ProjectMeta
                name='Target Launch'
                value={
                  project.targetLaunchDate ? (
                    <FormattedDate value={project.targetLaunchDate} year='numeric' month='long' day='2-digit' />
                  ) : (
                    'n/a'
                  )
                }
              />
            </li>
            <li>
              <ProjectMeta
                name='Initial SOW Budget'
                value={
                  <span>
                    <NumberStat value={project.budget} suffix={' hours'} />
                    {' ('}
                    <Currency value={project.budget * project.hourlyRate} />
                    {')'}
                  </span>
                }
              />
            </li>
            <li>
              <ProjectMeta
                name='Current Budget'
                value={
                  <span>
                    <NumberStat value={project.currentBudget} suffix={' hours'} />
                    {' ('}
                    <Currency value={project.currentBudget * project.hourlyRate} />
                    {')'}
                  </span>
                }
              />
            </li>
          </ul>
        </GridContainer>
      </Toolbar>
      <Banner>
        <GridContainer>
          {overviewStats ? (
            <Grid gutters='margin'>
              <Cell small={3}>
                <BannerStat
                  title={`Budget Used (${hasBudget ? '%' : 'hours'})`}
                  main={
                    hasBudget ? (
                      <Percentage value={overviewStats.budget_used.hours / project.currentBudget} />
                    ) : (
                      <NumberStat value={overviewStats.budget_used.hours} />
                    )
                  }
                  secondary={<Currency value={overviewStats.budget_used.dollars} />}
                />
              </Cell>
              <Cell small={3}>
                <BannerStat
                  title='Budget Remaining (%)'
                  main={
                    (hasBudget && (
                      <Percentage
                        value={(project.currentBudget - overviewStats.budget_used.hours) / project.currentBudget}
                      />
                    )) ||
                    'n/a'
                  }
                  secondary={
                    (hasBudget && (
                      <NumberStat value={project.currentBudget - overviewStats.budget_used.hours} suffix={' hours'} />
                    )) ||
                    'n/a'
                  }
                />
              </Cell>
              <Cell small={3}>
                <BannerStat
                  title='Project Complete (%)'
                  main={
                    (hasJira && (
                      <Percentage
                        value={
                          overviewStats.story_points_total
                            ? overviewStats.story_points_completed / overviewStats.story_points_total
                            : 0
                        }
                      />
                    )) ||
                    'n/a'
                  }
                  secondary={
                    (hasJira && (
                      <span>
                        <NumberStat value={overviewStats.story_points_completed} />
                        {' / '}
                        <NumberStat value={overviewStats.story_points_total} />
                        {' POINTS'}
                      </span>
                    )) ||
                    'n/a'
                  }
                />
              </Cell>
              <Cell small={3}>
                <BannerStat
                  title='Hours Needed'
                  main={<NumberStat value={overviewStats.budget_needed.hours} />}
                  secondary={<Currency value={overviewStats.budget_needed.dollars} />}
                />
              </Cell>
            </Grid>
          ) : (
            <LoadingIndicator />
          )}
        </GridContainer>
      </Banner>
    </div>
  );
}

OverviewStats.propTypes = {
  project: projectShape,
  overviewStats: projectOverviewStatsShape,
};

export default OverviewStats;

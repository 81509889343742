import React from 'react';
import PropTypes from 'prop-types';
import { Button, Cell, Grid } from 'react-foundation';
import size from 'lodash/size';
import moment from 'moment';
import maxBy from 'lodash/maxBy';
import FontAwesome from 'react-fontawesome';

const STATE_SENT = 'sent';
const STATE_RECEIVED = 'received';

const STATE_LABELS = {
  [STATE_SENT]: 'Queued & waiting for other jobs to finish',
  [STATE_RECEIVED]: 'Sync in progress',
};

const STATE_TIME_LABELS = {
  [STATE_SENT]: 'Queued',
  [STATE_RECEIVED]: 'Started',
};

function JobSyncButton({ label, onResync, syncStatus, loading, children }) {
  let lastSync = null;
  if (syncStatus && syncStatus.data && size(syncStatus.data.items) > 0) {
    lastSync = maxBy(Object.values(syncStatus.data.items), (item) => item.received || item.sent);
  }

  const isDisabled =
    loading || !syncStatus || syncStatus.loading || (syncStatus.data && size(syncStatus.data.items) > 0);

  return (
    <div>
      <h5>
        {label}
        {lastSync && (
          <small>
            {' '}
            {STATE_LABELS[lastSync.state]}.{' '}
            <span>
              {' '}
              {STATE_TIME_LABELS[lastSync.state]}: {moment(lastSync.received || lastSync.sent).format('MM/DD hh:mm A')}
            </span>
          </small>
        )}
      </h5>
      <Button onClick={onResync} isDisabled={isDisabled}>
        {isDisabled && (
          <span>
            <FontAwesome name={'spinner'} spin />{' '}
          </span>
        )}
        {children}
      </Button>
    </div>
  );
}

JobSyncButton.propTypes = {
  label: PropTypes.string.isRequired,
  onResync: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  syncStatus: PropTypes.shape({
    loading: PropTypes.bool,
    error: PropTypes.string,
    data: PropTypes.shape({
      items: PropTypes.objectOf(
        PropTypes.shape({
          received: PropTypes.string,
          sent: PropTypes.string,
        })
      ),
    }),
  }),
  children: PropTypes.any,
};

export default JobSyncButton;

import fetch from 'utils/fetch';
import { push } from 'react-router-redux';
import { authenticate, setAuthToken } from 'utils/auth';

const namespace = 'app/LoginPage';
const loginPath = '/login_check';

export const REQUEST_LOADING = `${namespace}/REQUEST_LOADING`;
export const REQUEST_ERROR = `${namespace}/REQUEST_ERROR`;
export const REQUEST_SUCCESS = `${namespace}/REQUEST_SUCCESS`;
export const RESET = `${namespace}/RESET`;

export function error(error) {
  return { type: REQUEST_ERROR, error };
}

export function loading(loading) {
  return { type: REQUEST_LOADING, loading };
}

export function success(payload) {
  return { type: REQUEST_SUCCESS, payload };
}

export function reset() {
  return { type: RESET };
}

export function loginUser(username, password) {
  return (dispatch) => {
    dispatch(loading(true));
    dispatch(error(null));

    return authenticate(username, password)
      .then((token) => {
        dispatch(loading(false));
        dispatch(success(token));
        setAuthToken(token);
        dispatch(push('/'));
      })
      .catch((e) => {
        dispatch(loading(false));

        const message = e.message === 'Unauthorized' ? 'Bad credentials' : e.message;
        dispatch(error(message));
      });
  };
}

import orderBy from 'lodash/orderBy';
import NumberStat from 'components/NumberStat/NumberStat';
import PropTypes from 'prop-types';
import { personBreakdownShape } from 'utils/shapes/stats';
import React from 'react';

function HoursByPerson({ breakdowns }) {
  return (
    <ul>
      {orderBy(breakdowns, [(breakdown) => breakdown.billed.hours], ['desc']).map((breakdown) => (
        <li key={breakdown.name}>
          <strong>{breakdown.name}: </strong>
          <NumberStat
            value={breakdown.billed.hours}
            suffix={' hours'}
            minimumFractionDigits={2}
            maximumFractionDigits={4}
          />
        </li>
      ))}
    </ul>
  );
}

HoursByPerson.propTypes = {
  breakdowns: PropTypes.arrayOf(personBreakdownShape),
};

export default HoursByPerson;

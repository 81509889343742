import { string, bool, shape, arrayOf, number } from 'prop-types';

export const personShape = shape({
  avatar_url: string,
  first_name: string,
  last_name: string,
});

export const userShape = shape({
  person: personShape,
});

export const personTypeShape = shape({
  id: number,
  name: string,
  description: string,
  company_employee: bool,
});

export const clientContactShape = shape({
  id: number,
  title: string,
  personType: personTypeShape,
  firstName: string,
  lastName: string,
  email: string,
  avatarUrl: string,
});

const roles = {
  ROLE_ADMIN: 'ROLE_ADMIN',
  ROLE_ALLOWED_TO_SWITCH: 'ROLE_ALLOWED_TO_SWITCH',
  ROLE_APP_ADMIN_BUDGET_ADJUSTMENT_TYPE_LIST: 'ROLE_APP_ADMIN_BUDGET_ADJUSTMENT_TYPE_LIST',
  ROLE_APP_ADMIN_BUDGET_ADJUSTMENT_TYPE_READER: 'ROLE_APP_ADMIN_BUDGET_ADJUSTMENT_TYPE_READER',
  ROLE_APP_ADMIN_BUDGET_ADJUSTMENT_TYPE_VIEW: 'ROLE_APP_ADMIN_BUDGET_ADJUSTMENT_TYPE_VIEW',
  ROLE_APP_ADMIN_BUDGET_TYPE_LIST: 'ROLE_APP_ADMIN_BUDGET_TYPE_LIST',
  ROLE_APP_ADMIN_BUDGET_TYPE_READER: 'ROLE_APP_ADMIN_BUDGET_TYPE_READER',
  ROLE_APP_ADMIN_BUDGET_TYPE_VIEW: 'ROLE_APP_ADMIN_BUDGET_TYPE_VIEW',
  ROLE_APP_ADMIN_CLIENT_CREATE: 'ROLE_APP_ADMIN_CLIENT_CREATE',
  ROLE_APP_ADMIN_CLIENT_EDIT: 'ROLE_APP_ADMIN_CLIENT_EDIT',
  ROLE_APP_ADMIN_CLIENT_EDITOR: 'ROLE_APP_ADMIN_CLIENT_EDITOR',
  ROLE_APP_ADMIN_CLIENT_LIST: 'ROLE_APP_ADMIN_CLIENT_LIST',
  ROLE_APP_ADMIN_CLIENT_READER: 'ROLE_APP_ADMIN_CLIENT_READER',
  ROLE_APP_ADMIN_CLIENT_TYPE_LIST: 'ROLE_APP_ADMIN_CLIENT_TYPE_LIST',
  ROLE_APP_ADMIN_CLIENT_TYPE_READER: 'ROLE_APP_ADMIN_CLIENT_TYPE_READER',
  ROLE_APP_ADMIN_CLIENT_TYPE_VIEW: 'ROLE_APP_ADMIN_CLIENT_TYPE_VIEW',
  ROLE_APP_ADMIN_CLIENT_VIEW: 'ROLE_APP_ADMIN_CLIENT_VIEW',
  ROLE_APP_ADMIN_JIRA_PROJECT_BOARD_LIST: 'ROLE_APP_ADMIN_JIRA_PROJECT_BOARD_LIST',
  ROLE_APP_ADMIN_JIRA_PROJECT_BOARD_READER: 'ROLE_APP_ADMIN_JIRA_PROJECT_BOARD_READER',
  ROLE_APP_ADMIN_JIRA_PROJECT_BOARD_VIEW: 'ROLE_APP_ADMIN_JIRA_PROJECT_BOARD_VIEW',
  ROLE_APP_ADMIN_JIRA_PROJECT_LIST: 'ROLE_APP_ADMIN_JIRA_PROJECT_LIST',
  ROLE_APP_ADMIN_JIRA_PROJECT_READER: 'ROLE_APP_ADMIN_JIRA_PROJECT_READER',
  ROLE_APP_ADMIN_JIRA_PROJECT_VIEW: 'ROLE_APP_ADMIN_JIRA_PROJECT_VIEW',
  ROLE_APP_ADMIN_MASTER_SERVICE_AGREEMENT_CREATE: 'ROLE_APP_ADMIN_MASTER_SERVICE_AGREEMENT_CREATE',
  ROLE_APP_ADMIN_MASTER_SERVICE_AGREEMENT_EDIT: 'ROLE_APP_ADMIN_MASTER_SERVICE_AGREEMENT_EDIT',
  ROLE_APP_ADMIN_MASTER_SERVICE_AGREEMENT_EDITOR: 'ROLE_APP_ADMIN_MASTER_SERVICE_AGREEMENT_EDITOR',
  ROLE_APP_ADMIN_MASTER_SERVICE_AGREEMENT_LIST: 'ROLE_APP_ADMIN_MASTER_SERVICE_AGREEMENT_LIST',
  ROLE_APP_ADMIN_MASTER_SERVICE_AGREEMENT_READER: 'ROLE_APP_ADMIN_MASTER_SERVICE_AGREEMENT_READER',
  ROLE_APP_ADMIN_MASTER_SERVICE_AGREEMENT_VIEW: 'ROLE_APP_ADMIN_MASTER_SERVICE_AGREEMENT_VIEW',
  ROLE_APP_ADMIN_PERSON_BIOGRAPHY_CREATE: 'ROLE_APP_ADMIN_PERSON_BIOGRAPHY_CREATE',
  ROLE_APP_ADMIN_PERSON_BIOGRAPHY_EDIT: 'ROLE_APP_ADMIN_PERSON_BIOGRAPHY_EDIT',
  ROLE_APP_ADMIN_PERSON_BIOGRAPHY_EDITOR: 'ROLE_APP_ADMIN_PERSON_BIOGRAPHY_EDITOR',
  ROLE_APP_ADMIN_PERSON_BIOGRAPHY_LIST: 'ROLE_APP_ADMIN_PERSON_BIOGRAPHY_LIST',
  ROLE_APP_ADMIN_PERSON_BIOGRAPHY_READER: 'ROLE_APP_ADMIN_PERSON_BIOGRAPHY_READER',
  ROLE_APP_ADMIN_PERSON_BIOGRAPHY_VIEW: 'ROLE_APP_ADMIN_PERSON_BIOGRAPHY_VIEW',
  ROLE_APP_ADMIN_PERSON_CREATE: 'ROLE_APP_ADMIN_PERSON_CREATE',
  ROLE_APP_ADMIN_PERSON_EDIT: 'ROLE_APP_ADMIN_PERSON_EDIT',
  ROLE_APP_ADMIN_PERSON_EDITOR: 'ROLE_APP_ADMIN_PERSON_EDITOR',
  ROLE_APP_ADMIN_PERSON_LIST: 'ROLE_APP_ADMIN_PERSON_LIST',
  ROLE_APP_ADMIN_PERSON_READER: 'ROLE_APP_ADMIN_PERSON_READER',
  ROLE_APP_ADMIN_PERSON_TYPE_LIST: 'ROLE_APP_ADMIN_PERSON_TYPE_LIST',
  ROLE_APP_ADMIN_PERSON_TYPE_READER: 'ROLE_APP_ADMIN_PERSON_TYPE_READER',
  ROLE_APP_ADMIN_PERSON_TYPE_VIEW: 'ROLE_APP_ADMIN_PERSON_TYPE_VIEW',
  ROLE_APP_ADMIN_PERSON_VIEW: 'ROLE_APP_ADMIN_PERSON_VIEW',
  ROLE_APP_ADMIN_PROJECT_ASSIGNMENT_CREATE: 'ROLE_APP_ADMIN_PROJECT_ASSIGNMENT_CREATE',
  ROLE_APP_ADMIN_PROJECT_ASSIGNMENT_EDIT: 'ROLE_APP_ADMIN_PROJECT_ASSIGNMENT_EDIT',
  ROLE_APP_ADMIN_PROJECT_ASSIGNMENT_EDITOR: 'ROLE_APP_ADMIN_PROJECT_ASSIGNMENT_EDITOR',
  ROLE_APP_ADMIN_PROJECT_ASSIGNMENT_LIST: 'ROLE_APP_ADMIN_PROJECT_ASSIGNMENT_LIST',
  ROLE_APP_ADMIN_PROJECT_ASSIGNMENT_READER: 'ROLE_APP_ADMIN_PROJECT_ASSIGNMENT_READER',
  ROLE_APP_ADMIN_PROJECT_ASSIGNMENT_VIEW: 'ROLE_APP_ADMIN_PROJECT_ASSIGNMENT_VIEW',
  ROLE_APP_ADMIN_PROJECT_CREATE: 'ROLE_APP_ADMIN_PROJECT_CREATE',
  ROLE_APP_ADMIN_PROJECT_EDIT: 'ROLE_APP_ADMIN_PROJECT_EDIT',
  ROLE_APP_ADMIN_PROJECT_EDITOR: 'ROLE_APP_ADMIN_PROJECT_EDITOR',
  ROLE_APP_ADMIN_PROJECT_LIST: 'ROLE_APP_ADMIN_PROJECT_LIST',
  ROLE_APP_ADMIN_PROJECT_READER: 'ROLE_APP_ADMIN_PROJECT_READER',
  ROLE_APP_ADMIN_PROJECT_ROLE_CREATE: 'ROLE_APP_ADMIN_PROJECT_ROLE_CREATE',
  ROLE_APP_ADMIN_PROJECT_ROLE_EDIT: 'ROLE_APP_ADMIN_PROJECT_ROLE_EDIT',
  ROLE_APP_ADMIN_PROJECT_ROLE_EDITOR: 'ROLE_APP_ADMIN_PROJECT_ROLE_EDITOR',
  ROLE_APP_ADMIN_PROJECT_ROLE_LIST: 'ROLE_APP_ADMIN_PROJECT_ROLE_LIST',
  ROLE_APP_ADMIN_PROJECT_ROLE_READER: 'ROLE_APP_ADMIN_PROJECT_ROLE_READER',
  ROLE_APP_ADMIN_PROJECT_ROLE_VIEW: 'ROLE_APP_ADMIN_PROJECT_ROLE_VIEW',
  ROLE_APP_ADMIN_PROJECT_VIEW: 'ROLE_APP_ADMIN_PROJECT_VIEW',
  ROLE_APP_ADMIN_RESOURCE_LINK_ALL: 'ROLE_APP_ADMIN_RESOURCE_LINK_ALL',
  ROLE_APP_ADMIN_TAG_LIST: 'ROLE_APP_ADMIN_TAG_LIST',
  ROLE_APP_ADMIN_TAG_READER: 'ROLE_APP_ADMIN_TAG_READER',
  ROLE_APP_ADMIN_TAG_VIEW: 'ROLE_APP_ADMIN_TAG_VIEW',
  ROLE_APP_PERSON_REVENUE_VIEW: 'ROLE_APP_PERSON_REVENUE_VIEW',
  ROLE_APP_PERSON_SALARY_VIEW: 'ROLE_APP_PERSON_SALARY_VIEW',
  ROLE_APP_VIEW_BILLABLE_REPORT: 'ROLE_APP_VIEW_BILLABLE_REPORT',
  ROLE_DIRECTOR: 'ROLE_DIRECTOR',
  ROLE_PROJECT_MANAGER: 'ROLE_PROJECT_MANAGER',
  ROLE_SONATA_ADMIN: 'ROLE_SONATA_ADMIN',
  ROLE_SUPER_ADMIN: 'ROLE_SUPER_ADMIN',
  ROLE_SUPERADMIN: 'ROLE_SUPERADMIN',
  ROLE_USER: 'ROLE_USER',
};

export default roles;

import { gql } from '@apollo/client';

export const CREATE_INVOICE_MUTATION = gql`
  mutation CreateInvoice($createInvoiceInput: CreateInvoiceInput!) {
    createInvoice(createInvoiceInput: $createInvoiceInput) {
      amount
      client {
        id
        name
      }
      client_key
      closed_at
      created_at
      creator {
        id
        name
      }
      currency
      discount
      discount_amount
      due_amount
      due_date
      estimate {
        id
      }
      id
      issue_date
      line_items {
        amount
        description
        id
        kind
        project {
          code
          id
          name
        }
        quantity
        taxed
        taxed2
        unit_price
      }
      notes
      number
      paid_at
      paid_date
      payment_term
      period_end
      period_start
      purchase_order
      recurring_invoice_id
      retainer {
        id
      }
      sent_at
      state
      subject
      tax
      tax2
      tax2_amount
      tax_amount
      updated_at
    }
  }
`;

export default CREATE_INVOICE_MUTATION;

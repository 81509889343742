/**
 * TODO: Move this to live in actions file under src/routes/DashboardPage.
 */

import queryString from 'query-string';
import fetch from '../../utils/fetch';
import { PROJECT_FILTER_ACTIVE, PROJECT_FILTER_INTERNAL, PROJECT_FILTER_KEY } from 'utils/constants';
import { resetData } from 'routes/DashboardPage/actions';

export function error(error) {
  return { type: 'PROJECT_LIST_ERROR', error };
}

export function loading(loading) {
  return { type: 'PROJECT_LIST_LOADING', loading };
}

export function success(data) {
  return { type: 'PROJECT_LIST_SUCCESS', data };
}

export function list(page = '/projects', filters = null) {
  return (dispatch) => {
    dispatch(loading(true));
    dispatch(error(''));
    dispatch(resetData());

    if (filters) {
      // Make a copy of filters.
      const modifiedFilters = Object.assign({}, filters);
      if (modifiedFilters.project) {
        switch (modifiedFilters.project) {
          case PROJECT_FILTER_ACTIVE:
            modifiedFilters.hours = 3;
            break;
          case PROJECT_FILTER_INTERNAL:
            modifiedFilters['budgetType.internal'] = true;
            break;
          case PROJECT_FILTER_KEY:
            modifiedFilters.keyProject = true;
        }

        // Remove the project key from filters.
        delete modifiedFilters.project;
      }

      page += `?${queryString.stringify(modifiedFilters)}`;
    }

    fetch(page)
      .then((response) => response.json())
      .then((data) => {
        dispatch(loading(false));
        dispatch(success(data));
      })
      .catch((e) => {
        dispatch(loading(false));
        dispatch(error(e.message));
      });
  };
}

export function reset() {
  return { type: 'PROJECT_LIST_RESET' };
}

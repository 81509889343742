import React from 'react';
import { Field, FieldArray, reduxForm } from 'redux-form/lib/immutable';
import H3 from 'components/H3';
import moment from 'moment';
import TicketStatusAndTransitions from 'routes/Reports/routes/DailyRecap/components/TicketStatusAndTransitions';
import NumberStat from 'components/NumberStat';
import PropTypes from 'prop-types';
import get from 'lodash/get';

const propTypes = {
  onSubmit: PropTypes.any,
  recapData: PropTypes.any,
  component: PropTypes.func,
  reportFilters: PropTypes.any,
  completedInSprint: PropTypes.any,
  underwayInSprint: PropTypes.any,
  remainingInSprint: PropTypes.any,
  blockedInSprint: PropTypes.any,
  completedOutsideSprint: PropTypes.any,
  dailyStoryPointGoal: PropTypes.any,
  projectStats: PropTypes.any,
  project: PropTypes.any,
};

function DailyRecapForm({
  handleSubmit,
  blockedInSprint,
  completedInSprint,
  completedOutsideSprint,
  component,
  dailyStoryPointGoal,
  onSubmit,
  project,
  projectStats,
  recapData,
  remainingInSprint,
  reportFilters,
  underwayInSprint,
}) {
  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label htmlFor='teamMemberName'>Your Name</label>
        <Field name='teamMemberName' component='input' type='text' />
      </div>
      <div>
        <label htmlFor='clientName'>Client Name</label>
        <Field name='clientName' component='input' type='text' />
      </div>
      <H3>Basic Sprint Overview</H3>
      <div>
        <p>
          The current sprint runs from{' '}
          <mark>
            {recapData.sprint && (
              <span>
                {moment(recapData.sprint.start_date).format('MM/DD/YY')} &ndash;{' '}
                {moment(recapData.sprint.end_date).format('MM/DD/YY')}
              </span>
            )}
          </mark>
          .
          <br />
          The expected velocity of this sprint is{' '}
          <mark>{recapData.sprintStats && recapData.sprintStats.story_points_in_sprint}</mark>.
        </p>
      </div>
      <div>
        <label htmlFor='demoDate'>The demo is currently scheduled for: </label>
        <Field name='demoDate' component='input' type='text' />
      </div>
      <div>
        <label htmlFor='sprintCommitment'>When this sprint is complete the following will be true: </label>
        <Field name='sprintCommitment' component='textarea' aria-describedby='sprintCommitmentHelpText' />
        <p className='help-text' id='sprintCommitmentHelpText'>
          This should be the short version of what this sprint will accomplish. What will this product be able to do at
          the end of the sprint? Fill that in here. If you don’t know it, it means you didn’t complete the sprint vision
          checklist and you need to see Operations for assistance in remedying this immediately.
        </p>
      </div>
      <div>
        <label htmlFor='keyDecisions'>
          <H3>Key decisions from standups and team communications</H3>
        </label>
        <FieldArray name='keyDecisions' component={component} aria-describedby='keyDecisionsHelpText' />
        <p className='help-text' id='keyDecisionsHelpText'>
          Anything that has been closed should be referenced here with information.
          <br />
          Any division of labor with the client or external devs should be noted.
          <br />
          Key budget approvals or timeline changes should be noted here.
          <br />
          Any other big decisions from standups, team meetings, or verbal discussions should be recorded.
          <br />
          If there are none, you can say “There are no key decisions or changes made to note.”
        </p>
      </div>
      <hr />
      <div>
        <label htmlFor='decisionsToBeMade'>
          <H3>Features needing attention / decisions need to be made</H3>
        </label>
        <FieldArray name='decisionsToBeMade' component={component} />
        <p className='help-text' id='decisionsToBeMadeHelpText'>
          Anything that has been noted as a blocker should be referenced here with information.
          <br />
          Any big showstoppers like budget needs should be referenced here.
          <br />
          Any questions people should be thinking about or requiring discussion should be referenced here.
          <br />
          If there are no blockers or needs, say “There are no blockers or needs from you at this time.”
        </p>
      </div>
      <hr />
      <div>
        <label htmlFor='otherUpdates'>
          <H3>Other updates and information to share</H3>
        </label>
        <FieldArray name='otherUpdates' component={component} />
        <p className='help-text' id='otherUpdatesHelpText'>
          Any other information like team outages, upcoming meetings, etc should be noted here.
          <br />
          If there are not other updates, say “No additional updates at this time” here or remove this section.
        </p>
      </div>
      <hr />
      <H3>Current Status and Transitions</H3>
      <p className='help-text'>
        This is the current status of all tickets in <mark>{recapData.sprint && recapData.sprint.name}</mark> as of{' '}
        <mark>{moment().format('MM/DD/YY')}</mark>.
        <br />
        An <strong>***</strong> next to a feature indicates that it has transitioned or been worked on outside of the
        active sprint.
      </p>

      <TicketStatusAndTransitions data={recapData} jiraLinks={reportFilters.showJiraLinks} />

      <hr />

      <H3>SPRINT AND PROJECT HEALTH AND INSIGHTS</H3>
      <ul>
        <li>
          <p>
            <strong>
              We’ve completed or closed <mark>{completedInSprint} storypoints</mark>.
            </strong>
          </p>
          <p className='help-text' id='completedStorypointsHelpText'>
            Based on this, the team is on track / off track. If off-track, provide information about what you will do to
            account for this and correct it. If it’s on track, just note “On Track”
          </p>
          <div>
            <Field
              name='completedStorypointsNotes'
              component='textarea'
              aria-describedby='completedStorypointsHelpText'
              placeholder='Provide details...'
            />
          </div>
        </li>
        <li>
          <p>
            <strong>
              We have <mark>{underwayInSprint} storypoints</mark> underway.
            </strong>
          </p>
          <p className='help-text' id='underwayStorypointsHelpText'>
            Based on this, the team is on track / off track. If off-track, provide information about what you will do to
            account for this and correct it. If it’s on track, just note “On Track”
          </p>
          <div>
            <Field
              name='underwayStorypointsNotes'
              component='textarea'
              aria-describedby='underwayStorypointsHelpText'
              placeholder='Provide details...'
            />
          </div>
        </li>
        <li>
          <p>
            <strong>
              We have <mark>{remainingInSprint} storypoints</mark> left to do in this sprint.
            </strong>
          </p>
          <p className='help-text' id='remainingStorypointsHelpText'>
            Based on this, the team is on track / off track. If off-track, provide information about what you will do to
            account for this and correct it. If it’s on track, just note “On Track”
          </p>
          <div>
            <Field
              name='remainingStorypointsNotes'
              component='textarea'
              aria-describedby='remainingStorypointsHelpText'
              placeholder='Provide details...'
            />
          </div>
        </li>
        <li>
          <p>
            <strong>
              We have <mark>{blockedInSprint} storypoints</mark> BLOCKED and needing attention in this sprint.
            </strong>
          </p>
          <p className='help-text' id='blockedStorypointsHelpText'>
            Details about BLOCKED tickets and features can be found below in the “Needs Attention” section. If it’s on
            track, just note “On Track”
          </p>
          <div>
            <Field
              name='blockedStorypointsNotes'
              component='textarea'
              aria-describedby='blockedStorypointsHelpText'
              placeholder='Provide details...'
            />
          </div>
        </li>
        <li>
          <p>
            <strong>
              The team has completed an additional <mark>{completedOutsideSprint} storypoints</mark> outside of the
              sprint.
            </strong>
          </p>
          <p className='help-text' id='outsideSprintStorypointsHelpText'>
            This should be 0. If it’s 0, remove this section. If it does exist, you should provide a short description
            about why this was completed outside of the sprint.
          </p>
          <div>
            <Field
              name='outsideSprintStorypointsNotes'
              component='textarea'
              aria-describedby='outsideSprintStorypointsHelpText'
              placeholder='Provide details...'
            />
          </div>
        </li>
        <li>
          <p>
            <strong>
              To complete this sprint, the team must move <mark>{dailyStoryPointGoal} storypoints</mark> across the
              board each day.
            </strong>
          </p>
          <p className='help-text' id='dailyMoveStorypointsHelpText'>
            Based on this, the team is on track / off track. If off-track, provide information about what you will do to
            account for this and correct it. If it’s on track, just note “On Track”
          </p>
          <div>
            <Field
              name='dailyMoveStorypointsNotes'
              component='textarea'
              aria-describedby='dailyMoveStorypointsHelpText'
              placeholder='Provide details...'
            />
          </div>
        </li>
        <li>
          <p>
            <strong>
              There are currently{' '}
              <mark>{get(projectStats, 'data.numberOfTicketsUnestimated', 'n/a')} number of tickets</mark> in the
              backlog without estimates.
            </strong>
          </p>
          <p className='help-text' id='unestimatedStorypointsHelpText'>
            This number should be 0. If it isn’t 0, you need to provide information about why and what you’ll be doing
            with this.
          </p>
          <div>
            <Field
              name='unestimatedStorypointsNotes'
              component='textarea'
              aria-describedby='unestimatedStorypointsHelpText'
              placeholder='Provide details...'
            />
          </div>
        </li>
        <li>
          <p>
            <strong>
              The average storypoint estimate in the project is{' '}
              {projectStats && projectStats.data && (
                <mark>
                  <NumberStat
                    value={projectStats.data.storyPointsTotal / projectStats.data.numberOfTicketsTotal}
                    maximumFractionDigits={2}
                  />{' '}
                  storypoints
                </mark>
              )}
              .
            </strong>
          </p>
          <p className='help-text' id='averageEstimateStorypointsHelpText'>
            Note whether this is normal or not. If it’s not normal, note why there is an influx and what this may mean
            for the budget or timeline. FYI, the average storypoint estimate should roughly align with the CPP of the
            project.
          </p>
          <div>
            <Field
              name='averageEstimateStorypointsNotes'
              component='textarea'
              aria-describedby='averageEstimateStorypointsHelpText'
              placeholder='Provide details...'
            />
          </div>
        </li>
        <li>
          <p>
            <strong>
              The current CPP (cost per point) in this project is{' '}
              <mark>{project.health.cost_per_point.toFixed(2)} average hours / storypoint</mark>.
            </strong>
          </p>
          <p className='help-text' id='costPerPointHelpText'>
            Note whether this is healthy. The target for this is 3.5 or less. At the start of a project, 5 may be
            normal. By a couple weeks in, this should shift down to 4 or below. If your project is above 4, your project
            is not healthy and you need to get with Operations immediately to resolve data issues or performance issues.
          </p>
          <div>
            <Field
              name='costPerPointNotes'
              component='textarea'
              aria-describedby='costPerPointHelpText'
              placeholder='Provide details...'
            />
          </div>
        </li>
      </ul>
    </form>
  );
}

DailyRecapForm.propTypes = propTypes;

export default reduxForm({
  form: 'dailyRecap',
  destroyOnUnmount: false,
})(DailyRecapForm);

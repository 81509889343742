import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import routes from './routes';
import Header from 'components/Header';

class App extends Component {
  render() {
    return (
      <div className='App'>
        <Header />
        <Switch>
          {routes}
          <Route render={() => <h1>Page Not Found</h1>} />
        </Switch>
      </div>
    );
  }
}

export default App;

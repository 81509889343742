import useAction from 'hooks/useAction';
import { useEffect } from 'react';
import { getProjects } from 'api/projectApi';

/**
 *
 * @returns {({data: unknown, loading: boolean, error: unknown}|(function(*=): Promise<void>)|*)[]}
 */
export function useProjectList() {
  const [state, action] = useAction(getProjects);

  useEffect(() => {
    action();
  }, [action]);

  return [
    {
      ...state,
      data: state.data ? state.data['hydra:member'] : state.data,
    },
    action,
  ];
}

import React from 'react';
import orderBy from 'lodash/orderBy';
import sumBy from 'lodash/sumBy';
import last from 'lodash/last';
import countBy from 'lodash/countBy';
import toPairs from 'lodash/toPairs';
import maxBy from 'lodash/maxBy';
import head from 'lodash/head';
import BaseNumberStat from 'components/NumberStat/NumberStat';
import BasePercentage from 'components/Percentage/Percentage';
import ReactTable from 'react-table';
import Currency from 'components/Currency/Currency';
import Box from '@material-ui/core/Box';
import LabelAndValue from 'routes/DashboardPage/components/LabelAndValue';
import { makeHtmlTooltipWithStyles } from 'routes/DashboardPage/components/HtmlTooltip';

const HtmlTooltip = makeHtmlTooltipWithStyles({
  tooltip: {
    width: 300,
    maxWidth: 400,
  },
});

function NumberStat({ ...props }) {
  return <BaseNumberStat minimumFractionDigits={2} maximumFractionDigits={2} {...props} />;
}

function Percentage({ ...props }) {
  return <BasePercentage minimumFractionDigits={2} maximumFractionDigits={2} {...props} />;
}

function DataTable({ data, updateData }) {
  const tableData =
    (data &&
      orderBy(data, ['person.firstName', 'person.lastName']).map((row) => {
        const billablePercent = row.expectedBillableHours > 0 ? row.billableHours / row.expectedBillableHours : 0;

        return {
          id: row.person.id,
          '@id': row.person['@id'],
          name: `${row.person.firstName} ${row.person.lastName}`,
          isEmployee: row.person.personType?.company_employee || false,
          billableHoursPossible: row.billableHoursPossible,
          expectedBillableHours: row.expectedBillableHours,
          totalHours: row.totalHours,
          billableHours: row.billableHours,
          billablePercent,
          expectedBillablePercent: row.expectedBillablePercent,
          billablePercentDelta: billablePercent - row.expectedBillablePercent,
          billableHoursDelta: row.expectedBillablePercent * row.expectedBillableHours - row.billableHours,
          note: row.note,
          notes: (row.note && row.note.note) || '',
          allocationPeriods: row.allocationPeriods || [],
          missingHours: Math.max(0, row.billableHoursPossible - row.totalHours),
          timeOffExceptionHours: row.timeOffExceptionHours,
          timeOffPTOHours: row.timeOffPTOHours,
          timeOff: row.timeOffExceptionHours + row.timeOffPTOHours,
          revenue: row.revenue,
          projectRevenues: orderBy(row.projectRevenues, 'revenue', 'desc'),
          avgHourlyRate: row.billableHours > 0 ? row.revenue / row.billableHours : 0,
        };
      })) ||
    [];

  function renderEditable(cellInfo) {
    return (
      <div
        style={{ backgroundColor: '#fafafa' }}
        contentEditable
        suppressContentEditableWarning
        onBlur={(e) => {
          const data = [...tableData];
          data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;

          updateData && updateData(data[cellInfo.index][cellInfo.column.id], data[cellInfo.index], data);
        }}
        dangerouslySetInnerHTML={{
          __html: tableData[cellInfo.index][cellInfo.column.id],
        }}
      />
    );
  }

  const numEmployees = tableData.filter((x) => x.isEmployee).length;
  const sumHoursPossible = sumBy(tableData, 'billableHoursPossible');
  const sumHoursExpected = sumBy(tableData, 'expectedBillableHours');
  const sumBillableHours = sumBy(tableData, 'billableHours');
  const sumBillableHoursDelta = sumBy(tableData, 'billableHoursDelta');
  const sumMissingHours = sumBy(tableData, 'missingHours');
  const sumRevenue = sumBy(tableData, 'revenue');
  const sumPTOHours = sumBy(tableData, 'timeOffPTOHours');
  const sumExceptionHours = sumBy(tableData, 'timeOffExceptionHours');
  const sumHoursTimeOff = sumPTOHours + sumExceptionHours;
  const avgHourlyRate = sumRevenue / sumBillableHours;

  const defaultHoursPossible = parseInt(
    head(maxBy(toPairs(countBy(tableData.map((x) => x.billableHoursPossible))), (x) => last(x)))
  );
  const defaultHoursExpected = parseInt(
    head(maxBy(toPairs(countBy(tableData.map((x) => x.expectedBillableHours))), (x) => last(x)))
  );
  const defaultBillablePercent = parseFloat(
    head(maxBy(toPairs(countBy(tableData.map((x) => x.expectedBillablePercent))), (x) => last(x)))
  );

  const columns = [
    {
      Header: 'Team Member',
      accessor: 'name',
      minWidth: 200,
      Cell: ({ value, original }) => {
        return (
          <div>
            {value}
            <div style={{ lineHeight: '1' }}>
              {original.billableHoursPossible !== defaultHoursPossible && (
                <div>
                  <small>
                    <em>Hours Possible: {original.billableHoursPossible}</em>
                  </small>
                </div>
              )}
              {original.expectedBillableHours !== defaultHoursExpected && (
                <div>
                  <small>
                    <em>Hours Expected: {original.expectedBillableHours}</em>
                  </small>
                </div>
              )}
              {original.expectedBillablePercent !== defaultBillablePercent && (
                <div>
                  <small>
                    <em>
                      Billable % Expected:{' '}
                      <Percentage
                        minimumFractionDigits={0}
                        maximumFractionDigits={0}
                        value={original.expectedBillablePercent}
                      />
                    </em>
                  </small>
                </div>
              )}
            </div>
          </div>
        );
      },
      Footer: (
        <span>
          <strong>TOTALS</strong>
        </span>
      ),
    },
    {
      Header: 'Worked',
      accessor: 'totalHours',
      Cell: NumberStat,
      minWidth: 70,
      Footer: (
        <div style={{ textAlign: 'right' }}>
          <strong>
            <NumberStat value={sumBy(tableData, 'totalHours')} minimumFractionDigits={0} maximumFractionDigits={0} />
          </strong>
        </div>
      ),
    },
    {
      Header: 'Invoicing',
      accessor: 'billableHours',
      Cell: NumberStat,
      minWidth: 70,
      Footer: (
        <div style={{ textAlign: 'right' }}>
          <strong>
            <NumberStat value={sumBillableHours} minimumFractionDigits={0} maximumFractionDigits={0} />
          </strong>
        </div>
      ),
    },
    {
      Header: 'Missing',
      accessor: 'missingHours',
      Cell: NumberStat,
      minWidth: 70,
      Footer: (
        <div style={{ textAlign: 'right' }}>
          <strong>
            <NumberStat value={sumMissingHours} minimumFractionDigits={0} maximumFractionDigits={0} />
          </strong>
        </div>
      ),
    },
    {
      Header: 'Billable %',
      accessor: 'billablePercent',
      Cell: Percentage,
      minWidth: 70,
      Footer: (
        <div style={{ textAlign: 'right' }}>
          <strong>
            <Percentage
              value={sumHoursExpected > 0 ? sumBillableHours / sumHoursExpected : 0}
              minimumFractionDigits={0}
              maximumFractionDigits={0}
            />
          </strong>
        </div>
      ),
    },
    {
      Header: 'Billable Delta Hours',
      accessor: 'billableHoursDelta',
      Cell: NumberStat,
      minWidth: 70,
      Footer: (
        <div style={{ textAlign: 'right' }}>
          <strong>
            <NumberStat value={sumBillableHoursDelta} minimumFractionDigits={0} maximumFractionDigits={0} />
            <br />
            <br />
            <Currency value={sumBillableHoursDelta * 165} minimumFractionDigits={0} maximumFractionDigits={0} />
          </strong>
        </div>
      ),
    },
    {
      Header: 'Revenue',
      accessor: 'revenue',
      Cell: ({ value, original }) => {
        if (!value || original.projectRevenues?.length === 0) {
          return <Currency value={value} />;
        }

        return (
          <HtmlTooltip
            title={
              <>
                {original.projectRevenues.map((projectRevenue) => (
                  <LabelAndValue
                    key={projectRevenue.projectId}
                    label={`${projectRevenue.project_name}:`}
                    labelProps={{ xs: 8 }}
                  >
                    <Currency value={projectRevenue.revenue} />
                  </LabelAndValue>
                ))}
              </>
            }
            placement='right'
            arrow
          >
            <div>
              <Currency value={value} />
            </div>
          </HtmlTooltip>
        );
      },
      minWidth: 70,
      Footer: (
        <div style={{ textAlign: 'right' }}>
          <strong>
            <Currency value={sumRevenue} minimumFractionDigits={0} maximumFractionDigits={0} />
          </strong>
        </div>
      ),
    },
    {
      Header: 'Avg. Hourly Rate',
      accessor: 'avgHourlyRate',
      Cell: Currency,
      minWidth: 70,
      Footer: (
        <div style={{ textAlign: 'right' }}>
          <strong>
            <Currency value={avgHourlyRate} minimumFractionDigits={0} maximumFractionDigits={0} />
          </strong>
        </div>
      ),
    },
    {
      Header: 'Explain the Diff.',
      accessor: 'notes',
      minWidth: 200,
      Cell: renderEditable,
      Footer: (
        <p style={{ whiteSpace: 'normal' }}>
          {
            '<< this is the number of additional billable hours we expected to have based on expectations.. when negative, this number is how muchover expectation. When positive, this number is how short we are. '
          }
        </p>
      ),
    },
    {
      Header: 'Time Off',
      accessor: 'timeOff',
      Cell: NumberStat,
      minWidth: 70,
      Footer: (
        <div style={{ textAlign: 'right' }}>
          <strong>
            <NumberStat value={sumHoursTimeOff} minimumFractionDigits={0} maximumFractionDigits={0} />
          </strong>
        </div>
      ),
    },
  ];

  return (
    <>
      <Box mt={2}>
        <p>
          <strong>Default Hours Possible:</strong> {defaultHoursPossible}
          <br />
          <strong>Default Hours Expected:</strong> {defaultHoursExpected}
          <br />
          <strong>Default Expected Billable %:</strong>{' '}
          <Percentage minimumFractionDigits={0} maximumFractionDigits={0} value={defaultBillablePercent} />
          <br />
          <strong>Total Billable Hours Possible:</strong> {sumHoursPossible}
          <br />
          <strong>Employee Count in Duration:</strong> {numEmployees}
          <br />
          <strong>Total Time Off:</strong>{' '}
          <NumberStat value={sumHoursTimeOff} minimumFractionDigits={0} maximumFractionDigits={2} />
          <br />
        </p>
      </Box>
      <ReactTable
        data={tableData}
        noDataText={'Loading...'}
        columns={columns}
        showPagination={false}
        defaultPageSize={data && data.length}
        getTrGroupProps={(state, rowInfo, column, instance) => ({
          style: { borderBottom: 'none' },
        })}
        getTdProps={(state, rowInfo, column, instance) => {
          const style = {
            borderBottom: '1px solid rgba(0,0,0,0.02)',
          };

          if (column.id !== 'name' && column.id !== 'notes') {
            style.textAlign = 'right';
          }

          // Add yellow text if total hours worked is more than billable possible.
          if (
            column.id === 'totalHours' &&
            rowInfo &&
            rowInfo.row.totalHours > rowInfo.original.billableHoursPossible
          ) {
            style.color = '#f09300';
          }

          if (column.id === 'billablePercent' && rowInfo && rowInfo.original.billableHoursPossible > 0) {
            if (rowInfo.row.billablePercent <= 0.4) {
              style.backgroundColor = '#f4c7c3';
              style.fontWeight = 'bold';
            } else if (rowInfo.row.billablePercent < 0.75) {
              style.backgroundColor = '#fce8b2';
            } else {
              style.backgroundColor = '#b7e1cd';
            }
          }

          if (
            (column.id === 'billablePercentDelta' || column.id === 'billableHoursDelta') &&
            rowInfo &&
            rowInfo.original.billableHoursPossible > 0
          ) {
            style.backgroundColor = rowInfo.row.billableHoursDelta > 0 ? '#f4c7c3' : '#b7e1cd';
            style.fontWeight = rowInfo.row.billableHoursDelta > 0 ? 'bold' : 'normal';
          }

          return { style };
        }}
        getTheadThProps={(state, rowInfo, column, instance) => ({
          style: {
            whiteSpace: 'normal',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            fontWeight: 'bold',
            fontSize: '0.8em',
          },
        })}
        className='-highlight'
      />
    </>
  );
}

export default DataTable;

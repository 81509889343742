import React from 'react';
import ReactDOM from 'react-dom';

/*
 * As of v13.0.0 of react-dates, this project relies on react-with-styles. If you want to continue using
 * CSS stylesheets and classes, there is a little bit of extra set-up required to get things going.
 * As such, you need to import react-dates/initialize to set up class names on our components. This import
 * should go at the top of your application as you won't be able to import any react-dates components without it.
 */
import 'react-dates/initialize';

import './fonts/fonts.css';
import 'foundation-sites/dist/css/foundation.css';
import 'react-dates/lib/css/_datepicker.css';
import 'react-table/react-table.css';
import registerServiceWorker from './registerServiceWorker';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import createHistory from 'history/createBrowserHistory';
import { routerMiddleware, ConnectedRouter } from 'react-router-redux';
import { ThemeProvider } from 'styled-components/macro';
import { syncFetchWithStore } from 'utils/fetch';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client';

// react-intl imports.
import { addLocaleData, IntlProvider } from 'react-intl';
import en from 'react-intl/locale-data/en';

import theme from './theme';
import muiTheme from './muiTheme';

// import reducers
import reducers from './reducers';

import App from './App';
import GlobalStyle from './global-styles';
import { setContext } from '@apollo/client/link/context';
import { getAuthToken } from 'utils/auth';

// Initialize react-intl.
addLocaleData([...en]);

// Initialize Apollo Client.
const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_API,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getAuthToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

// Set up redux store
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let history = createHistory();

const routing = routerMiddleware(history);

const store = createStore(reducers, composeEnhancers(applyMiddleware(routing, thunk)));

// Sync fetch with the redux store.
syncFetchWithStore(store);

// Sync history with the redux store to work with Immutable.js.
// history = syncHistoryWithStore(history, store, {
//   selectLocationState(state) {
//     return state.get('routing').toJS();
//   }
// });

const MOUNT_NODE = document.getElementById('root');
const render = () => {
  ReactDOM.render(
    <IntlProvider locale={'en-us'}>
      <Provider store={store}>
        <ApolloProvider client={client}>
          <ConnectedRouter history={history}>
            <ThemeProvider theme={theme}>
              <MuiThemeProvider theme={muiTheme}>
                <GlobalStyle />
                <App />
              </MuiThemeProvider>
            </ThemeProvider>
          </ConnectedRouter>
        </ApolloProvider>
      </Provider>
    </IntlProvider>,
    MOUNT_NODE
  );
};

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./App'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render();
  });
}

render();

// TODO: Consider adding this back if we can figure out nginx.
// registerServiceWorker();

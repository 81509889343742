import { gql, useQuery } from '@apollo/client';

const GET_PROJECT_QUERY = gql`
  query GetProject($id: Int!) {
    project(id: $id) {
      id
      name
      billingCycle
      harvestProjects {
        billBy
        budget
        budgetBy
        clientId
        code
        costBudget
        costBudgetIncludeExpenses
        createdAt
        createdInHarvestAt
        endsOn
        fee
        harvestId
        hourlyRate
        id
        isActive
        isBillable
        isFixedFee
        name
        notes
        notifyWhenOverBudget
        overBudgetNotificationDate
        overBudgetNotificationPercentage
        showBudgetToAll
        startsOn
        status
        updatedAt
        updatedInHarvestAt
      }
    }
  }
`;

export default function useQueryProject(id) {
  return useQuery(GET_PROJECT_QUERY, {
    variables: {
      id,
    },
  });
}

import { createStructuredSelector } from 'reselect';
import { selectError, selectLoading, selectOverviewStats, selectRetrieved } from 'routes/ProjectPage/selectors';
import { error, getOverviewStats, loading, reset, retrieve } from 'routes/ProjectPage/actions';
import connect from 'react-redux/es/connect/connect';
import { toJS } from 'utils/toJS';
import DailyRecap from '../components';
import { selectDailyRecapFormData, selectDailyRecapStats, selectProjectStats, selectReportFilters } from '../selectors';
import { dailyRecapStatsReset, getDailyRecapStats, setShowJiraLinks } from '../reducer';
import { createDailyRecapEntry, getProjectStats } from 'routes/Reports/routes/DailyRecap/reducer';

const mapStateToProps = createStructuredSelector({
  error: selectError,
  loading: selectLoading,
  project: selectRetrieved,
  overviewStats: selectOverviewStats,
  dailyRecapStats: selectDailyRecapStats,
  reportFilters: selectReportFilters,
  projectStats: selectProjectStats,
  dailyRecapData: selectDailyRecapFormData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    retrieve: (id) => dispatch(retrieve(id)),
    getProjectStats: (id) => dispatch(getProjectStats(id)),
    getOverviewStats: (id) => dispatch(getOverviewStats(id)),
    createDailyRecapEntry: (entry) => dispatch(createDailyRecapEntry(entry)),
    getDailyRecapStats: (id, start, end) => dispatch(getDailyRecapStats(id)),
    reset: () => {
      dispatch(reset());
      dispatch(error(null));
      dispatch(loading(false));
    },
    resetDailyRecapStats: () => dispatch(dailyRecapStatsReset()),
    setShowJiraLinks: (payload) => dispatch(setShowJiraLinks(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(toJS(DailyRecap));

import { createSelector } from 'reselect';

const getListDomain = (state) => state.getIn(['project', 'list']);

export const selectError = createSelector(getListDomain, (list) => list.get('error'));
export const selectLoading = createSelector(getListDomain, (list) => list.get('loading'));
export const selectData = createSelector(getListDomain, (list) => list.get('data'));
export const selectRecentProjects = createSelector(getListDomain, (list) => list.get('recentProjects'));
export const selectRecentProjectIds = createSelector(selectRecentProjects, (recentProjects) =>
  recentProjects.get('recentProjectIds')
);
export const selectCompanyStats = createSelector(getListDomain, (list) => list.get('companyStats'));
export const selectAllProjectStats = createSelector(getListDomain, (list) => list.get('allProjectStats'));
export const selectProjectFilter = createSelector(getListDomain, (list) => list.get('projectFilter'));
export const selectDateFilters = createSelector(getListDomain, (list) => list.get('dateFilters'));
export const selectOrderBy = createSelector(getListDomain, (list) => list.get('orderBy'));
export const selectDashboardView = createSelector(getListDomain, (list) => list.get('dashboardView'));

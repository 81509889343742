import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { GridContainer, TabItem, TabPanel, Tabs, TabsContent } from 'react-foundation';

const TAB_PROJECT_OVERVIEW = 'projectOverviewPanel';
const TAB_FORECASTING = 'forecastingPanel';

DashboardTabs.propTypes = {
  projectOverview: PropTypes.node,
  forecasting: PropTypes.node,
};

function DashboardTabs({ projectOverview, forecasting }) {
  const [activeTab, setActiveTab] = useState(TAB_PROJECT_OVERVIEW);

  const handleTabClick = useCallback((event, tabId) => {
    setActiveTab(tabId);
  }, []);

  return (
    <GridContainer fluid>
      <Tabs data-deep-link='true'>
        <GridContainer>
          <TabItem isActive={activeTab === TAB_PROJECT_OVERVIEW} role='presentation'>
            <a
              href='#projectOverviewPanel'
              onClick={(e) => handleTabClick(e, TAB_PROJECT_OVERVIEW)}
              id='projectOverviewTab'
              role='tab'
              aria-selected={activeTab === TAB_PROJECT_OVERVIEW ? 'true' : 'false'}
              aria-controls='projectOverviewPanel'
            >
              Project Overview
            </a>
          </TabItem>
          <TabItem isActive={activeTab === TAB_FORECASTING} role='presentation'>
            <a
              href='#forecastingPanel'
              onClick={(e) => handleTabClick(e, TAB_FORECASTING)}
              id='forecastingTab'
              role='tab'
              aria-selected={activeTab === TAB_FORECASTING ? 'true' : 'false'}
              aria-controls='forecastingPanel'
            >
              Forecasting
            </a>
          </TabItem>
        </GridContainer>
      </Tabs>
      <TabsContent>
        <TabPanel
          isActive={activeTab === TAB_PROJECT_OVERVIEW}
          id='projectOverviewPanel'
          role='tabpanel'
          aria-labelledby='projectOverviewTab'
          aria-hidden={activeTab === TAB_PROJECT_OVERVIEW ? undefined : 'true'}
        >
          {projectOverview}
        </TabPanel>
        <TabPanel
          isActive={activeTab === TAB_FORECASTING}
          id='forecastingPanel'
          role='tabpanel'
          aria-labelledby='forecastingTab'
          aria-hidden={activeTab === TAB_FORECASTING ? undefined : 'true'}
        >
          {forecasting}
        </TabPanel>
      </TabsContent>
    </GridContainer>
  );
}

export default DashboardTabs;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { toJS } from 'utils/toJS';
import { connect } from 'react-redux';
import { setDateFilters, setDatePreset } from 'routes/DashboardPage/actions';
import { selectDateFilters } from 'routes/DashboardPage/selectors';
import DateRangeFilter from 'components/DateRangeFilter';

const mapStateToProps = createStructuredSelector({
  filters: selectDateFilters,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setDateFilters: (startDate, endDate) => dispatch(setDateFilters(startDate, endDate)),
    setDatePreset: (preset) => dispatch(setDatePreset(preset)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(toJS(DateRangeFilter));

import React from 'react';
import styled from 'styled-components/macro';

const StyledDivider = styled.div`
  height: 1px;
  border-top: solid 2px #f4f1ef;
  margin-bottom: 1rem;
  //border-bottom: solid 2px #f4f1ef;
`;

export default function Divider() {
  return <StyledDivider />;
}

import moment from 'moment';

export const RANGE_PRESET_THIS_SEMI_MONTH = 'this_semi_month';
export const RANGE_PRESET_LAST_SEMI_MONTH = 'last_semi_month';
export const RANGE_PRESET_THIS_MONTH = 'this_month';
export const RANGE_PRESET_LAST_MONTH = 'last_month';
export const RANGE_PRESET_THIS_QUARTER = 'this_quarter';
export const RANGE_PRESET_LAST_QUARTER = 'last_quarter';
export const RANGE_PRESET_THIS_YEAR = 'this_year';
export const RANGE_PRESET_LAST_YEAR = 'last_year';
export const RANGE_PRESET_CUSTOM = 'custom';

function getDateRangeByUnit(unit, referenceDate) {
  const start = moment(referenceDate).startOf(unit);
  const end = moment(referenceDate).endOf(unit);

  return {
    startDate: start.format('YYYY-MM-DD'),
    endDate: end.format('YYYY-MM-DD'),
  };
}

function formatDateRangeValues(value, format = 'YYYY-MM-DD') {
  const { startDate, endDate } = value || {};

  return {
    startDate: moment(startDate).format(format),
    endDate: moment(endDate).format(format),
  };
}

const RANGE_PRESET_FUNCTIONS = {
  [RANGE_PRESET_THIS_SEMI_MONTH]: (referenceDate) => formatDateRangeValues(getSemimonthRange(referenceDate)),
  [RANGE_PRESET_LAST_SEMI_MONTH]: (referenceDate) => formatDateRangeValues(getPreviousSemimonthRange(referenceDate)),
  [RANGE_PRESET_THIS_MONTH]: (referenceDate) => getDateRangeByUnit('month', referenceDate),
  [RANGE_PRESET_LAST_MONTH]: (referenceDate) => getDateRangeByUnit('month', moment(referenceDate).subtract(1, 'month')),
  [RANGE_PRESET_THIS_QUARTER]: (referenceDate) => getDateRangeByUnit('quarter', referenceDate),
  [RANGE_PRESET_LAST_QUARTER]: (referenceDate) =>
    getDateRangeByUnit('quarter', moment(referenceDate).subtract(1, 'quarter')),
  [RANGE_PRESET_THIS_YEAR]: (referenceDate) => getDateRangeByUnit('year', referenceDate),
  [RANGE_PRESET_LAST_YEAR]: (referenceDate) => getDateRangeByUnit('year', moment(referenceDate).subtract(1, 'year')),
  [RANGE_PRESET_CUSTOM]: (referenceDate) => getDateRangeByUnit('month', referenceDate),
};

export function getPresetRange(preset, referenceDate) {
  if (!RANGE_PRESET_FUNCTIONS[preset]) {
    throw new Error(`Unknown date range preset "preset"`);
  }

  return RANGE_PRESET_FUNCTIONS[preset](referenceDate);
}

export function getSemimonthRange(referenceDate) {
  const end = moment(referenceDate);
  const start = end.clone();

  if (end.date() > 15) {
    start.date(16);
    end.endOf('month');
  } else {
    end.date(15);
    start.startOf('month');
  }

  return {
    startDate: start.toISOString(),
    endDate: end.toISOString(),
  };
}

export function getPreviousSemimonthRange(referenceDate) {
  const end = moment(referenceDate);
  const start = end.clone();

  if (end.date() > 15) {
    start.date(1);
    end.date(15);
  } else {
    end.startOf('month').subtract(1, 'month').endOf('month');
    start.startOf('month').subtract(1, 'month').date(16);
  }

  return {
    startDate: start.toISOString(),
    endDate: end.toISOString(),
  };
}

export function getNextSemimonthRange(referenceDate) {
  const end = moment(referenceDate);
  const start = end.clone();

  if (end.date() > 15) {
    start.startOf('month').add(1, 'month');
    end.startOf('month').add(1, 'month').date(15);
  } else {
    start.date(16);
    end.endOf('month');
  }

  return {
    startDate: start.toISOString(),
    endDate: end.toISOString(),
  };
}

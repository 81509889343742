import fetch from 'utils/fetch';

const namespace = 'app/InvoicesContainer';
const collectionPath = '/projects/:id/invoices';

export const REQUEST_LOADING = `${namespace}/REQUEST_LOADING`;
export const REQUEST_SAVING = `${namespace}/REQUEST_SAVING`;
export const REQUEST_ERROR = `${namespace}/REQUEST_ERROR`;
export const REQUEST_SUCCESS = `${namespace}/REQUEST_SUCCESS`;
export const RESET = `${namespace}/RESET`;

export function error(error) {
  return { type: REQUEST_ERROR, error };
}

export function loading(loading) {
  return { type: REQUEST_LOADING, loading };
}

export function success(payload) {
  return { type: REQUEST_SUCCESS, payload };
}

export function reset() {
  return { type: RESET };
}

export function load(id) {
  return (dispatch) => {
    dispatch(loading(true));

    return fetch(collectionPath.replace(':id', id))
      .then((response) => response.json())
      .then((data) => {
        dispatch(loading(false));
        dispatch(success(data['hydra:member']));
      })
      .catch((e) => {
        dispatch(loading(false));
        dispatch(error(e.message));
      });
  };
}

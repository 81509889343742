import styled from 'styled-components/macro';
import { Cell, Grid, GridContainer, Link as ButtonLink } from 'react-foundation';
import { darken } from 'polished';
import Banner from 'routes/ProjectPage/Banner';
import H1 from 'components/H1';
import H3 from 'components/H3';
import { API_HOST } from 'config/_entrypoint';
import React from 'react';

const Button = styled(ButtonLink)`
  & {
    width: 15rem;
    margin-bottom: 0.4rem;
    background-color: #df1e36;
    color: white;
    font-weight: 700;

    &:hover {
      background-color: ${darken(0.1, '#df1e36')};
    }
  }
`;

export default function QuickActionsBlock() {
  return (
    <Banner tall>
      <GridContainer>
        <H1>QUICK ACTIONS</H1>
        <Grid gutters={'margin'}>
          <Cell small={4}>
            <H3>Operation Reports</H3>
            <Button href={'/reports/billable-report/monthly'}>Monthly Billable Report</Button>
          </Cell>

          <Cell small={4}>
            <H3>Project Reports</H3>
            <Button href={'/reports/daily-recap'}>Daily Recaps</Button>
            <Button href={'/reports/budget-summary'}>Budget & Status Summaries</Button>
          </Cell>

          <Cell small={4}>
            <H3>Admin Actions</H3>
            <Button href={`${API_HOST}/admin`} target='_blank'>
              Manage Admin Panel
            </Button>
            <Button>Sync Harvest Data</Button>
            <Button>Sync JIRA Data</Button>
          </Cell>
        </Grid>
      </GridContainer>
    </Banner>
  );
}

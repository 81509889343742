import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/styles';

export const htmlTooltipStyles = (theme) => ({
  tooltip: {
    backgroundColor: 'rgba(53,53,53,0.99)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    padding: '.8rem',
  },
  arrow: {
    color: 'rgba(53,53,53,0.99)',
  },
});

const HtmlTooltip = withStyles(htmlTooltipStyles)(Tooltip);

export const makeHtmlTooltipWithStyles = (styles) => {
  return withStyles((theme) => {
    const baseStyles = htmlTooltipStyles(theme);
    return {
      ...baseStyles,
      ...styles,
      tooltip: {
        ...baseStyles.tooltip,
        ...(styles.tooltip || {}),
      },
      arrow: {
        ...baseStyles.arrow,
        ...(styles.arrow || {}),
      },
    };
  })(Tooltip);
};

export default HtmlTooltip;

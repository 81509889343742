import { fromJS } from 'immutable';
import fetch from 'utils/fetch';

export const HARVEST_SYNC_JOB_KEY_ALL = 'harvest_sync_ALL';
export const JIRA_SYNC_JOB_KEY_ALL = 'jira_sync_ALL';

const SYNC_STATUS_REQUEST = 'app/Admin/SYNC_STATUS_REQUEST';
const SYNC_STATUS_SUCCESS = 'app/Admin/SYNC_STATUS_SUCCESS';
const SYNC_STATUS_ERROR = 'app/Admin/SYNC_STATUS_ERROR';
const RESYNC_HARVEST_REQUEST = 'app/Admin/RESYNC_HARVEST_REQUEST';
const RESYNC_HARVEST_COMPLETE = 'app/Admin/RESYNC_HARVEST_COMPLETE';
const RESYNC_JIRA_REQUEST = 'app/Admin/RESYNC_JIRA_REQUEST';
const RESYNC_JIRA_COMPLETE = 'app/Admin/RESYNC_JIRA_COMPLETE';

export function syncStatusRequest(jobKey) {
  return { type: SYNC_STATUS_REQUEST, payload: { jobKey } };
}

export function syncStatusSuccess(jobKey, data) {
  return { type: SYNC_STATUS_SUCCESS, payload: { jobKey, data } };
}

export function syncStatusError(jobKey, error) {
  return { type: SYNC_STATUS_ERROR, payload: { jobKey, error } };
}

export function resyncHarvestRequest() {
  return { type: RESYNC_HARVEST_REQUEST };
}

export function resyncHarvestComplete(payload) {
  return { type: RESYNC_HARVEST_COMPLETE, payload };
}

export function resyncJiraRequest() {
  return { type: RESYNC_JIRA_REQUEST };
}

export function resyncJiraComplete(payload) {
  return { type: RESYNC_JIRA_COMPLETE, payload };
}

export function getSyncStatus(jobKey) {
  return (dispatch) => {
    dispatch(syncStatusRequest(jobKey));

    return fetch(`/sync/status/${jobKey}`)
      .then((response) => response.json())
      .then((data) => {
        dispatch(syncStatusSuccess(jobKey, data));
      })
      .catch((e) => {
        dispatch(syncStatusError(jobKey, e));
      });
  };
}

function requestResync(source) {
  return fetch(`/sync/${source}`).then((response) => response.json());
}

export function resyncHarvest() {
  return (dispatch) => {
    dispatch(resyncHarvestRequest());

    return requestResync('harvest')
      .then((data) => {
        dispatch(resyncHarvestComplete(data));
        dispatch(getSyncStatus(HARVEST_SYNC_JOB_KEY_ALL));
      })
      .catch((e) => {
        console.error(e);
      });
  };
}

export function resyncJira() {
  return (dispatch) => {
    dispatch(resyncJiraRequest());

    return requestResync('jira')
      .then((data) => {
        dispatch(resyncJiraComplete(data));
        dispatch(getSyncStatus(JIRA_SYNC_JOB_KEY_ALL));
      })
      .catch((e) => {
        console.error(e);
      });
  };
}

const initialState = fromJS({
  syncStatus: {},
  harvestResync: {
    loading: false,
    error: null,
    data: null,
  },
  jiraResync: {
    loading: false,
    error: null,
    data: null,
  },
});

function reducer(state = initialState, action) {
  switch (action.type) {
    case SYNC_STATUS_REQUEST:
      return state.updateIn(['syncStatus', action.payload.jobKey], (value) =>
        fromJS({
          loading: true,
          error: null,
          data: value ? value.get('data') : null,
        })
      );
    case SYNC_STATUS_SUCCESS:
      return state.setIn(
        ['syncStatus', action.payload.jobKey],
        fromJS({
          loading: false,
          error: null,
          data: action.payload.data,
        })
      );
    case SYNC_STATUS_ERROR:
      return state.setIn(
        ['syncStatus', action.payload.jobKey],
        fromJS({
          loading: false,
          error: action.payload.error,
          data: null,
        })
      );
    case RESYNC_HARVEST_REQUEST:
      return state.set(
        'harvestResync',
        fromJS({
          loading: true,
          error: null,
          data: null,
        })
      );
    case RESYNC_HARVEST_COMPLETE:
      return state.set(
        'harvestResync',
        fromJS({
          loading: false,
          error: null,
          data: action.payload,
        })
      );
    case RESYNC_JIRA_REQUEST:
      return state.set(
        'jiraResync',
        fromJS({
          loading: true,
          error: null,
          data: null,
        })
      );
    case RESYNC_JIRA_COMPLETE:
      return state.set(
        'jiraResync',
        fromJS({
          loading: false,
          error: null,
          data: action.payload,
        })
      );
    default:
      return state;
  }
}

export default reducer;

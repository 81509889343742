import React, { useCallback, useEffect, useMemo, useState } from 'react';
import H3 from 'components/H3';
import { Callout, Cell, Colors, Grid, Switch } from 'react-foundation';
import Select from 'react-select';
import styled from 'styled-components/macro';
import Button from 'components/Button';
import moment from 'moment';
import useMutationCreateInvoice from 'hooks/graphql/useMutationCreateInvoice/useMutationCreateInvoice';
import DateRangeSelect from 'components/DateRangeSelect/DateRangeSelect';
import { BILLING_CYCLE_SEMIMONTHLY, PAYMENT_TERM_LABELS, PAYMENT_TERMS } from 'utils/constants';
import { RANGE_PRESET_CUSTOM, RANGE_PRESET_LAST_MONTH, RANGE_PRESET_LAST_SEMI_MONTH } from 'utils/dateUtils';
import useQueryInvoiceNotes from 'hooks/graphql/useQueryInvoiceNotes/useQueryInvoiceNotes';
import ErrorMessage from 'components/ErrorMessage';

const CreateInvoiceContainer = styled.div`
  .basic-multi-select {
    margin-bottom: 1rem;
  }

  .select__input {
    height: auto;
    box-shadow: none;
  }
`;

function formatDateRange(dateRange, preset = RANGE_PRESET_CUSTOM) {
  const start = moment(dateRange?.startDate);
  const end = moment(dateRange?.endDate);

  return `${start.format('M/D')} - ${end.format('M/D')}`;
}

function getBudgetNotes(project, dateRange, invoiceNotes) {
  const combinedNotes = invoiceNotes ? invoiceNotes.map((x) => x.notes).join('. ') : '';
  return `INC: ${combinedNotes}`;
}

const PAYMENT_TERM_OPTIONS = Object.entries(PAYMENT_TERM_LABELS).map(([value, label]) => ({ value, label }));

export default function CreateInvoice({ project }) {
  const { data, loading, error } = useQueryInvoiceNotes({ projectId: project.id });
  const invoiceNotes = data?.invoiceNotes || [];

  const harvestProjects = useMemo(() => project.harvestProjects.map((x) => ({ value: x.harvestId, label: x.name })), [
    project,
  ]);
  const [selectedProjects, setSelectedProjects] = useState(harvestProjects);
  const [issueDate, setIssueDate] = useState(() => moment().format('YYYY-MM-DD'));
  const [modifiedIssueDate, setModifiedIssueDate] = useState(false);
  const [paymentTerm, setPaymentTerm] = useState(PAYMENT_TERMS.Net15);
  const [subject, setSubject] = useState('');
  const [notes, setNotes] = useState('');
  const [dateRange, setDateRange] = useState(null);
  const [includeExpenses, setIncludeExpenses] = useState(true);
  const [includeTime, setIncludeTime] = useState(true);
  const [modifiedSubject, setModifiedSubject] = useState(false);
  const [modifiedNotes, setModifiedNotes] = useState(false);

  const selectedPaymentTerm = useMemo(() => PAYMENT_TERM_OPTIONS.find((x) => x.value === paymentTerm), [paymentTerm]);

  const filteredInvoiceNotes = useMemo(() => {
    if (!dateRange) {
      return [];
    }

    const start = moment(dateRange.startDate);
    const end = moment(dateRange.endDate);
    return invoiceNotes.filter((invoiceNote) => moment(invoiceNote.targetDate).isBetween(start, end, 'day', '[]'));
  }, [invoiceNotes, dateRange]);

  const initialPreset =
    project.billingCycle === BILLING_CYCLE_SEMIMONTHLY ? RANGE_PRESET_LAST_SEMI_MONTH : RANGE_PRESET_LAST_MONTH;

  useEffect(() => {
    if (!modifiedSubject) {
      if (dateRange) {
        const newSubject = `DRAFT: Caxy Invoice for ${project.name} from ${formatDateRange(
          dateRange
        )} // ${getBudgetNotes(project, dateRange, filteredInvoiceNotes)}`;
        setSubject(newSubject.substring(0, 255));
      }
    }

    if (!modifiedNotes) {
      setNotes(getBudgetNotes(project, dateRange, filteredInvoiceNotes));
    }
  }, [project, dateRange, modifiedSubject, modifiedNotes, filteredInvoiceNotes]);

  useEffect(() => {
    if (!modifiedIssueDate && dateRange) {
      setIssueDate(moment(dateRange.endDate).add(1, 'day').format('YYYY-MM-DD'));
    }
  }, [modifiedIssueDate, dateRange]);

  const [createInvoice, { data: createData, loading: createLoading, error: createError }] = useMutationCreateInvoice();

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();

      createInvoice({
        variables: {
          createInvoiceInput: {
            projectId: project.id,
            harvestProjectIds: selectedProjects.map((x) => x.value),
            importFrom: dateRange.startDate,
            importTo: dateRange.endDate,
            issueDate,
            subject,
            notes,
            includeTime,
            includeExpenses,
            paymentTerm,
          },
        },
      });
    },
    [
      selectedProjects,
      dateRange,
      subject,
      notes,
      createInvoice,
      project,
      includeTime,
      includeExpenses,
      issueDate,
      paymentTerm,
    ]
  );

  return (
    <CreateInvoiceContainer>
      {!!createData?.createInvoice && (
        <Callout color={Colors.SUCCESS}>
          <h5>Invoice created!</h5>
          <p>
            Invoice has been created in Harvest:{' '}
            <a href={`https://caxyinteractive.harvestapp.com/invoices/${createData.createInvoice.id}`} target='_blank'>
              View in Harvest
            </a>
          </p>
        </Callout>
      )}
      {!!createError && <ErrorMessage title='Error creating invoice' error={createError.message} />}
      <H3>Create Invoice</H3>
      {loading && <p>Loading invoice notes...</p>}
      <form onSubmit={handleSubmit}>
        <Grid>
          <Cell>
            <label>
              Harvest Projects
              <Select
                defaultValue={harvestProjects}
                isMulti
                name='harvestProject'
                options={harvestProjects}
                className='basic-multi-select'
                classNamePrefix='select'
                value={selectedProjects}
                onChange={setSelectedProjects}
                required
              />
            </label>
          </Cell>
        </Grid>
        <DateRangeSelect value={dateRange} onChange={setDateRange} initialPreset={initialPreset} />
        <Grid gutters='margin'>
          <Cell small={12}>
            <label>
              Subject
              <textarea
                name='subject'
                value={subject}
                onChange={(e) => {
                  setSubject(e.target.value ? e.target.value.substring(0, 255) : e.target.value);
                  setModifiedSubject(true);
                }}
                rows={2}
                required
              />
            </label>
          </Cell>
          <Cell small={12}>
            <label>
              Notes
              <textarea
                name='notes'
                value={notes}
                onChange={(e) => {
                  setNotes(e.target.value);
                  setModifiedNotes(true);
                }}
                rows={5}
                aria-describedby='notesHelpText'
              />
            </label>
            {modifiedNotes && !!notes && filteredInvoiceNotes.length === 0 ? (
              <div className='text-right'>
                <Button
                  type='button'
                  onClick={() => alert("This isn't implemented just yet, sorry!")}
                  color={Colors.SECONDARY}
                  size='small'
                >
                  Save invoice note to project
                </Button>
              </div>
            ) : filteredInvoiceNotes.length > 0 ? (
              <p className='help-text text-right' id='notesHelpText'>
                Populated from {filteredInvoiceNotes.length} invoice note{' '}
                {filteredInvoiceNotes.length > 1 ? 'entries.' : 'entry.'}
              </p>
            ) : null}
          </Cell>
          <Cell small={12} medium={6} large={3}>
            <label>
              Issue Date
              <input
                type='date'
                name='issueDate'
                value={issueDate}
                onChange={(e) => {
                  setIssueDate(moment(e.target.value).format('YYYY-MM-DD'));
                  setModifiedIssueDate(true);
                }}
                required
              />
            </label>
          </Cell>
          <Cell small={12} medium={6} large={3}>
            <label>
              Payment Term
              <Select
                name='paymentTerm'
                options={PAYMENT_TERM_OPTIONS}
                className='basic-multi-select'
                classNamePrefix='select'
                value={selectedPaymentTerm}
                onChange={(newValue) => setPaymentTerm(newValue.value)}
                required
              />
            </label>
          </Cell>
          <Cell small={6} large={2}>
            <label htmlFor='include_time'>
              Include Time
              <Switch
                id='include_time'
                input={{
                  defaultChecked: true,
                  onChange: (e) => setIncludeTime(e.target.checked),
                }}
                active={{ text: 'Yes' }}
                inactive={{ text: 'No' }}
              />
            </label>
          </Cell>
          <Cell small={6} large={2}>
            <label htmlFor='include_expenses'>
              Include Expenses
              <Switch
                id='include_expenses'
                input={{
                  defaultChecked: true,
                  onChange: (e) => setIncludeExpenses(e.target.checked),
                }}
                active={{ text: 'Yes' }}
                inactive={{ text: 'No' }}
              />
            </label>
          </Cell>
          <Cell small={12}>
            <Button color={Colors.PRIMARY} type='submit' disabled={createLoading}>
              Create Invoice
            </Button>
          </Cell>
        </Grid>
      </form>
    </CreateInvoiceContainer>
  );
}

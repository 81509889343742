import moment from 'moment';
import EmailHorizontalRule from './EmailHorizontalRule';
import React from 'react';
import Percentage from 'components/Percentage/Percentage';
import Currency from 'components/Currency/Currency';
import NumberStat from 'components/NumberStat/NumberStat';
import PropTypes from 'prop-types';
import { projectShape } from 'utils/shapes/project';
import { projectStatsShape } from 'utils/shapes/stats';
import styled from 'styled-components/macro';
import { Field, FieldArray } from 'redux-form/lib/immutable';
import H3 from 'components/H3';
import TicketStatusAndTransitions from 'routes/Reports/routes/DailyRecap/components/TicketStatusAndTransitions';

const PlaceholderText = styled.mark`
  //background-color: ;
`;

function EmailContent({ project, projectStats, reportFilters, dailyRecapStats, dailyRecapData }) {
  const recapData = dailyRecapStats && dailyRecapStats.data;

  const {
    blocked_in_sprint,
    completed_in_sprint,
    daily_story_point_goal,
    remaining_in_sprint,
    story_points_in_sprint,
    underway_in_sprint,
    completed_outside_sprint,
  } = (recapData && recapData.sprintStats) || {};

  return (
    <div className='email-content'>
      <p>Hello, {dailyRecapData && dailyRecapData.clientName} and All:</p>
      <p>
        Please see below for key happenings of {recapData.sprint && recapData.sprint.name} since the last daily recap.
        If you have any questions, please don’t hesitate to reach me directly.
      </p>
      <h3>BASIC SPRINT OVERVIEW</h3>
      <div>
        <p>
          The current sprint runs from{' '}
          {recapData.sprint && (
            <span>
              {moment(recapData.sprint.start_date).format('MM/DD/YY')} &ndash;{' '}
              {moment(recapData.sprint.end_date).format('MM/DD/YY')}
            </span>
          )}
          .
          <br />
          The expected velocity of this sprint is{' '}
          {recapData.sprintStats && recapData.sprintStats.story_points_in_sprint}.
          <br />
          The demo is currently scheduled for {dailyRecapData && dailyRecapData.demoDate}.
        </p>
        <p>
          <strong>When this sprint is complete the following will be true:</strong>
          <br />
          {dailyRecapData && dailyRecapData.sprintCommitment}
        </p>
      </div>
      <h3>KEY DECISIONS FROM STANDUPS AND TEAM COMMUNICATIONS</h3>
      <ul>
        {dailyRecapData &&
          dailyRecapData.keyDecisions &&
          dailyRecapData.keyDecisions.map((decision, index) => <li key={index}>{decision}</li>)}
      </ul>
      <h3>FEATURES NEEDING ATTENTION / DECISIONS NEED TO BE MADE</h3>
      <ul>
        {dailyRecapData &&
          dailyRecapData.decisionsToBeMade &&
          dailyRecapData.decisionsToBeMade.map((item, index) => <li key={index}>{item}</li>)}
      </ul>
      <h3>OTHER UPDATES AND INFORMATION TO SHARE</h3>
      <ul>
        {dailyRecapData &&
          dailyRecapData.otherUpdates &&
          dailyRecapData.otherUpdates.map((item, index) => <li key={index}>{item}</li>)}
      </ul>
      <h3>CURRENT STATUS AND TRANSITIONS</h3>
      <p className='help-text'>
        This is the current status of all tickets in {recapData.sprint && recapData.sprint.name} as of{' '}
        {moment().format('MM/DD/YY')}.
        <br />
        An <strong>***</strong> next to a feature indicates that it has transitioned or been worked on outside of the
        active sprint.
      </p>
      <TicketStatusAndTransitions data={recapData} jiraLinks={reportFilters.showJiraLinks} />
      <h3>SPRINT AND PROJECT HEALTH AND INSIGHTS</h3>
      <ul>
        <li>
          <p>
            <strong>We’ve completed or closed {completed_in_sprint} storypoints.</strong>
            <br />
            {dailyRecapData && dailyRecapData.completedStorypointsNotes}
          </p>
        </li>
        <li>
          <p>
            <strong>We have {underway_in_sprint} storypoints underway.</strong>
            <br />
            {dailyRecapData && dailyRecapData.underwayStorypointsNotes}
          </p>
        </li>
        <li>
          <p>
            <strong>We have {remaining_in_sprint} storypoints left to do in this sprint.</strong>
            <br />
            {dailyRecapData && dailyRecapData.remainingStorypointsNotes}
          </p>
        </li>
        <li>
          <p>
            <strong>We have {blocked_in_sprint} storypoints BLOCKED and needing attention in this sprint.</strong>
            <br />
            {dailyRecapData && dailyRecapData.blockedStorypointsNotes}
          </p>
        </li>
        <li>
          <p>
            <strong>
              The team has completed an additional {completed_outside_sprint} storypoints outside of the sprint.
            </strong>
            <br />
            {dailyRecapData && dailyRecapData.outsideSprintStorypointsNotes}
          </p>
        </li>
        <li>
          <p>
            <strong>
              To complete this sprint, the team must move {daily_story_point_goal} storypoints across the board each
              day.
            </strong>
            <br />
            {dailyRecapData && dailyRecapData.dailyMoveStorypointsNotes}
          </p>
        </li>
        <li>
          <p>
            <strong>
              There are currently {projectStats.data.numberOfTicketsUnestimated} number of tickets in the backlog
              without estimates.
            </strong>
            <br />
            {dailyRecapData && dailyRecapData.unestimatedStorypointsNotes}
          </p>
        </li>
        <li>
          <p>
            <strong>
              The average storypoint estimate in the project is{' '}
              <NumberStat
                value={projectStats.data.storyPointsTotal / projectStats.data.numberOfTicketsTotal}
                maximumFractionDigits={2}
              />{' '}
              storypoints.
            </strong>
            <br />
            {dailyRecapData && dailyRecapData.averageEstimateStorypointsNotes}
          </p>
        </li>
        <li>
          <p>
            <strong>
              The current CPP (cost per point) in this project is {project.health.cost_per_point.toFixed(2)} average
              hours / storypoint.
            </strong>
            <br />
            {dailyRecapData && dailyRecapData.costPerPointNotes}
          </p>
        </li>
      </ul>
      <p>
        Please reach out with any additional questions or needs at this time.
        <br />
        My contact information is provided in my signature.
        <br />
        Looking forward to your thoughts.
      </p>
      Talk soon,
      <br />
      {dailyRecapData && dailyRecapData.teamMemberName}
    </div>
  );
}

EmailContent.propTypes = {
  filters: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }),
  project: projectShape,
  overviewStats: PropTypes.shape({
    loading: PropTypes.bool,
    error: PropTypes.string,
    data: projectStatsShape,
  }),
  filteredStats: PropTypes.shape({
    loading: PropTypes.bool,
    error: PropTypes.string,
    data: projectStatsShape,
  }),
  reportFilters: PropTypes.shape({
    hoursByPerson: PropTypes.bool,
    hoursByTask: PropTypes.bool,
  }),
  previousPayPeriodStats: PropTypes.shape({
    loading: PropTypes.bool,
    error: PropTypes.string,
    data: projectStatsShape,
  }),
};

export default EmailContent;

import { string, bool, shape, arrayOf, number } from 'prop-types';
import { projectShape } from './project';
import PropTypes from 'prop-types';

export const budgetStatShape = shape({
  hours: number,
  dollars: number,
});

export const taskBreakdownShape = shape({
  task_name: PropTypes.string,
  budget_stat: budgetStatShape,
  is_billable: bool,
});

export const personBreakdownShape = shape({
  name: string,
  billed: budgetStatShape,
  unbilled: budgetStatShape,
});

export const projectOverviewStatsShape = shape({
  budgetUsed: budgetStatShape,
  storyPointsCompleted: number,
  storyPointsTotal: number,
  hoursNeeded: budgetStatShape,
});

export const projectStatsShape = shape({
  project: projectShape,
  overviewStats: projectOverviewStatsShape,
});

export const projectEntryShape = shape({
  project: projectShape,
  overviewStats: projectOverviewStatsShape,
});

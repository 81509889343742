import styled from 'styled-components/macro';
import { Button } from 'react-foundation';

export default styled(Button)`
  &,
  &.button.hollow.secondary {
    border: solid 2px #f4f1ef;
    min-width: 170px;
    line-height: 1.25;
    letter-spacing: 0.1px;
    color: ${(props) => props.theme.titleColorPrimary};
    text-transform: uppercase;
    font-family: ${(props) => props.theme.titleFontFamily};

    &:hover {
      border-color: ${(props) => props.theme.titleColorPrimary};
      transition: border-color 0.5s;
    }
  }
`;

import { createStructuredSelector } from 'reselect';
import {
  selectError,
  selectFilteredStats,
  selectFilters,
  selectLoading,
  selectOverviewStats,
  selectRetrieved,
} from 'routes/ProjectPage/selectors';
import {
  error,
  filteredStatsReset,
  getFilteredStats,
  getOverviewStats,
  loading,
  reset,
  retrieve,
} from 'routes/ProjectPage/actions';
import connect from 'react-redux/es/connect/connect';
import { toJS } from 'utils/toJS';
import BudgetSummary from '../components';
import { selectDateFilters, selectPreviousPayPeriodStats, selectReportFilters } from '../selectors';
import {
  getPreviousPayPeriodStats,
  setBillingCycle,
  setDateFilters,
  setDatePreset,
  setHoursByPerson,
  setHoursByTask,
  setHoursByTaskSort,
} from '../reducer';

const mapStateToProps = createStructuredSelector({
  error: selectError,
  loading: selectLoading,
  project: selectRetrieved,
  filters: selectDateFilters,
  overviewStats: selectOverviewStats,
  filteredStats: selectFilteredStats,
  previousPayPeriodStats: selectPreviousPayPeriodStats,
  reportFilters: selectReportFilters,
});

const mapDispatchToProps = (dispatch) => {
  return {
    retrieve: (id) => dispatch(retrieve(id)),
    getOverviewStats: (id) => dispatch(getOverviewStats(id)),
    getFilteredStats: (id, start, end) => dispatch(getFilteredStats(id, start, end)),
    getPreviousPayPeriodStats: (id, start, end) => dispatch(getPreviousPayPeriodStats(id, start, end)),
    reset: () => {
      dispatch(reset());
      dispatch(error(null));
      dispatch(loading(false));
    },
    resetFilteredStats: () => dispatch(filteredStatsReset()),
    setHoursByPerson: (payload) => dispatch(setHoursByPerson(payload)),
    setHoursByTask: (payload) => dispatch(setHoursByTask(payload)),
    setHoursByTaskSort: (payload) => dispatch(setHoursByTaskSort(payload)),
    setBillingCycle: (payload) => dispatch(setBillingCycle(payload)),
    setDateFilters: (startDate, endDate) => dispatch(setDateFilters(startDate, endDate)),
    setDatePreset: (preset) => dispatch(setDatePreset(preset)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(toJS(BudgetSummary));

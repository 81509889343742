import { gql } from '@apollo/client';

const INVOICE_NOTES_QUERY = gql`
  query InvoiceNotes($input: FindInvoiceNotesInput!) {
    invoiceNotes(input: $input) {
      harvestProjectId
      id
      notes
      projectId
      targetDate
    }
  }
`;

export default INVOICE_NOTES_QUERY;

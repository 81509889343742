import styled from 'styled-components/macro';
import sumBy from 'lodash/sumBy';
import meanBy from 'lodash/meanBy';
import { Link } from 'react-router-dom';
import { stripApiPath } from 'utils/helpers';
import ReactTable from 'react-table';
import NumberStat from 'components/NumberStat/NumberStat';
import Currency from 'components/Currency/Currency';
import { Colors, Progress } from 'react-foundation';
import React from 'react';
import Percentage from 'components/Percentage';
import Tooltip from '@material-ui/core/Tooltip';
import LabelAndValue from './LabelAndValue';
import HtmlTooltip from './HtmlTooltip';
import HoursSplitBreakdown from 'routes/DashboardPage/components/HoursSplitBreakdown';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const StyledTable = styled.div`
  .ReactTable {
    border-radius: 0.5rem;
  }

  padding-bottom: 10rem;
`;
const supportProjectPattern = /support/i;

function isTimeAndMaterials(project) {
  return (
    !isBudgeted(project) &&
    project.budgetType &&
    project.budgetType.billableBeyondBudget &&
    !project.budgetType.internal
  );
}

function isBudgeted(project) {
  return project.currentBudget && project.currentBudget > 0;
}

function isInternal(project) {
  return project.budgetType && project.budgetType.internal;
}

export default function ProjectListTable({ projects, allProjectStats }) {
  const internalProjects = React.useMemo(() => projects.filter((x) => isInternal(x)).map((x) => x.name), [projects]);

  const tableData =
    (projects &&
      allProjectStats.data &&
      projects.map((project) => {
        const hasBudget = isBudgeted(project);
        const isTM = isTimeAndMaterials(project);
        const internal = isInternal(project);

        const budgetUsedPercent = hasBudget ? project.health.budget_used_percent : null;
        const projectCompletePercent = project.health.project_complete_percent || null;
        const projectDelta = hasBudget ? (projectCompletePercent || 0) - budgetUsedPercent : null;

        // If no story points remaining but also none complete, we default the value instead of showing 0.
        let storyPointsRemaining = project.health.story_points?.remaining;
        if (!storyPointsRemaining && !project.health.story_points?.completed) {
          storyPointsRemaining = null;
        }

        // Look up the project stats data.
        const stats = allProjectStats.data.find((x) => x.project['@id'] === project['@id']);
        const invoiced = stats?.glanceStats?.budget_breakdown?.invoiced;

        return {
          project,
          stats,
          code: project.code,
          name: project.name,
          prepaymentRemaining: project.health.prepayment_remaining || 0,
          budgetUsed: hasBudget ? budgetUsedPercent : isTM ? 'T&M' : 'n/a',
          projectComplete: project.health.project_complete_percent || null,
          costPerPoint: project.health.cost_per_point,
          costPerPointInDollars: project.hourlyRate ? project.health.cost_per_point * project.hourlyRate : null,
          averageCarryover: 'TBD',
          burdenedRate: project.health.burden_rate || null,
          budgetRemainingDollars: hasBudget && !internal ? project.health.budget_remaining?.dollars || 0 : null,
          storyPointsRemaining,
          projectDelta,
          projectCompletePercent,
          budgetUsedPercent,
          revenueToDate: invoiced ? invoiced.dollars : null,
        };
      })) ||
    [];

  const sumStoryPointsRemaining = sumBy(
    tableData.filter((x) => !isNaN(x.storyPointsRemaining)),
    'storyPointsRemaining'
  );
  const sumBudgetRemaining = sumBy(
    tableData.filter((x) => !isNaN(x.budgetRemainingDollars)),
    'budgetRemainingDollars'
  );
  const sumRevenueToDate = sumBy(
    tableData.filter((x) => !isNaN(x.revenueToDate)),
    'revenueToDate'
  );
  const avgBurdenedRate = meanBy(
    tableData.filter((x) => !isNaN(x.burdenedRate) && x.burdenedRate > 0),
    'burdenedRate'
  );

  const columns = [
    {
      Header: 'PROJECT',
      accessor: 'name',
      minWidth: 200,
      Cell: (props) => (
        <Link to={`/projects/show/${encodeURIComponent(stripApiPath(props.original.project['@id'], '/projects/'))}`}>
          {props.value}
        </Link>
      ),
      sortMethod: (a, b, desc) => {
        // Force internal projects last.
        const isInternalA = internalProjects.includes(a);
        const isInternalB = internalProjects.includes(b);
        if (isInternalA || isInternalB) {
          // Return either 1 or -1 to indicate a sort priority
          if (isInternalA && !isInternalB) {
            return 1;
          }
          if (!isInternalA && isInternalB) {
            return -1;
          }
          return 0;
        }

        // Force support projects to the bottom above internal projects.
        const isSupportA = typeof a === 'string' && supportProjectPattern.test(a);
        const isSupportB = typeof b === 'string' && supportProjectPattern.test(b);

        if (isSupportA || isSupportB) {
          // Return either 1 or -1 to indicate a sort priority
          if (isSupportA && !isSupportB) {
            return 1;
          }
          if (!isSupportA && isSupportB) {
            return -1;
          }
          // returning 0, undefined or any falsey value will use subsequent sorts or
          // the index as a tiebreaker
          return 0;
        }

        // Default back to react-table's sort method.
        return ReactTable.defaultProps.defaultSortMethod(a, b, desc);
      },
      Footer: (
        <span>
          <strong>TOTALS</strong>
        </span>
      ),
    },
    {
      Header: 'BUDGET REMAINING',
      accessor: 'budgetRemainingDollars',
      Cell: (props) => {
        if (props.value === null) {
          return '';
        }

        if (isNaN(props.value)) {
          return props.value;
        }

        return (
          <HtmlTooltip
            title={
              <>
                <LabelAndValue label='Budget Used:'>
                  <Percentage value={props.original.budgetUsedPercent} />
                </LabelAndValue>
                <LabelAndValue label='Current Budget Total:'>
                  <Currency value={props.original.project.currentBudget * props.original.project.hourlyRate} />
                </LabelAndValue>
                <LabelAndValue label='Original SOW Budget:'>
                  <Currency value={props.original.project.budget * props.original.project.hourlyRate} />
                </LabelAndValue>
                {!!props.original.prepaymentRemaining && (
                  <LabelAndValue label='Prepay Left:'>
                    <Currency value={props.original.prepaymentRemaining} />
                  </LabelAndValue>
                )}
              </>
            }
            placement='right'
            arrow
          >
            <div>
              <Currency value={props.value} />
            </div>
          </HtmlTooltip>
        );
      },
      minWidth: 90,
      Footer: (
        <div style={{ textAlign: 'right' }}>
          <strong>
            <Currency value={sumBudgetRemaining} />
          </strong>
        </div>
      ),
    },
    {
      Header: 'PRJ DELTA',
      accessor: 'projectDelta',
      minWidth: 90,
      Cell: (props) => {
        // Return empty cell for internal projects.
        if (isInternal(props.original.project)) {
          return '';
        }

        // Check if JIRA is connected.
        if (!props.original.project.jiraProject) {
          return 'N/A';
        }

        if (props.value === null) {
          return '';
        }

        return (
          <HtmlTooltip
            title={
              <>
                <LabelAndValue label='Budget Used:'>
                  <Percentage value={props.original.budgetUsedPercent} />
                </LabelAndValue>
                <LabelAndValue label='Project Complete:'>
                  {props.original.projectCompletePercent !== null ? (
                    <Percentage value={props.original.projectCompletePercent} />
                  ) : (
                    'n/a'
                  )}
                </LabelAndValue>
              </>
            }
            placement='right'
            arrow
          >
            <div>
              <Percentage value={props.value} />
            </div>
          </HtmlTooltip>
        );
      },
      Footer: (
        <div style={{ textAlign: 'right' }}>
          <strong>
            <NumberStat value={sumStoryPointsRemaining} />
          </strong>
        </div>
      ),
    },
    {
      Header: 'CPP',
      accessor: 'costPerPoint',
      Cell: (props) => {
        if (props.value === null) {
          return '';
        }

        return (
          <HtmlTooltip
            title={
              <>
                <Currency value={props.original.costPerPointInDollars} /> dollars per point
              </>
            }
            placement='right'
            arrow
          >
            <div>
              <NumberStat value={props.value} minimumFractionDigits={2} maximumFractionDigits={2} />
            </div>
          </HtmlTooltip>
        );
      },
      minWidth: 50,
    },
    {
      Header: 'BURDENED RATE',
      accessor: 'burdenedRate',
      Cell: (props) => {
        if (props.value === null) {
          return '';
        }

        const budgetDetails = props.original.project.health?.budget_details;
        const delta = props.value - budgetDetails.hourly_rate;
        const renderedValue = (
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Box ml={1}>
              <Typography variant='caption' color='textSecondary'>
                (<Currency value={delta} />)
              </Typography>
            </Box>
            <Currency value={props.value} />
          </Box>
        );

        // Don't render with tooltip if we don't have the budget details.
        if (!budgetDetails) {
          return renderedValue;
        }

        return (
          <HtmlTooltip
            title={
              <>
                <LabelAndValue label='Project Hourly Rate:'>
                  <Currency value={budgetDetails.hourly_rate} />
                </LabelAndValue>
                {budgetDetails.budget_used && (
                  <>
                    <LabelAndValue label='Billed (All Time):'>
                      <Currency value={budgetDetails.budget_used.billable * budgetDetails.hourly_rate} />
                    </LabelAndValue>
                    <LabelAndValue label='IB (All Time):'>
                      <Currency value={budgetDetails.budget_used.unbillable * budgetDetails.hourly_rate} />
                    </LabelAndValue>
                    {!!budgetDetails.hours_used_total && (
                      <LabelAndValue label='Billable Percentage (All Time):'>
                        <Percentage value={budgetDetails.budget_used.billable / budgetDetails.hours_used_total} />
                      </LabelAndValue>
                    )}
                  </>
                )}
              </>
            }
            placement='right'
            arrow
          >
            <div>{renderedValue}</div>
          </HtmlTooltip>
        );
      },
      minWidth: 70,
      Footer: (
        <div style={{ textAlign: 'right' }}>
          <strong>
            Avg: <Currency value={avgBurdenedRate} />
          </strong>
        </div>
      ),
    },
    {
      Header: 'REVENUE TO DATE',
      accessor: 'revenueToDate',
      Cell: (props) => {
        if (props.value === null) {
          return '$0';
        }

        const budgetBreakdown = props.original.stats?.glanceStats?.budget_breakdown;
        const renderedValue = <Currency value={props.value} />;

        // Don't render with tooltip if we don't have a budget breakdown.
        if (!budgetBreakdown) {
          return renderedValue;
        }

        return (
          <HtmlTooltip title={<HoursSplitBreakdown data={budgetBreakdown} />} placement='right' arrow>
            <div>{renderedValue}</div>
          </HtmlTooltip>
        );
      },
      minWidth: 70,
      Footer: (
        <div style={{ textAlign: 'right' }}>
          <strong>
            <Currency value={sumRevenueToDate} />
          </strong>
        </div>
      ),
    },
  ];

  return (
    <StyledTable>
      <ReactTable
        data={tableData}
        noDataText={'Loading...'}
        columns={columns}
        showPagination={false}
        defaultPageSize={projects && projects.length}
        defaultSorted={[
          {
            id: 'name',
            desc: false,
          },
        ]}
        getTrGroupProps={(state, rowInfo, column, instance) => ({
          style: { borderBottom: 'none' },
        })}
        getTdProps={(state, rowInfo, column, instance) => {
          const style = {
            borderBottom: '1px solid rgba(0,0,0,0.02)',
          };

          if (column.id !== 'name' && column.id !== 'code') {
            style.textAlign = 'right';
          }

          // Add red background if there are unbillable hours
          if (column.id === 'unbillableHours' && rowInfo && rowInfo.row.unbillableHours > 0) {
            style.backgroundColor = '#f4cccc';
            style.fontWeight = 'bold';
          }

          if (column.id === 'hoursToInvoice' && rowInfo && rowInfo.row.hoursToInvoice < rowInfo.row.billableHours) {
            style.backgroundColor = '#fce5cd';
            style.fontWeight = 'bold';
          }

          if (
            rowInfo &&
            column.id === 'costPerPoint' &&
            rowInfo.row.costPerPoint > 4 &&
            (!rowInfo.original.project.budgetType || !rowInfo.original.project.budgetType.internal)
          ) {
            style.backgroundColor = '#f4cccc';
            style.fontWeight = 'bold';
          }

          if (
            rowInfo &&
            column.id === 'budgetRemainingDollars' &&
            rowInfo.row.budgetRemainingDollars <= 0 &&
            (!rowInfo.original.project.budgetType || !rowInfo.original.project.budgetType.internal)
          ) {
            style.backgroundColor = '#f4cccc';
            style.fontWeight = 'bold';
          }

          return { style };
        }}
        getTheadThProps={(state, rowInfo, column, instance) => ({
          style: {
            whiteSpace: 'normal',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            fontWeight: 'bold',
            fontSize: '0.8em',
          },
        })}
        style={
          {
            // height: "800px" // This will force the table body to overflow and scroll, since there is not enough room
          }
        }
        className='-striped -highlight'
      />
    </StyledTable>
  );
}

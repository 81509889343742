import { fromJS } from 'immutable';
import { combineReducers } from 'redux-immutable';
import {
  COMPANY_STATS_ERROR,
  COMPANY_STATS_LOADING,
  COMPANY_STATS_RESET,
  COMPANY_STATS_SUCCESS,
  ALL_PROJECT_STATS_ERROR,
  ALL_PROJECT_STATS_LOADING,
  ALL_PROJECT_STATS_RESET,
  ALL_PROJECT_STATS_SUCCESS,
  UPDATE_PROJECT_FILTER,
  PROJECT_LIST_DATA_RESET,
  SET_DATE_FILTERS,
  SET_DATE_PRESET,
  SET_ORDER_BY,
  VIEW_PROJECT_PAGE,
  REMOVE_RECENT_PROJECT,
} from 'routes/DashboardPage/actions';
import {
  DATE_PRESET_MONTH,
  PROJECT_FILTER_ACTIVE,
  PROJECT_SORT_NAME,
  RECENT_PROJECT_STORAGE_KEY,
  RECENT_PROJECT_STORAGE_LIMIT,
  SORT_ASCENDING,
} from 'utils/constants';
import moment from 'moment/moment';

export function error(state = null, action) {
  switch (action.type) {
    case 'PROJECT_LIST_ERROR':
      return action.error;

    case 'PROJECT_LIST_RESET':
      return null;

    default:
      return state;
  }
}

export function loading(state = false, action) {
  switch (action.type) {
    case 'PROJECT_LIST_LOADING':
      return action.loading;

    case 'PROJECT_LIST_RESET':
      return false;

    default:
      return state;
  }
}

const initialState = fromJS({});
export function data(state = initialState, action) {
  switch (action.type) {
    case 'PROJECT_LIST_SUCCESS':
      return fromJS(action.data);

    case PROJECT_LIST_DATA_RESET:
      return initialState;

    case 'PROJECT_LIST_RESET':
      return initialState;

    default:
      return state;
  }
}

const recentProjectsJson = localStorage.getItem(RECENT_PROJECT_STORAGE_KEY);
const recentProjectIds = recentProjectsJson ? JSON.parse(recentProjectsJson) : [];

const initialRecentProjectsState = fromJS({
  recentProjectIds,
});
export function recentProjects(state = initialRecentProjectsState, action) {
  switch (action.type) {
    case VIEW_PROJECT_PAGE:
      const updated = [
        action.payload,
        ...state.get('recentProjectIds').filter((x) => x.toString() !== action.payload.toString()),
      ];
      localStorage.setItem(RECENT_PROJECT_STORAGE_KEY, JSON.stringify(updated.slice(0, RECENT_PROJECT_STORAGE_LIMIT)));
      return state.set('recentProjectIds', updated);

    case REMOVE_RECENT_PROJECT:
      const updatedList = state.get('recentProjectIds').filter((x) => x.toString() !== action.payload.toString());
      localStorage.setItem(
        RECENT_PROJECT_STORAGE_KEY,
        JSON.stringify(updatedList.slice(0, RECENT_PROJECT_STORAGE_LIMIT))
      );
      return state.set('recentProjectIds', updatedList);

    default:
      return state;
  }
}

const initialCompanyStats = fromJS({
  loading: false,
  error: undefined,
  data: undefined,
});
export function companyStats(state = initialCompanyStats, action) {
  switch (action.type) {
    case COMPANY_STATS_ERROR:
      return state.set('error', action.payload);
    case COMPANY_STATS_LOADING:
      return state.set('loading', action.payload);
    case COMPANY_STATS_SUCCESS:
      return state.set('data', action.payload);
    case COMPANY_STATS_RESET:
      return initialCompanyStats;
    default:
      return state;
  }
}

const initialAllProjectStats = fromJS({
  loading: false,
  error: undefined,
  data: undefined,
});
export function allProjectStats(state = initialAllProjectStats, action) {
  switch (action.type) {
    case ALL_PROJECT_STATS_ERROR:
      return state.set('error', action.payload);
    case ALL_PROJECT_STATS_LOADING:
      return state.set('loading', action.payload);
    case ALL_PROJECT_STATS_SUCCESS:
      return state.set('data', action.payload);
    case ALL_PROJECT_STATS_RESET:
      return initialAllProjectStats;
    default:
      return state;
  }
}

export function projectFilter(state = PROJECT_FILTER_ACTIVE, action) {
  switch (action.type) {
    case UPDATE_PROJECT_FILTER:
      return action.payload;
    default:
      return state;
  }
}

const initialDateFiltersState = fromJS({
  startDate: moment().startOf(DATE_PRESET_MONTH).toISOString(),
  endDate: moment().endOf(DATE_PRESET_MONTH).toISOString(),
  datePreset: DATE_PRESET_MONTH,
});
export function dateFilters(state = initialDateFiltersState, action) {
  switch (action.type) {
    case SET_DATE_FILTERS:
      const { startDate, endDate } = action.payload;
      return state.set('startDate', startDate).set('endDate', endDate);
    case SET_DATE_PRESET:
      return state.set('datePreset', action.payload);
    default:
      return state;
  }
}

const initialOrderByState = fromJS({
  orderBy: PROJECT_SORT_NAME,
  direction: SORT_ASCENDING,
});
export function orderBy(state = initialOrderByState, action) {
  switch (action.type) {
    case SET_ORDER_BY:
      const { orderBy, direction } = action.payload;
      return state.set('orderBy', orderBy).set('direction', direction);
    default:
      return state;
  }
}

export default combineReducers({
  error,
  loading,
  data,
  companyStats,
  allProjectStats,
  projectFilter,
  dateFilters,
  orderBy,
  recentProjects,
});

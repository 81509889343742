import Banner from 'routes/ProjectPage/Banner';
import { Cell, Grid, GridContainer } from 'react-foundation';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import MonthBillingCard from 'routes/DashboardPage/components/MonthBillingCard';
import BannerStat from 'routes/ProjectPage/BannerStat';
import Currency from 'components/Currency';
import Percentage from 'components/Percentage';
import NumberStat from 'components/NumberStat';
import HoursSplitBreakdown from 'routes/DashboardPage/components/HoursSplitBreakdown';

CompanyStatsBlock.propTypes = {
  companyStats: PropTypes.shape({
    loading: PropTypes.bool,
    error: PropTypes.string,
    data: PropTypes.object,
  }),
};

export default function CompanyStatsBlock({ companyStats }) {
  const {
    lastMonth,
    monthToDate,
    endOfMonthProjection,
    nextMonthProjection,
    monthToDateHoursReportedPercent,
    endOfMonthPercentResourced,
    monthToDateBillablePercent,
    monthToDateHoursMissing,
    mtdDailyRevenueAccrualAvg,
    lastMonthBillablePercent,
    lastMonthHoursMissing,
    lastMonthHoursReportedPercent,
    lastMonthDailyRevenueAccrualAvg,
    lastMonthProjection,
  } = companyStats.data || {};

  const lastMonthName = moment().subtract(1, 'months').format('MMMM YYYY');
  const currentMonthName = moment().format('MMMM');
  const nextMonthName = moment().add(1, 'months').format('MMMM YYYY');

  return (
    <Banner secondary>
      <GridContainer>
        <Grid gutters='margin'>
          <Cell small={3}>
            {companyStats.data && (
              <BannerStat
                secondaryTitleStyle
                numberStat
                title={'MTD Billability'}
                main={<Percentage value={monthToDateBillablePercent} />}
                tooltip={<HoursSplitBreakdown data={monthToDate} />}
              />
            )}
          </Cell>
          <Cell small={3}>
            {companyStats.data && (
              <BannerStat
                secondaryTitleStyle
                numberStat
                title={'MTD Total Hours Missing'}
                main={<NumberStat value={monthToDateHoursMissing} />}
                tooltip={
                  <>
                    <Percentage value={monthToDateHoursReportedPercent} /> Hrs Reported
                  </>
                }
              />
            )}
          </Cell>
          <Cell small={3}>
            {companyStats.data && (
              <BannerStat
                secondaryTitleStyle
                numberStat
                title={'MTD Revenue'}
                main={<Currency value={monthToDate.invoiced.dollars} />}
                tooltip={
                  <>
                    Avg. Daily Revenue Accrual: <Currency value={mtdDailyRevenueAccrualAvg} />
                  </>
                }
              />
            )}
          </Cell>
          <Cell small={3}>
            {companyStats.data && (
              <BannerStat
                secondaryTitleStyle
                numberStat
                title={'MTD Projection Revenue'}
                main={<Currency value={endOfMonthProjection.invoiced.dollars} />}
                tooltip={<HoursSplitBreakdown data={endOfMonthProjection} />}
              />
            )}
          </Cell>
        </Grid>
      </GridContainer>
    </Banner>
  );
}

export const RECENT_PROJECT_DISPLAY_LIMIT = 6;
export const RECENT_PROJECT_STORAGE_LIMIT = 30;
export const RECENT_PROJECT_STORAGE_KEY = 'pulse_check.recently_viewed_projects';
export const DATE_PRESET_MONTH = 'month';
export const DATE_PRESET_YEAR = 'year';
export const DATE_PRESET_QUARTER = 'quarter';
export const DATE_PRESET_CUSTOM = 'custom';
export const DATE_PRESET_SEMIMONTH = 'semimonth';

export const DATE_PRESETS = [DATE_PRESET_SEMIMONTH, DATE_PRESET_MONTH, DATE_PRESET_YEAR, DATE_PRESET_QUARTER];

export const PROJECT_FILTER_ACTIVE = 'active_projects';
export const PROJECT_FILTER_ALL = 'all_projects';
export const PROJECT_FILTER_KEY = 'key_projects';
export const PROJECT_FILTER_INTERNAL = 'internal_projects';

export const PROJECT_LIST_FILTERS = [
  PROJECT_FILTER_ALL,
  PROJECT_FILTER_ACTIVE,
  PROJECT_FILTER_KEY,
  PROJECT_FILTER_INTERNAL,
];

export const FORECASTING_FILTER_ACTUAL = 'forecasting_actual';
export const FORECASTING_FILTER_A = 'forecasting_a';
export const FORECASTING_FILTER_B = 'forecasting_b';

export const FORECASTING_FILTERS = [FORECASTING_FILTER_ACTUAL, FORECASTING_FILTER_A, FORECASTING_FILTER_B];

export const SORT_ASCENDING = 'asc';
export const SORT_DESCENDING = 'desc';

export const PROJECT_SORT_NAME = 'name';
export const PROJECT_SORT_CPP = 'cost_per_point';
export const PROJECT_SORT_TICKET_QUALITY = 'ticket_quality';
export const PROJECT_SORT_BILLABLE_HOURS = 'billable_hours';
export const PROJECT_SORT_WRITE_OFF_HOURS = 'writeoff_hours';

export const HOURS_BY_TASK_SORT_HOURS = 'hours';
export const HOURS_BY_TASK_SORT_ALPHA = 'alpha';
export const HOURS_BY_TASK_SORT_OPTIONS = [HOURS_BY_TASK_SORT_HOURS, HOURS_BY_TASK_SORT_ALPHA];

export const PROJECT_LIST_SORT_OPTIONS = [
  PROJECT_SORT_NAME,
  PROJECT_SORT_CPP,
  PROJECT_SORT_TICKET_QUALITY,
  PROJECT_SORT_BILLABLE_HOURS,
  PROJECT_SORT_WRITE_OFF_HOURS,
];

export const PROJECT_LIST_SORT_DIRECTIONS = {
  [PROJECT_SORT_NAME]: SORT_ASCENDING,
  [PROJECT_SORT_CPP]: SORT_DESCENDING,
  [PROJECT_SORT_TICKET_QUALITY]: SORT_DESCENDING,
  [PROJECT_SORT_BILLABLE_HOURS]: SORT_DESCENDING,
  [PROJECT_SORT_WRITE_OFF_HOURS]: SORT_DESCENDING,
};

export const BILLING_CYCLE_MONTHLY = 'monthly';
export const BILLING_CYCLE_SEMIMONTHLY = 'semimonthly';
export const BILLING_CYCLES = [BILLING_CYCLE_MONTHLY, BILLING_CYCLE_SEMIMONTHLY];

export const RESOURCE_PROJECT = '/api/projects';
export const RESOURCE_PROJECT_STATS = '/api/project_stats';
export const RESOURCE_COMMENT = '/api/comments';

// JIRA statuses
// export const JIRA_STATUS_BACKLOG = 'backlog';
export const JIRA_STATUS_BLOCKED = 'blocked';
export const JIRA_STATUS_CLOSED_INVALID = 'closed/invalid';
export const JIRA_STATUS_DONE = 'done';
export const JIRA_STATUS_FUTURE_ITERATION = 'future iteration';
export const JIRA_STATUS_IN_PROGRESS = 'in progress';
export const JIRA_STATUS_NEXT_UAT_RELEASE = 'next uat release';
export const JIRA_STATUS_UAT_RELEASED = 'uat released';
export const JIRA_STATUS_QA = 'qa';
export const JIRA_STATUS_REVIEW = 'review';
export const JIRA_STATUS_SELECTED_FOR_DEVELOPMENT = 'blocked';
export const JIRA_STATUS_TO_DO = 'to do';
export const JIRA_STATUS_UAT = 'uat';
// export const JIRA_UNESTIMATED = 'unestimated';

export const JIRA_STATUSES_ORDER = [
  JIRA_STATUS_DONE,
  JIRA_STATUS_CLOSED_INVALID,
  JIRA_STATUS_UAT,
  JIRA_STATUS_UAT_RELEASED,
  JIRA_STATUS_NEXT_UAT_RELEASE,
  JIRA_STATUS_QA,
  JIRA_STATUS_REVIEW,
  JIRA_STATUS_IN_PROGRESS,
  JIRA_STATUS_TO_DO,
  JIRA_STATUS_BLOCKED,
];

// Unused for now but keeping in case we need it later.
export const WARNING_STATES = [
  { differential: 0.7, color: 'darkred' },
  { differential: 0.5, color: 'red' },
  { differential: 0.2, color: 'orange' },
  { differential: 0.1, color: 'yellow' },
  { differential: 0.05, color: 'blue' },
  { differential: 0, color: 'gray' },
  { differential: -0.05, color: 'pink' },
  { differential: -0.1, color: 'green' },
];

export const PAYMENT_TERMS = {
  UponReceipt: 'UponReceipt',
  Net15: 'Net15',
  Net30: 'Net30',
  Net45: 'Net45',
  Net60: 'Net60',
  Custom: 'Custom',
};

export const PAYMENT_TERM_LABELS = {
  [PAYMENT_TERMS.UponReceipt]: 'upon receipt',
  [PAYMENT_TERMS.Net15]: 'net 15',
  [PAYMENT_TERMS.Net30]: 'net 30',
  [PAYMENT_TERMS.Net45]: 'net 45',
  [PAYMENT_TERMS.Net60]: 'net 60',
  [PAYMENT_TERMS.Custom]: 'custom',
};

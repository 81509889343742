const theme = {
  baseFontColor: '#000000',
  baseFontFamily: "'Work Sans'",
  primaryFontColor: '#084159',
  subTitleColor: '#041C25',
  titleColorPrimary: '#000000',
  titleFontFamily: "'Work Sans'",
  mutedColor: '#f4f1ef',

  // Color variables
  colorGrayWarm: '#9a9a9a',
  colorGrayDark: '#808080',
  colorGrayLight: '#f4f1ef',
  colorOrange: '#f99b1c',
  colorRed: '#df1e36',
  colorBlue: '#084159',
  colorBlueSoft: '#3c78d8',

  // Cards
  cardBorderColor: '#efefef',
};

export default theme;

import { createSelector } from 'reselect';

const selectValidatorOptions = () => (options) => options;

const selectValue = (value) => value;

const selectAllValues = (value, allValues) => allValues;

const selectLength = () => (options) => options.get('length');

const selectField = () => (options) => options.get('field');

const selectMessage = () => (options) => options.get('message');

export const required = createSelector(selectValidatorOptions(), () =>
  createSelector(selectValue, (value) => (value ? undefined : 'Required'))
);

export const maxLength = createSelector(selectLength(), (length) =>
  createSelector(selectValue, (value) =>
    value && value.length > length ? `Must be ${length} characters or less` : undefined
  )
);

export const minLength = createSelector(selectLength(), (length) =>
  createSelector(selectValue, (value) =>
    value && value.length < length ? `Must be ${length} characters or more` : undefined
  )
);

export const number = createSelector(selectValidatorOptions(), () =>
  createSelector(selectValue, (value) => (value && isNaN(Number(value)) ? 'Must be a number' : undefined))
);

export const minValue = createSelector(selectLength(), (length) =>
  createSelector(selectValue, (value) => (value && value < length ? `Must be at least ${length}` : undefined))
);

export const email = createSelector(selectValidatorOptions(), () =>
  createSelector(selectValue, (value) =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'Invalid email address' : undefined
  )
);

export const alphaNumeric = createSelector(selectValidatorOptions(), () =>
  createSelector(selectValue, (value) =>
    value && /[^a-zA-Z0-9 ]/i.test(value) ? 'Only alphanumeric characters' : undefined
  )
);

export const phoneNumber = createSelector(selectValidatorOptions(), () =>
  createSelector(selectValue, (value) =>
    value && !/^\+?(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/.test(value)
      ? 'Invalid phone number format'
      : undefined
  )
);

export const matchField = createSelector([selectField(), selectMessage()], (field, message) =>
  createSelector([selectValue, selectAllValues], (value, allValues) =>
    allValues.get(field) && allValues.get(field) === value ? undefined : message
  )
);

export const validateUrl = createSelector(selectValidatorOptions(), () =>
  createSelector(selectValue, (value) =>
    /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
      value
    )
      ? undefined
      : 'Invalid url'
  )
);

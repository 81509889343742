import { fromJS } from 'immutable';
import { combineReducers } from 'redux-immutable';
import { DATE_PRESET_MONTH } from 'utils/constants';
import moment from 'moment/moment';
import fetch from 'utils/fetch';

export const BILLABLE_REPORT_LOADING = 'app/Reports/MonthlyBillableReport/BILLABLE_REPORT_LOADING';
export const BILLABLE_REPORT_SUCCESS = 'app/Reports/MonthlyBillableReport/BILLABLE_REPORT_SUCCESS';
export const BILLABLE_REPORT_ERROR = 'app/Reports/MonthlyBillableReport/BILLABLE_REPORT_ERROR';
export const BILLABLE_REPORT_RESET = 'app/Reports/MonthlyBillableReport/BILLABLE_REPORT_RESET';

export const NOTE_SAVED = 'app/Reports/MonthlyBillableReport/NOTE_SAVED';

export const SET_DATE_FILTERS = 'app/Reports/MonthlyBillableReport/SET_DATE_FILTERS';
export const SET_DATE_PRESET = 'app/Reports/MonthlyBillableReport/SET_DATE_PRESET';

export function resetData() {
  return { type: BILLABLE_REPORT_RESET };
}

export function billableReportError(error) {
  return { type: BILLABLE_REPORT_ERROR, payload: error };
}

export function billableReportLoading(loading) {
  return { type: BILLABLE_REPORT_LOADING, payload: loading };
}

export function billableReportSuccess(payload) {
  return { type: BILLABLE_REPORT_SUCCESS, payload };
}

export function noteSaved(data) {
  return { type: NOTE_SAVED, payload: data };
}

export function getBillableReport(startDate, endDate) {
  return (dispatch) => {
    dispatch(billableReportLoading(true));

    const query = `?start_date=${startDate}&end_date=${endDate}`;

    return fetch(`/billable_report${query}`)
      .then((response) => response.json())
      .then((data) => {
        dispatch(billableReportLoading(false));
        dispatch(billableReportSuccess(data['hydra:member']));
      })
      .catch((e) => {
        dispatch(billableReportLoading(false));
        dispatch(billableReportError(e.message));
      });
  };
}

export function createNote(data) {
  return (dispatch) => {
    return fetch('/billable_report_notes', {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        dispatch(noteSaved(data));
      })
      .catch((e) => {
        console.error('Failed to create billable report note', e);
      });
  };
}

export function updateNote(id, data) {
  return (dispatch) => {
    return fetch(`/billable_report_notes/${id}`, {
      method: 'PUT',
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        dispatch(noteSaved(data));
      })
      .catch((e) => {
        console.error('Failed to update billable report note', e);
      });
  };
}

export function setDateFilters(startDate, endDate) {
  return {
    type: SET_DATE_FILTERS,
    payload: { startDate, endDate },
  };
}

export function setDatePreset(preset) {
  return {
    type: SET_DATE_PRESET,
    payload: preset,
  };
}

const initialBillableReport = fromJS({
  loading: false,
  error: undefined,
  data: undefined,
});
export function billableReport(state = initialBillableReport, action) {
  switch (action.type) {
    case BILLABLE_REPORT_ERROR:
      return state.set('error', action.payload);
    case BILLABLE_REPORT_LOADING:
      return state.set('loading', action.payload);
    case BILLABLE_REPORT_SUCCESS:
      return state.set('data', action.payload);
    case NOTE_SAVED:
      return state.update('data', (data) =>
        fromJS(data)
          .update(
            data.findIndex((item) => item.person && item.person['@id'] === action.payload.employee),
            (item) => item.set('note', action.payload)
          )
          .toJS()
      );
    case BILLABLE_REPORT_RESET:
      return initialBillableReport;
    default:
      return state;
  }
}

const initialDateFiltersState = fromJS({
  startDate: moment().startOf(DATE_PRESET_MONTH).toISOString(),
  endDate: moment().endOf(DATE_PRESET_MONTH).toISOString(),
  datePreset: DATE_PRESET_MONTH,
});
export function dateFilters(state = initialDateFiltersState, action) {
  switch (action.type) {
    case SET_DATE_FILTERS:
      const { startDate, endDate } = action.payload;
      return state.set('startDate', startDate).set('endDate', endDate);
    case SET_DATE_PRESET:
      return state.set('datePreset', action.payload);
    default:
      return state;
  }
}

export default combineReducers({ billableReport, dateFilters });

import React from 'react';
import PropTypes from 'prop-types';
import 'ag-grid-enterprise';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

DataGrid.propTypes = {
  ...AgGridReact.propTypes,
  outerProps: PropTypes.object,
  gridRef: PropTypes.any,
};

/**
 * @param gridRef
 * @param outerProps
 * @param {AgGridReactProps} props
 * @returns {JSX.Element}
 * @constructor
 */
function DataGrid({ gridRef, outerProps = {}, ...props }) {
  return (
    <div className='ag-theme-alpine' {...outerProps}>
      <AgGridReact ref={gridRef} domLayout='autoHeight' {...props} />
    </div>
  );
}

export default DataGrid;

import React from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, Cell, Colors, Grid, Link as FoundationLink } from 'react-foundation';
import MainSection from 'routes/ProjectPage/MainSection';
import StatSection from 'routes/ProjectPage/StatSection';
import { projectStatsShape } from 'utils/shapes/stats';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import StatTable from 'components/StatTable';
import H3 from 'components/H3';
import { projectShape } from 'utils/shapes/project';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';

function getClientInvoicingStats(projectStats) {
  if (!projectStats) {
    return null;
  }

  const additionalStats = projectStats.additionalStats;
  const { forecastStats } = additionalStats;
  return [
    {
      title: 'Invoice Amount to Date',
      data: [
        { label: 'Hours', value: Math.round(additionalStats.invoice_amount_to_date.hours) },
        { label: 'Dollars', value: `\$${Math.round(additionalStats.invoice_amount_to_date.dollars).toLocaleString()}` },
      ],
    },
    {
      title: 'Projected Invoice Amount',
      data: [
        { label: 'Hours', value: forecastStats ? Math.round(forecastStats.projectedInvoiceAmount.hours) : 'n/a' },
        {
          label: 'Dollars',
          value: forecastStats
            ? `\$${Math.round(forecastStats.projectedInvoiceAmount.dollars).toLocaleString()}`
            : 'n/a',
        },
      ],
    },
    {
      title: 'Next Month Predictions',
      data: [
        {
          label: 'Hours',
          value: forecastStats ? Math.round(forecastStats.projectedInvoiceAmountNextMonth.hours) : 'n/a',
        },
        {
          label: 'Dollars',
          value: forecastStats
            ? `\$${Math.round(forecastStats.projectedInvoiceAmountNextMonth.dollars).toLocaleString()}`
            : 'n/a',
        },
      ],
    },
  ];
}

function getCaxyInvestmentStats(projectStats) {
  if (!projectStats) {
    return null;
  }
  const additionalStats = projectStats.additionalStats;
  return [
    {
      title: 'Caxy Credits',
      data: [
        { label: 'Hours', value: Math.round(additionalStats.investment_stats.credit.hours) },
        {
          label: 'Dollars',
          value: `\$${Math.round(additionalStats.investment_stats.credit.dollars).toLocaleString()}`,
        },
      ],
    },
    {
      title: 'Accounting Corrections',
      data: [
        { label: 'Hours', value: Math.round(additionalStats.investment_stats.accounting_correction.hours) },
        {
          label: 'Dollars',
          value: `\$${Math.round(additionalStats.investment_stats.accounting_correction.dollars).toLocaleString()}`,
        },
      ],
    },
    {
      title: 'Total Caxy Write-Off',
      data: [
        { label: 'Hours', value: Math.round(additionalStats.investment_stats.total_write_off.hours) },
        {
          label: 'Dollars',
          value: `\$${Math.round(additionalStats.investment_stats.total_write_off.dollars).toLocaleString()}`,
        },
      ],
    },
  ];
}

function getTeamResourcingStats(projectStats) {
  if (!projectStats) {
    return null;
  }
  const additionalStats = projectStats.additionalStats;
  const { forecastStats } = additionalStats;
  return [
    {
      title: 'Average Weekly Allocation',
      data: [
        { label: 'Hours', value: Math.round(additionalStats.average_weekly_allocation.hours) },
        {
          label: 'Dollars',
          value: `\$${Math.round(additionalStats.average_weekly_allocation.dollars).toLocaleString()}`,
        },
      ],
    },
    {
      title: 'Projected Month Allocation',
      data: [
        { label: 'Hours', value: forecastStats ? Math.round(forecastStats.projectedAllocation.hours) : 'n/a' },
        {
          label: 'Dollars',
          value: forecastStats ? `\$${Math.round(forecastStats.projectedAllocation.dollars).toLocaleString()}` : 'n/a',
        },
      ],
    },
    {
      title: 'Next Month Predictions',
      data: [
        { label: 'Hours', value: forecastStats ? Math.round(forecastStats.projectedAllocationNextMonth.hours) : 'n/a' },
        {
          label: 'Dollars',
          value: forecastStats
            ? `\$${Math.round(forecastStats.projectedAllocationNextMonth.dollars).toLocaleString()}`
            : 'n/a',
        },
      ],
    },
  ];
}

function getTicketQualityStats(projectStats) {
  const goodTickets =
    (projectStats.project.jiraProject &&
      projectStats.jiraStats &&
      projectStats.jiraStats.ticket_quality &&
      projectStats.jiraStats.ticket_quality.good_tickets) ||
    'n/a';
  const badTickets =
    (projectStats.project.jiraProject &&
      projectStats.jiraStats &&
      projectStats.jiraStats.ticket_quality &&
      projectStats.jiraStats.ticket_quality.bad_tickets + projectStats.jiraStats.ticket_quality.quality_missing) ||
    'n/a';

  return {
    title: 'Ticket Quality',
    data: [
      { label: 'Good Tickets', value: goodTickets },
      { label: 'Bad Tickets', value: badTickets },
    ],
  };
}

function getJiraStats(projectStats) {
  if (!projectStats) {
    return null;
  }
  const additionalStats = projectStats.additionalStats;
  return [
    {
      title: 'Project Progress (Points)',
      data: [
        { label: 'Completed', value: (projectStats.project.jiraProject && projectStats.storyPointsComplete) || 'n/a' },
        {
          label: 'Remaining',
          value:
            (projectStats.project.jiraProject && projectStats.storyPointsTotal - projectStats.storyPointsComplete) ||
            'n/a',
        },
      ],
    },
    {
      title: 'Issues Without Estimates',
      data: [
        {
          label: 'All Issues',
          value: (projectStats.project.jiraProject && projectStats.numberOfTicketsUnestimated) || 'n/a',
        },
        {
          label: 'Issues Completed',
          value: (projectStats.project.jiraProject && projectStats.numberOfCompletedTicketsUnestimated) || 'n/a',
        },
      ],
    },
    {
      title: 'Average Sprint Commitment',
      data: [
        {
          label: 'Points in Sprint',
          value:
            (additionalStats.jira_sprint_commitments &&
              Math.round(additionalStats.jira_sprint_commitments.averageSprintCommitment)) ||
            'n/a',
        },
        {
          label: 'Points Complete',
          value:
            (additionalStats.jira_sprint_commitments &&
              Math.round(additionalStats.jira_sprint_commitments.averagePointsComplete)) ||
            'n/a',
        },
      ],
    },
    {
      title: 'Cost Per Point',
      data: [
        {
          label: 'Hours/Point',
          value:
            (additionalStats.cost_per_point && Math.round(additionalStats.cost_per_point.hours * 10) / 10) || 'n/a',
        },
        {
          label: 'Dollars/Point',
          value:
            (additionalStats.cost_per_point &&
              `\$${(Math.round(additionalStats.cost_per_point.dollars * 10) / 10).toLocaleString()}`) ||
            'n/a',
        },
      ],
    },
  ];
}

function FilteredStats({ project, projectStats, loading, error }) {
  if (error) {
    return (
      <MainSection>
        <ErrorMessage error={error} title={'An error occurred while loading project stats'} />
      </MainSection>
    );
  }

  const clientInvoicingStats = getClientInvoicingStats(projectStats);
  const caxyInvestmentStats = getCaxyInvestmentStats(projectStats);
  const teamResourcingStats = getTeamResourcingStats(projectStats);
  const jiraStats = getJiraStats(projectStats);

  return (
    <MainSection>
      <StatSection>
        <H3>Client Invoicing Stats</H3>
        <Grid gutters={'margin'}>
          {clientInvoicingStats ? (
            clientInvoicingStats.map(({ title, data }) => (
              <Cell key={title} small={4}>
                <StatTable title={title} data={data} />
              </Cell>
            ))
          ) : (
            <LoadingIndicator />
          )}
        </Grid>
      </StatSection>
      <StatSection>
        <H3>Caxy Investment Stats</H3>
        <Grid gutters={'margin'}>
          {caxyInvestmentStats ? (
            caxyInvestmentStats.map(({ title, data }) => (
              <Cell key={title} small={4}>
                <StatTable title={title} data={data} />
              </Cell>
            ))
          ) : (
            <LoadingIndicator />
          )}
        </Grid>
      </StatSection>
      <StatSection>
        <H3>Team Resourcing Stats</H3>
        <Grid gutters={'margin'}>
          {teamResourcingStats ? (
            teamResourcingStats.map(({ title, data }) => (
              <Cell key={title} small={4}>
                <StatTable title={title} data={data} />
              </Cell>
            ))
          ) : (
            <LoadingIndicator />
          )}
        </Grid>
      </StatSection>
      <StatSection>
        <H3>
          Jira Stats <small>All Time</small>
        </H3>
        <Grid gutters={'margin'}>
          {jiraStats ? (
            jiraStats.map(({ title, data }) => (
              <Cell key={title} small={4}>
                <StatTable title={title} data={data} />
              </Cell>
            ))
          ) : (
            <LoadingIndicator />
          )}
        </Grid>
      </StatSection>
      {project.resourceLinks.length > 0 && (
        <StatSection>
          <H3>Site Links</H3>
          <ButtonGroup>
            {project.resourceLinks.map(({ linkText, url }) => (
              <FoundationLink href={url} color={Colors.SECONDARY} isHollow target='_blank'>
                {linkText}
              </FoundationLink>
            ))}
          </ButtonGroup>
        </StatSection>
      )}
    </MainSection>
  );
}

FilteredStats.propTypes = {
  project: projectShape.isRequired,
  projectStats: projectStatsShape,
  loading: PropTypes.bool,
  error: PropTypes.string,
};

export default FilteredStats;

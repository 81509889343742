import styled, { css } from 'styled-components/macro';
import { ifProp } from 'styled-tools';

const H4 = styled.h4`
  font-size: 0.875em;
  font-weight: 500;
  line-height: 1.07;
  letter-spacing: -0.2px;
  text-transform: uppercase;
  text-align: ${(props) => (props.center ? 'center' : 'left')};

  ${ifProp(
    'white',
    css`
      color: white;
    `
  )}
`;

export default H4;

import { createGlobalStyle } from 'styled-components/macro';

const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
  }
  
  body {
    margin: 0;
    padding: 0;
    font-family: 'Work Sans', sans-serif;
    font-weight: 400;
    color: #000000;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Work Sans', sans-serif;
  }
  
  /**
   * For modern browsers
   * 1. The space content is one way to avoid an Opera bug when the
   *    contenteditable attribute is included anywhere else in the document.
   *    Otherwise it causes space to appear at the top and bottom of elements
   *    that are clearfixed.
   * 2. The use of \`table\` rather than \`block\` is only necessary if using
   *    \`:before\` to contain the top-margins of child elements.
   */
  .clearfix:before,
  .clearfix:after {
      content: " "; /* 1 */
      display: table; /* 2 */
  }
  
  .clearfix:after {
      clear: both;
  }
  
  /**
   * For IE 6/7 only
   * Include this rule to trigger hasLayout and contain floats.
   */
  .clearfix {
      *zoom: 1;
  }
  
  /**
   * react-dates style overrides.
   */
   
  .DateRangePicker_picker {
    z-index: 500;
  }

`;

export default GlobalStyle;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

const Name = styled.span`
  font-family: ${(props) => props.theme.titleFontFamily};
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.2px;
  color: #808080;
  margin-right: 15px;
`;

const Value = styled.span`
	font-family: ${(props) => props.theme.titleFontFamily};
	font-size: 13px;
	font-weight: 500;
	line-height: 2.15;
	color: #084159;
}
`;

function ProjectMeta({ name, value }) {
  return (
    <div>
      <Name>{name}</Name>
      <Value>{value}</Value>
    </div>
  );
}

ProjectMeta.propTypes = {
  name: PropTypes.node,
  value: PropTypes.node,
};

export default ProjectMeta;

import { createStructuredSelector } from 'reselect';
import { selectError, selectDateFilters, selectLoading, selectData } from './../selectors';
import { resetData, getBillableReport, createNote, updateNote } from './../reducer';
import { connect } from 'react-redux';
import { toJS } from 'utils/toJS';
import MonthlyBillableReport from '../components';

const mapStateToProps = createStructuredSelector({
  error: selectError,
  loading: selectLoading,
  data: selectData,
  filters: selectDateFilters,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getBillableReport: (startDate, endDate) => dispatch(getBillableReport(startDate, endDate)),
    createNote: (data) => dispatch(createNote(data)),
    updateNote: (id, data) => dispatch(updateNote(id, data)),
    reset: () => {
      dispatch(resetData());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(toJS(MonthlyBillableReport));

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import ProjectNotes from 'components/ProjectNotes';
import { toJS } from 'utils/toJS';
import {
  selectError,
  selectData,
  selectLoading,
  selectCreateError,
  selectSaving,
} from 'routes/ProjectPage/containers/ProjectNotesContainer/selectors';
import { load, reset, create } from 'routes/ProjectPage/containers/ProjectNotesContainer/actions';
import { RESOURCE_PROJECT } from 'utils/constants';
import { createIri } from 'utils/helpers';

const mapStateToProps = (state, ownProps) => ({
  project: ownProps.project,
  data: selectData(state),
  error: selectError(state),
  loading: selectLoading(state),
  createError: selectCreateError(state),
  saving: selectSaving(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  load: () => dispatch(load(ownProps.project.id)),
  reset: () => dispatch(reset()),
  submitComment: (comment) =>
    dispatch(
      create({
        message: comment.get('message'),
        project: createIri(ownProps.project.id, RESOURCE_PROJECT),
      })
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(toJS(ProjectNotes));

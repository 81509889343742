import styled from 'styled-components/macro';
import { Callout, Cell, Colors, Grid, GridContainer } from 'react-foundation';
import DropdownButton from 'components/DropdownButton';
import { PROJECT_LIST_FILTER_MESSAGES } from 'utils/messages';
import FontAwesome from 'react-fontawesome';
import { PROJECT_LIST_FILTERS } from 'utils/constants';
import DashboardDateRangeFilter from 'routes/DashboardPage/containers/DashboardDateRangeFilter';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import ProjectListTable from 'routes/DashboardPage/components/ProjectListTable';
import React from 'react';
import H1 from 'components/H1';
import { darken } from 'polished';
import PropTypes from 'prop-types';

const ProjectListContainer = styled(GridContainer)`
  margin-top: 2rem;
`;
const Ul = styled.ul`
  max-height: 400px;
  overflow-y: auto;
  margin: 0;
  list-style: none;

  ul {
    margin: 0;
    width: 100%;
  }

  li {
    list-style: none;

    a {
      display: block;
      width: 100%;
      padding: 0.5rem 2rem;
      border-bottom: 1px solid ${(props) => props.theme.cardBorderColor};
      color: ${(props) => props.theme.baseFontColor};

      &:last-of-type {
        border-bottom: none;
      }

      &:hover {
        color: #ffffff;
        background-color: ${(props) => props.theme.primaryFontColor};
      }
    }
  }
`;
const H1Dropdown = styled(H1)`
  display: flex;
  align-items: center;

  &:hover {
    color: ${(props) => darken(0.2, props.theme.titleColorPrimary)};
  }

  .fa {
    font-size: 0.6em;
    margin-left: 0.8rem;
  }
`;

ProjectList.propTypes = {
  projectFilter: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.string,
  projectsSorted: PropTypes.any,
  allProjectStats: PropTypes.shape({ loading: PropTypes.bool, error: PropTypes.string, data: PropTypes.any }),
};

export default function ProjectList({
  allProjectStats,
  error,
  loading,
  projectFilter,
  projectsSorted,
  updateProjectFilter,
}) {
  return (
    <ProjectListContainer>
      <GridContainer>
        <Grid gutters={'none'} className={'align-middle align-justify'}>
          <Cell small={4}>
            <DropdownButton
              label={
                <H1Dropdown>
                  {PROJECT_LIST_FILTER_MESSAGES[projectFilter]}
                  <FontAwesome name='chevron-down' />
                </H1Dropdown>
              }
              buttonComponent={'a'}
            >
              <Ul>
                {PROJECT_LIST_FILTERS.map((filter) => (
                  <li key={filter}>
                    <a onClick={() => updateProjectFilter(filter)}>{PROJECT_LIST_FILTER_MESSAGES[filter]}</a>
                  </li>
                ))}
              </Ul>
            </DropdownButton>
          </Cell>
          <Cell small={4}>
            <DashboardDateRangeFilter className={'float-right'} />
          </Cell>
        </Grid>
      </GridContainer>
      {loading && (
        <Callout color={Colors.SECONDARY}>
          <p>Loading...</p>
        </Callout>
      )}
      {error && (
        <Callout color={Colors.ALERT}>
          <h5>An error occurred while loading the page</h5>
          <p>{error}</p>
        </Callout>
      )}
      {!projectsSorted || allProjectStats.loading ? (
        <LoadingIndicator />
      ) : (
        <ProjectListTable projects={projectsSorted} allProjectStats={allProjectStats} />
      )}
    </ProjectListContainer>
  );
}

import orderBy from 'lodash/orderBy';
import NumberStat from 'components/NumberStat/NumberStat';
import PropTypes from 'prop-types';
import { taskBreakdownShape } from 'utils/shapes/stats';
import React from 'react';
import BudgetSummary from 'routes/Reports/routes/BudgetSummary/components/index';
import styled from 'styled-components/macro';
import theme from 'theme';
import { HOURS_BY_TASK_SORT_ALPHA, HOURS_BY_TASK_SORT_HOURS, HOURS_BY_TASK_SORT_OPTIONS } from 'utils/constants';

const LI = styled.li`
  color: ${(props) => (props.$billable ? undefined : theme.colorRed)};
`;

function makeSortFunc(sort) {
  return (breakdown) => {
    switch (sort) {
      case HOURS_BY_TASK_SORT_HOURS:
        return breakdown.budget_stat.hours;

      case HOURS_BY_TASK_SORT_ALPHA:
        return breakdown.task_name.toLowerCase();
    }
  };
}

function getSortDirection(sort) {
  switch (sort) {
    case HOURS_BY_TASK_SORT_HOURS:
      return 'desc';

    case HOURS_BY_TASK_SORT_ALPHA:
      return 'asc';
  }
}

function HoursByTask({ breakdowns, sort = HOURS_BY_TASK_SORT_HOURS }) {
  return (
    <ul>
      {orderBy(
        BudgetSummary.filterBilledTasks(breakdowns),
        [(breakdown) => (breakdown.is_billable ? 0 : 1), makeSortFunc(sort)],
        ['asc', getSortDirection(sort)]
      ).map((breakdown) => (
        <LI key={breakdown.task_name} $billable={breakdown.is_billable}>
          <strong>{breakdown.task_name}: </strong>
          <NumberStat
            value={breakdown.budget_stat.hours}
            suffix={' hours'}
            minimumFractionDigits={2}
            maximumFractionDigits={4}
          />
        </LI>
      ))}
    </ul>
  );
}

HoursByTask.propTypes = {
  breakdowns: PropTypes.arrayOf(taskBreakdownShape),
  sort: PropTypes.oneOf(HOURS_BY_TASK_SORT_OPTIONS),
};

export default HoursByTask;

import styled from 'styled-components/macro';

const H3 = styled.h3`
	font-size: 15px;
	letter-spacing: 0.4px;
	text-transform: uppercase;
	color: ${(props) => props.theme.primaryFontColor}
	text-align: ${(props) => (props.center ? 'center' : 'left')}
`;

export default H3;

/**
 *
 * @param {Document} targetDocument
 * @param {Document} sourceDocument
 * @param {string[]} elementIds
 */
export function replaceNodesInDocument(targetDocument, sourceDocument, elementIds) {
  elementIds.forEach((elementId) => {
    const sourceElement = sourceDocument.getElementById(elementId);
    const targetElement = targetDocument.getElementById(elementId);
    targetElement.parentNode.replaceChild(sourceElement, targetElement);
  });
}

export function verifyElementsExistInDocument(document, elementIds) {
  return !elementIds.find((elementId) => !document.getElementById(elementId));
}

import styled from 'styled-components/macro';

const H1 = styled.h1`
  color: ${(props) => props.theme.titleColorPrimary};
  font-size: 1.8125em;
  font-weight: 800;
  line-height: 1.59;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  margin-top: 15px;
  margin-bottom: 15px;
`;

export default H1;

import React from 'react';
import { Iterable } from 'immutable';

/**
 * Something needs to map the Immutable.JS props in your Smart Component to the pure JavaScript props used in your
 * Dumb Component. That something is a Higher Order Component (HOC) that simply takes the Immutable.JS props from
 * your Smart Component, and converts them using toJS() to plain JavaScript props, which are then passed to  your
 * Dumb Component.
 *
 * @see https://redux.js.org/recipes/using-immutable.js-with-redux#use-a-higher-order-component-to-convert-your-smart-component's-immutable.js-props-to-your-dumb-component's-javascript-props
 *
 * @param WrappedComponent
 * @returns {function(*=)}
 */
export function toJS(WrappedComponent) {
  return function toJSInner(wrappedComponentProps) {
    const KEY = 0;
    const VALUE = 1;

    const propsJS = Object.entries(wrappedComponentProps).reduce((newProps, wrappedComponentProp) => {
      newProps[wrappedComponentProp[KEY]] = Iterable.isIterable(wrappedComponentProp[VALUE])
        ? wrappedComponentProp[VALUE].toJS()
        : wrappedComponentProp[VALUE];
      return newProps;
    }, {});

    return <WrappedComponent {...propsJS} />;
  };
}

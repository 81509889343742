import fetch from 'utils/fetch';

const namespace = 'app/ProjectNotesContainer';
const collectionPath = '/projects/:id/comments';
const itemPath = '/comments';

export const REQUEST_LOADING = `${namespace}/REQUEST_LOADING`;
export const REQUEST_SAVING = `${namespace}/REQUEST_SAVING`;
export const REQUEST_ERROR = `${namespace}/REQUEST_ERROR`;
export const REQUEST_SUCCESS = `${namespace}/REQUEST_SUCCESS`;
export const CREATE_SUCCESS = `${namespace}/CREATE_SUCCESS`;
export const CREATE_ERROR = `${namespace}/CREATE_ERROR`;
export const RESET = `${namespace}/RESET`;

export function error(error) {
  return { type: REQUEST_ERROR, error };
}

export function loading(loading) {
  return { type: REQUEST_LOADING, loading };
}

export function saving(saving) {
  return { type: REQUEST_SAVING, saving };
}

export function success(payload) {
  return { type: REQUEST_SUCCESS, payload };
}

export function createSuccess(payload) {
  return { type: CREATE_SUCCESS, payload };
}

export function createError(error) {
  return { type: CREATE_ERROR, error };
}

export function reset() {
  return { type: RESET };
}

export function load(id) {
  return (dispatch) => {
    dispatch(loading(true));

    return fetch(collectionPath.replace(':id', id))
      .then((response) => response.json())
      .then((data) => {
        dispatch(loading(false));
        dispatch(success(data['hydra:member']));
      })
      .catch((e) => {
        dispatch(loading(false));
        dispatch(error(e.message));
      });
  };
}

export function create(payload) {
  return (dispatch) => {
    dispatch(saving(true));

    return fetch(itemPath, {
      body: JSON.stringify(payload),
      method: 'POST',
    })
      .then((response) => response.json())
      .then((data) => {
        dispatch(saving(false));
        dispatch(createSuccess(data));
      })
      .catch((e) => {
        dispatch(saving(false));
        dispatch(createError(e.message));
      });
  };
}

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';

const defaultProps = {
  style: 'percent',
  useGrouping: true,
  minimumIntegerDigits: 1,
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
};

function Percentage({ value, ...otherProps }) {
  const props = { ...defaultProps, ...otherProps };
  return <FormattedNumber value={value} {...props} />;
}

Percentage.propTypes = {
  value: PropTypes.any.isRequired,
};

export default Percentage;

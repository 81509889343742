import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form/immutable';
import { Button, Colors } from 'react-foundation';

class LoginForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    loading: PropTypes.bool,
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { handleSubmit, loading } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor='email'>Username</label>
          <Field name='email' component='input' type='text' />
        </div>
        <div>
          <label htmlFor='password'>Password</label>
          <Field name='password' component='input' type='password' />
        </div>
        <Button type='submit' isDisabled={loading} color={Colors.PRIMARY}>
          Log In
        </Button>
      </form>
    );
  }
}

export default reduxForm({
  form: 'login',
})(LoginForm);

import fetch from 'utils/fetch';

export const SET_DATE_FILTERS = 'app/ProjectPage/SET_DATE_FILTERS';
export const SET_DATE_PRESET = 'app/ProjectPage/SET_DATE_PRESET';
export const OVERVIEW_STATS_LOADING = 'app/ProjectPage/OVERVIEW_STATS_LOADING';
export const OVERVIEW_STATS_SUCCESS = 'app/ProjectPage/OVERVIEW_STATS_SUCCESS';
export const OVERVIEW_STATS_ERROR = 'app/ProjectPage/OVERVIEW_STATS_ERROR';
export const OVERVIEW_STATS_RESET = 'app/ProjectPage/OVERVIEW_STATS_RESET';
export const FILTERED_STATS_LOADING = 'app/ProjectPage/FILTERED_STATS_LOADING';
export const FILTERED_STATS_SUCCESS = 'app/ProjectPage/FILTERED_STATS_SUCCESS';
export const FILTERED_STATS_ERROR = 'app/ProjectPage/FILTERED_STATS_ERROR';
export const FILTERED_STATS_RESET = 'app/ProjectPage/FILTERED_STATS_RESET';

export function error(error) {
  return { type: 'PROJECT_SHOW_ERROR', error };
}

export function loading(loading) {
  return { type: 'PROJECT_SHOW_LOADING', loading };
}

export function retrieved(retrieved) {
  return { type: 'PROJECT_SHOW_RETRIEVED_SUCCESS', retrieved };
}

export function overviewStatsError(error) {
  return { type: OVERVIEW_STATS_ERROR, payload: error };
}

export function overviewStatsLoading(loading) {
  return { type: OVERVIEW_STATS_LOADING, payload: loading };
}

export function overviewStatsSuccess(payload) {
  return { type: OVERVIEW_STATS_SUCCESS, payload };
}

export function overviewStatsReset() {
  return { type: OVERVIEW_STATS_RESET };
}

export function filteredStatsError(error) {
  return { type: FILTERED_STATS_ERROR, payload: error };
}

export function filteredStatsLoading(loading) {
  return { type: FILTERED_STATS_LOADING, payload: loading };
}

export function filteredStatsSuccess(payload) {
  return { type: FILTERED_STATS_SUCCESS, payload };
}

export function filteredStatsReset() {
  return { type: FILTERED_STATS_RESET };
}

export function retrieve(id) {
  return (dispatch) => {
    dispatch(loading(true));

    return fetch(`/projects/${id}`)
      .then((response) => response.json())
      .then((data) => {
        dispatch(loading(false));
        dispatch(retrieved(data));
      })
      .catch((e) => {
        dispatch(loading(false));
        dispatch(error(e.message));
      });
  };
}

export function getOverviewStats(id) {
  return (dispatch) => {
    dispatch(overviewStatsLoading(true));

    const query = '?fields=overviewStats';

    return fetch(`/projects/${id}/project_stats${query}`)
      .then((response) => response.json())
      .then((data) => {
        dispatch(overviewStatsLoading(false));
        dispatch(overviewStatsSuccess(data));
      })
      .catch((e) => {
        dispatch(overviewStatsLoading(false));
        dispatch(overviewStatsError(e.message));
      });
  };
}

export function getFilteredStats(id, startDate, endDate) {
  return (dispatch) => {
    dispatch(filteredStatsLoading(true));

    const query = `?start_date=${startDate}&end_date=${endDate}`;

    return fetch(`/projects/${id}/project_stats${query}`)
      .then((response) => response.json())
      .then((data) => {
        dispatch(filteredStatsLoading(false));
        dispatch(filteredStatsSuccess(data));
      })
      .catch((e) => {
        dispatch(filteredStatsLoading(false));
        dispatch(filteredStatsError(e.message));
      });
  };
}

export function setDateFilters(startDate, endDate) {
  return {
    type: SET_DATE_FILTERS,
    payload: { startDate, endDate },
  };
}

export function setDatePreset(preset) {
  return {
    type: SET_DATE_PRESET,
    payload: preset,
  };
}

export function reset() {
  return { type: 'PROJECT_SHOW_RESET' };
}

import { fromJS } from 'immutable';
import { combineReducers } from 'redux-immutable';
import fetch from 'utils/fetch';

export const DAILY_RECAP_STATS_LOADING = 'app/DailyRecap/DAILY_RECAP_STATS_LOADING';
export const DAILY_RECAP_STATS_SUCCESS = 'app/DailyRecap/DAILY_RECAP_STATS_SUCCESS';
export const DAILY_RECAP_STATS_ERROR = 'app/DailyRecap/DAILY_RECAP_STATS_ERROR';
export const DAILY_RECAP_STATS_RESET = 'app/DailyRecap/DAILY_RECAP_STATS_RESET';

export const DAILY_RECAP_ENTRY_LOADING = 'app/DailyRecap/DAILY_RECAP_ENTRY_LOADING';
export const DAILY_RECAP_ENTRY_SUCCESS = 'app/DailyRecap/DAILY_RECAP_ENTRY_SUCCESS';
export const DAILY_RECAP_ENTRY_ERROR = 'app/DailyRecap/DAILY_RECAP_ENTRY_ERROR';
export const DAILY_RECAP_ENTRY_RESET = 'app/DailyRecap/DAILY_RECAP_ENTRY_RESET';
export const DAILY_RECAP_ENTRY_SAVING = 'app/DailyRecap/DAILY_RECAP_ENTRY_SAVING';
export const DAILY_RECAP_ENTRY_UPDATED = 'app/DailyRecap/DAILY_RECAP_ENTRY_UPDATED';

export const PROJECT_STATS_LOADING = 'app/DailyRecap/PROJECT_STATS_LOADING';
export const PROJECT_STATS_SUCCESS = 'app/DailyRecap/PROJECT_STATS_SUCCESS';
export const PROJECT_STATS_ERROR = 'app/DailyRecap/PROJECT_STATS_ERROR';
export const PROJECT_STATS_RESET = 'app/DailyRecap/PROJECT_STATS_RESET';

export const SET_SHOW_JIRA_LINKS = 'app/DailyRecap/SET_SHOW_JIRA_LINKS';

export function dailyRecapStatsError(error) {
  return { type: DAILY_RECAP_STATS_ERROR, payload: error };
}

export function dailyRecapStatsLoading(loading) {
  return { type: DAILY_RECAP_STATS_LOADING, payload: loading };
}

export function dailyRecapStatsSuccess(payload) {
  return { type: DAILY_RECAP_STATS_SUCCESS, payload };
}

export function dailyRecapStatsReset() {
  return { type: DAILY_RECAP_STATS_RESET };
}

export function dailyRecapEntryError(error) {
  return { type: DAILY_RECAP_ENTRY_ERROR, payload: error };
}

export function dailyRecapEntryLoading(loading) {
  return { type: DAILY_RECAP_ENTRY_LOADING, payload: loading };
}

export function dailyRecapEntrySaving(saving) {
  return { type: DAILY_RECAP_ENTRY_SAVING, payload: saving };
}

export function dailyRecapEntrySuccess(payload) {
  return { type: DAILY_RECAP_ENTRY_SUCCESS, payload };
}

export function projectStatsError(error) {
  return { type: PROJECT_STATS_ERROR, payload: error };
}

export function projectStatsLoading(loading) {
  return { type: PROJECT_STATS_LOADING, payload: loading };
}

export function projectStatsSuccess(payload) {
  return { type: PROJECT_STATS_SUCCESS, payload };
}

export function projectStatsReset() {
  return { type: PROJECT_STATS_RESET };
}

export function setShowJiraLinks(payload) {
  return { type: SET_SHOW_JIRA_LINKS, payload };
}

export function getDailyRecapStats(id) {
  return (dispatch) => {
    dispatch(dailyRecapStatsLoading(true));

    return fetch(`/projects/${id}/daily_recap_stats`)
      .then((response) => response.json())
      .then((data) => {
        dispatch(dailyRecapStatsLoading(false));
        dispatch(dailyRecapStatsSuccess(data));
      })
      .catch((e) => {
        dispatch(dailyRecapStatsLoading(false));
        dispatch(dailyRecapStatsError(e.message));
      });
  };
}

export function createDailyRecapEntry(entry) {
  return (dispatch) => {
    dispatch(dailyRecapEntryLoading(true));

    return fetch(`/daily_recap_entries`, {
      body: JSON.stringify(entry),
      method: 'POST',
    })
      .then((response) => response.json())
      .then((data) => {
        dispatch(dailyRecapEntryLoading(false));
        dispatch(dailyRecapEntrySuccess(data));
      })
      .catch((e) => {
        dispatch(dailyRecapEntryLoading(false));
        dispatch(dailyRecapEntryError(e.message));
      });
  };
}

export function getProjectStats(id) {
  return (dispatch) => {
    dispatch(projectStatsLoading(true));

    return fetch(`/projects/${id}/project_stats`)
      .then((response) => response.json())
      .then((data) => {
        dispatch(projectStatsLoading(false));
        dispatch(projectStatsSuccess(data));
      })
      .catch((e) => {
        dispatch(projectStatsLoading(false));
        dispatch(projectStatsError(e.message));
      });
  };
}

const initialDailyRecapStatsState = fromJS({
  loading: false,
  error: undefined,
  data: undefined,
});
export function dailyRecapStats(state = initialDailyRecapStatsState, action) {
  switch (action.type) {
    case DAILY_RECAP_STATS_ERROR:
      return state.set('error', action.payload);
    case DAILY_RECAP_STATS_LOADING:
      return state.set('loading', action.payload);
    case DAILY_RECAP_STATS_SUCCESS:
      return state.set('data', action.payload);
    case DAILY_RECAP_STATS_RESET:
      return initialDailyRecapStatsState;
    default:
      return state;
  }
}

const initialDailyRecapEntryState = fromJS({
  loading: false,
  saving: false,
  error: undefined,
  data: undefined,
});
export function dailyRecapEntry(state = initialDailyRecapEntryState, action) {
  switch (action.type) {
    case DAILY_RECAP_ENTRY_ERROR:
      return state.set('error', action.payload);
    case DAILY_RECAP_ENTRY_LOADING:
      return state.set('loading', action.payload);
    case DAILY_RECAP_ENTRY_SAVING:
      return state.set('saving', action.payload);
    case DAILY_RECAP_ENTRY_SUCCESS:
      return state.set('data', action.payload);
    case DAILY_RECAP_ENTRY_RESET:
      return initialDailyRecapEntryState;
    case DAILY_RECAP_ENTRY_UPDATED:
      return state.update('data', (data) => ({ ...data, 'hydra:member': [action.payload] }));
    default:
      return state;
  }
}

const initialProjectStatsState = fromJS({
  loading: false,
  error: undefined,
  data: undefined,
});
export function projectStats(state = initialProjectStatsState, action) {
  switch (action.type) {
    case PROJECT_STATS_ERROR:
      return state.set('error', action.payload);
    case PROJECT_STATS_LOADING:
      return state.set('loading', action.payload);
    case PROJECT_STATS_SUCCESS:
      return state.set('data', action.payload);
    case PROJECT_STATS_RESET:
      return initialProjectStatsState;
    default:
      return state;
  }
}

const initialReportFiltersState = fromJS({
  showJiraLinks: false,
});
export function reportFilters(state = initialReportFiltersState, action) {
  switch (action.type) {
    case SET_SHOW_JIRA_LINKS:
      return state.set('showJiraLinks', action.payload);
    default:
      return state;
  }
}

export default combineReducers({ dailyRecapStats, projectStats, reportFilters, dailyRecapEntry });

import { FILTERED_STATS_RESET, OVERVIEW_STATS_RESET, SET_DATE_PRESET } from 'routes/ProjectPage/actions';
import { combineReducers } from 'redux-immutable';
import moment from 'moment/moment';
import { fromJS } from 'immutable';
import { DATE_PRESET_MONTH } from 'utils/constants';
import {
  FILTERED_STATS_ERROR,
  FILTERED_STATS_LOADING,
  FILTERED_STATS_SUCCESS,
  OVERVIEW_STATS_ERROR,
  OVERVIEW_STATS_LOADING,
  OVERVIEW_STATS_SUCCESS,
  SET_DATE_FILTERS,
} from 'routes/ProjectPage/actions';

export function error(state = null, action) {
  switch (action.type) {
    case 'PROJECT_SHOW_ERROR':
      return action.error;

    case 'PROJECT_SHOW_RESET':
      return null;

    default:
      return state;
  }
}

export function loading(state = false, action) {
  switch (action.type) {
    case 'PROJECT_SHOW_LOADING':
      return action.loading;

    case 'PROJECT_SHOW_RESET':
      return false;

    default:
      return state;
  }
}

export function retrieved(state = null, action) {
  switch (action.type) {
    case 'PROJECT_SHOW_RETRIEVED_SUCCESS':
      return fromJS(action.retrieved);

    case 'PROJECT_SHOW_RESET':
      return null;

    default:
      return state;
  }
}

const initialFiltersState = fromJS({
  startDate: moment().startOf(DATE_PRESET_MONTH).toISOString(),
  endDate: moment().endOf(DATE_PRESET_MONTH).toISOString(),
  datePreset: DATE_PRESET_MONTH,
});
export function filters(state = initialFiltersState, action) {
  switch (action.type) {
    case SET_DATE_FILTERS:
      const { startDate, endDate } = action.payload;
      return state.set('startDate', startDate).set('endDate', endDate);
    case SET_DATE_PRESET:
      return state.set('datePreset', action.payload);
    default:
      return state;
  }
}

const initialOverviewStats = fromJS({
  loading: false,
  error: undefined,
  data: undefined,
});
export function overviewStats(state = initialOverviewStats, action) {
  switch (action.type) {
    case OVERVIEW_STATS_ERROR:
      return state.set('error', action.payload);
    case OVERVIEW_STATS_LOADING:
      return state.set('loading', action.payload);
    case OVERVIEW_STATS_SUCCESS:
      return state.set('data', action.payload);
    case OVERVIEW_STATS_RESET:
    case 'PROJECT_SHOW_RESET':
      return initialOverviewStats;
    default:
      return state;
  }
}

const initialFilteredStats = fromJS({
  loading: false,
  error: undefined,
  data: undefined,
});
export function filteredStats(state = initialFilteredStats, action) {
  switch (action.type) {
    case FILTERED_STATS_ERROR:
      return state.set('error', action.payload);
    case FILTERED_STATS_LOADING:
      return state.set('loading', action.payload);
    case FILTERED_STATS_SUCCESS:
      return state.set('data', action.payload);
    case FILTERED_STATS_RESET:
      return initialFilteredStats;
    case 'PROJECT_SHOW_RESET':
      return initialFilteredStats;
    default:
      return state;
  }
}

export default combineReducers({ error, loading, retrieved, filters, overviewStats, filteredStats });

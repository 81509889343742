import MuiGrid from '@material-ui/core/Grid';
import InfoIcon from '@material-ui/icons/Info';
import React from 'react';
import styled, { css } from 'styled-components';
import Currency from 'components/Currency/Currency';
import { ifProp } from 'styled-tools';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import moment from 'moment';
import Divider from '@material-ui/core/Divider';
import CardContent from '@material-ui/core/CardContent';
import Percentage from 'components/Percentage/Percentage';
import NumberStat from 'components/NumberStat';
import PropTypes from 'prop-types';
import LabelAndValue from './LabelAndValue';
import HtmlTooltip from './HtmlTooltip';
import HoursSplitBreakdown from 'routes/DashboardPage/components/HoursSplitBreakdown';

const CardStatLabel = styled.span`
  font-size: 0.9rem;
  font-weight: 500;
`;

const CardStatValue = styled.span`
  font-size: 1.5rem;
  font-weight: 500;

  ${ifProp(
    '$padRight',
    css`
      padding-right: 1rem;
    `
  )}
`;

CardStat.propTypes = {
  value: PropTypes.node,
  label: PropTypes.node,
  tooltip: PropTypes.node,
};

export function CardStat({ label, value, tooltip }) {
  return (
    <LabelAndValue label={<CardStatLabel>{label}</CardStatLabel>}>
      <MuiGrid container justify='flex-end' alignItems='center' wrap='nowrap' spacing={1}>
        <MuiGrid item>
          <CardStatValue $padRight={!tooltip}>{value}</CardStatValue>
        </MuiGrid>
        {tooltip && (
          <HtmlTooltip title={tooltip} arrow placement='bottom-end' interactive>
            <InfoIcon fontSize='inherit' />
          </HtmlTooltip>
        )}
      </MuiGrid>
    </LabelAndValue>
  );
}

MonthBillingCard.propTypes = {
  value: PropTypes.any,
  data: PropTypes.any,
  value1: PropTypes.any,
  value2: PropTypes.any,
  value3: PropTypes.any,
  endOfMonthProjection: PropTypes.any,
};

export default function MonthBillingCard({
  billingBreakdown,
  endOfMonthProjection,
  billablePercent,
  hoursMissing,
  hoursReportedPercent,
  dailyRevenueAccrual,
  title,
  subTitle,
}) {
  return (
    <Card>
      <CardHeader
        title={title || moment().format('MMMM, DD YYYY').toUpperCase()}
        subheader={subTitle || 'MTD Reporting'}
      />
      <Divider />
      <CardContent>
        <CardStat
          label='MTD Billability'
          // value={<Percentage value={billablePercent}/>}
          value={'-'}
          tooltip={<HoursSplitBreakdown data={billingBreakdown} />}
        />
        <CardStat
          label='MTD Total Hours Missing'
          value={<NumberStat value={hoursMissing} />}
          tooltip={
            <>
              <Percentage value={hoursReportedPercent} /> Hrs Reported
            </>
          }
        />
        <CardStat
          label='MTD Revenue'
          value={<Currency value={billingBreakdown.invoiced.dollars} />}
          tooltip={
            <>
              Avg. Daily Revenue Accrual: <Currency value={dailyRevenueAccrual} />
            </>
          }
        />
        <CardStat
          label='MTD Projection Revenue'
          value={<Currency value={endOfMonthProjection.invoiced.dollars} />}
          tooltip={<HoursSplitBreakdown data={endOfMonthProjection} />}
        />
      </CardContent>
    </Card>
  );
}

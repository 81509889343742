import moment from 'moment';
import EmailHorizontalRule from 'routes/Reports/routes/BudgetSummary/components/EmailHorizontalRule';
import React from 'react';
import Percentage from 'components/Percentage/Percentage';
import Currency from 'components/Currency/Currency';
import NumberStat from 'components/NumberStat/NumberStat';
import PropTypes from 'prop-types';
import { projectShape } from 'utils/shapes/project';
import { projectStatsShape } from 'utils/shapes/stats';
import styled from 'styled-components/macro';
import BillableBudgetUsed from 'routes/Reports/routes/BudgetSummary/components/BillableBudgetUsed';
import HoursBreakdown from 'routes/Reports/routes/BudgetSummary/components/HoursBreakdown';

const PlaceholderText = styled.mark`
  //background-color: ;
`;

const Placeholder = ({ children, noSpaces = false }) => {
  return (
    <>
      [{noSpaces ? '' : ' '}
      <PlaceholderText>{children}</PlaceholderText>
      {noSpaces ? '' : ' '}]
    </>
  );
};

function getContactNames(project) {
  if (!project || !project.budgetSummaryContacts || project.budgetSummaryContacts.length === 0) {
    return;
  }

  const firstNames = project.budgetSummaryContacts.map((x) => x.firstName).filter((x) => !!x);

  return firstNames.length > 0 ? firstNames.join(', ') + (firstNames.length > 1 ? ',' : '') : undefined;
}

function EmailContent({
  project,
  overviewStats,
  filteredStats,
  filters,
  previousPayPeriodStats,
  previousPayPeriodFilters,
  reportFilters,
}) {
  const hasBudget = project && project.currentBudget > 0;
  const hasJira = project && !!project.jiraProject;
  const stats = overviewStats.data && overviewStats.data.overviewStats;

  const budgetUsedPercent = hasBudget && stats ? stats.budget_used.hours / project.currentBudget : 0;
  const budgetRemainingPercent = Math.max(0, hasBudget && stats ? 1 - budgetUsedPercent : 0);
  const projectCompletePercent =
    stats && stats.story_points_total ? stats.story_points_completed / stats.story_points_total : 0;
  const projectRemainingPercent = 1 - projectCompletePercent;

  const filterStats = filteredStats.data;
  const hoursPaidByCaxy = project && filterStats && filterStats.internallyBilledHours;
  const dollarsPaidByCaxy = project && filterStats && filterStats.internallyBilledHours * project.hourlyRate;
  const mtdStats = filterStats && filterStats.additionalStats;
  const prevStats = previousPayPeriodStats.data && previousPayPeriodStats.data.additionalStats;

  const now = moment();
  const start = moment(filters.startDate);
  let end = moment(filters.endDate);

  if (end.isAfter(now)) {
    end = now;
  }

  const { start: prevStart, end: prevEnd } = previousPayPeriodFilters;

  return (
    <div className='email-content'>
      <p>
        Hey{' '}
        {getContactNames(project) || (
          <>
            [<PlaceholderText>Name of Person</PlaceholderText>]
          </>
        )}{' '}
        and All,
      </p>
      <p>Please see below for our current progress and relevant notes.</p>
      <p>
        <b>
          Our Next Planned Meeting is: [<PlaceholderText>Name of Meeting and Date</PlaceholderText>].
        </b>
      </p>
      <p>
        <b>
          Please let me know how we should adjust this information to make it more meaningful for you. It’s helpful for
          you to confirm receipt and understanding of this email as it’s a critical document outlining project health
          status and trajectory.
        </b>{' '}
        If you have any questions at all about this budget or the project before our next meeting, please don't hesitate
        to reach me directly. My contact info is provided below.
      </p>
      <EmailHorizontalRule />
      <h3>Project Status Information and Updates</h3>
      <h5>Current Goal</h5>
      <p>
        [{' '}
        <PlaceholderText>
          The current goal should highlight your progress toward your sprint vision. At the beginning of the sprint, it
          will be the full vision of work to do. In the middle and end of the sprint, it will include the things that
          are done vs. the things still underway. It should also include any outlier updates, redflags, adjustments, and
          needs that exist for the client to be aware of to make the best project decisions. It should also include
          information about what is needed in order to continue a positive trajectory or reset of expectations.
        </PlaceholderText>{' '}
        ]
      </p>
      <h5>General Updates</h5>
      <h6>Work Completed Since Last Update</h6>
      <ul>
        <li>
          [<PlaceholderText>List of tickets most recently marked done from current sprint</PlaceholderText>]
        </li>
        <li>
          [
          <PlaceholderText>
            Other list of things that are now done which may include planning, architecture, working sessions, access
            granted, and other things that don’t have tickets{' '}
          </PlaceholderText>
          ]
        </li>
        <li>
          [<PlaceholderText>It may also include followups and decisions.</PlaceholderText>]
        </li>
        <li>
          [
          <PlaceholderText>
            If there is work moved to done — it should have next to it the release date to production
          </PlaceholderText>
          ]
        </li>
      </ul>
      <h6>Work Currently Underway</h6>
      <ul>
        <li>
          [<PlaceholderText>List of tickets in QA and Code Review and In Progress</PlaceholderText>]
        </li>
        <li>
          [
          <PlaceholderText>
            Other list of things that are current underway which may include working sessions, architecture, design, or
            followups
          </PlaceholderText>
          ]
        </li>
      </ul>
      <h6>Work Still To Do</h6>
      <ul>
        <li>
          [<PlaceholderText>List of tickets that are TODO for the sprint</PlaceholderText>]
        </li>
        <li>
          [
          <PlaceholderText>
            OTHER todos that you know about from meetings, checkins, and needs which may include notes, meetings to
            schedule, etc.
          </PlaceholderText>
          ]
        </li>
        <li>
          [
          <PlaceholderText>
            This may also include TODOs for the client TODO like UAT. All things in TODO should have due dates or
            anticipated due dates next to them
          </PlaceholderText>
          ]
        </li>
      </ul>
      <h6>Other Outstanding/Upcoming Work</h6>
      <ul>
        <li>
          [
          <PlaceholderText>
            Short description of high level features that are remaining in this phase (this should NOT be tickets)
          </PlaceholderText>
          ]
        </li>
        <li>
          [<PlaceholderText>This may be a list of potential work we could do next as well </PlaceholderText>]
        </li>
        <li>
          [<PlaceholderText>Things listed here should have due dates where possible. </PlaceholderText>]
        </li>
      </ul>
      <EmailHorizontalRule />
      {stats && (
        <React.Fragment>
          <h3>Budget Snapshot</h3>

          <div id='budgetSnapshotSection'>
            <p>
              <ul>
                <li>
                  % Budget Used: <b>{hasBudget ? <Percentage value={budgetUsedPercent} /> : 'n/a'}</b>
                </li>
                <li>
                  % Budget Remaining:{' '}
                  <b>
                    {hasBudget ? (
                      <React.Fragment>
                        <Percentage value={budgetRemainingPercent} /> remains
                      </React.Fragment>
                    ) : (
                      'n/a'
                    )}
                  </b>
                </li>
              </ul>
            </p>

            <p>
              <ul>
                <li>
                  % Project Complete based on current backlog of features:{' '}
                  <b>
                    {(hasJira && (
                      <React.Fragment>
                        <Percentage value={projectCompletePercent} /> complete
                      </React.Fragment>
                    )) ||
                      'n/a'}
                  </b>
                </li>
                <li>
                  % Project Remaining based on current backlog of features:{' '}
                  <b>
                    {(hasJira && (
                      <React.Fragment>
                        <Percentage value={projectRemainingPercent} /> remains
                      </React.Fragment>
                    )) ||
                      'n/a'}
                  </b>
                </li>
              </ul>
            </p>

            <p>
              <ul>
                <li>
                  % Difference Between Budget Used /Project Complete:{' '}
                  <b>
                    {hasBudget && hasJira ? (
                      <React.Fragment>
                        <Percentage value={budgetUsedPercent - projectCompletePercent} /> difference
                      </React.Fragment>
                    ) : (
                      'n/a'
                    )}
                  </b>
                </li>
              </ul>
            </p>
          </div>

          <p>
            <b>
              [{' '}
              <PlaceholderText>
                insert text here about the difference between the two - whether we are on track or not and any
                suggestions about what we should do to get the numbers closer together. This is also the place to
                suggest getting additional budget, reducing the scope of the features, or pulling feature altogether.
              </PlaceholderText>{' '}
              ]
            </b>
          </p>

          <p>
            <b>
              <u>If No Feature Changes Are Possible, the current additional budget we need is $X,XXX, (XXX hours).</u>
            </b>
          </p>

          <EmailHorizontalRule />
          <h3>Budget Details</h3>

          <div id='budgetDetailsSection'>
            <p>
              <ul>
                <li>
                  Total Project Budget Approved To Date:{' '}
                  <b>
                    {hasBudget ? (
                      <React.Fragment>
                        <Currency
                          value={project.currentBudget * project.hourlyRate}
                          minimumFractionDigits={2}
                          maximumFractionDigits={2}
                        />{' '}
                        approved
                      </React.Fragment>
                    ) : (
                      'n/a'
                    )}
                  </b>
                </li>
                <li>
                  Total Project Hours Approved To Date:{' '}
                  <b>
                    {hasBudget ? (
                      <NumberStat value={project.currentBudget} suffix={' hours approved'} maximumFractionDigits={4} />
                    ) : (
                      'n/a'
                    )}
                  </b>
                </li>
              </ul>
            </p>

            <p>
              <ul>
                <li>
                  Current Budget Used:{' '}
                  <b>
                    <Currency value={stats.budget_used.dollars} minimumFractionDigits={2} maximumFractionDigits={2} />{' '}
                    budget used
                  </b>
                </li>
                <li>
                  Current Hours Used:{' '}
                  <b>
                    <NumberStat value={stats.budget_used.hours} maximumFractionDigits={4} suffix={' hours used'} />
                  </b>
                </li>
              </ul>
            </p>

            {!!hoursPaidByCaxy && (
              <p>
                <ul>
                  <li>
                    Current Hours Paid By Caxy:{' '}
                    <b>
                      <NumberStat value={hoursPaidByCaxy} suffix={' hours'} maximumFractionDigits={4} />
                    </b>
                  </li>
                  <li>
                    Current Dollars Paid By Caxy:{' '}
                    <b>
                      <Currency value={dollarsPaidByCaxy} minimumFractionDigits={2} maximumFractionDigits={2} />
                    </b>
                  </li>
                </ul>
              </p>
            )}

            <h5>Billable Budget Used From</h5>

            {prevStats && <BillableBudgetUsed start={prevStart} end={prevEnd} stats={prevStats} />}
            {mtdStats && <BillableBudgetUsed start={start} end={end} stats={mtdStats} />}

            <p>
              <ul>
                <li>
                  <i>
                    Expected Invoice of current period: <Placeholder noSpaces>notes</Placeholder>
                  </i>
                </li>
                <li>
                  <i>
                    Expected invoice for next period: <Placeholder noSpaces>notes</Placeholder>
                  </i>
                </li>
              </ul>
            </p>

            <p>
              <ul>
                <li>
                  <i>
                    Other Notes about invoices: <Placeholder noSpaces>notes</Placeholder>
                  </i>
                </li>
              </ul>
            </p>

            {(reportFilters.hoursByPerson || reportFilters.hoursByTask) && (
              <React.Fragment>
                <h5>Hours Breakdown</h5>
                {prevStats && (
                  <HoursBreakdown start={prevStart} end={prevEnd} stats={prevStats} filters={reportFilters} />
                )}
                {mtdStats && <HoursBreakdown start={start} end={end} stats={mtdStats} filters={reportFilters} />}
              </React.Fragment>
            )}
          </div>

          <EmailHorizontalRule />
        </React.Fragment>
      )}
      Thanks,
      <br />[<PlaceholderText>Your Name</PlaceholderText>]
    </div>
  );
}

EmailContent.propTypes = {
  filters: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }),
  project: projectShape,
  overviewStats: PropTypes.shape({
    loading: PropTypes.bool,
    error: PropTypes.string,
    data: projectStatsShape,
  }),
  filteredStats: PropTypes.shape({
    loading: PropTypes.bool,
    error: PropTypes.string,
    data: projectStatsShape,
  }),
  reportFilters: PropTypes.shape({
    hoursByPerson: PropTypes.bool,
    hoursByTask: PropTypes.bool,
  }),
  previousPayPeriodStats: PropTypes.shape({
    loading: PropTypes.bool,
    error: PropTypes.string,
    data: projectStatsShape,
  }),
};

export default EmailContent;

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components/macro';
import TimeAgo from 'react-timeago';
import { invoiceShape } from 'utils/shapes/project';
import Ul from 'components/Ul';
import Li from 'components/Li';
import Currency from 'components/Currency/Currency';
import { FormattedDate } from 'react-intl';

const Invoice = styled.div`
  font-size: 0.9em;
  padding: 1em 0;
`;

const InvoiceDate = styled.div``;

const InvoiceMeta = styled.div``;

const InvoiceAmount = styled.span`
  font-weight: bold;
`;

const PaymentStatus = styled.div`
  color: ${(props) => props.theme.colorGrayDark};
`;

const DueDate = styled.span`
  color: ${(props) => props.theme.colorOrange};
`;

const Status = styled.span`
  color: ${(props) => {
    if (props.paid) {
      return props.theme.primaryFontColor;
    }
    if (props.late) {
      return props.theme.colorRed;
    }
    return props.theme.subTitleColor;
  }};
  font-weight: bold;
`;

const Memo = styled.div`
  font-size: 0.8em;
  color: ${(props) => props.theme.colorGrayWarm};
`;

const Label = styled.span`
  font-size: 0.8em;
  font-weight: normal;
  color: ${(props) => props.theme.colorGrayWarm};
  width: 7rem;
  display: inline-block;
`;

function InvoiceList({ invoices }) {
  if (!invoices) {
    return null;
  }

  const currentTime = moment();

  return (
    <Ul>
      {invoices.map((invoice) => {
        const isPaid = invoice.Balance === 0;
        const isLate = moment(invoice.DueDate).isBefore(currentTime, 'day');
        const partiallyPaid = !isPaid && invoice.Balance < invoice.TotalAmt;

        return (
          <Li key={invoice['@id']}>
            <Invoice>
              <div>
                <InvoiceDate>
                  <FormattedDate value={invoice.TxnDate} year='numeric' month='short' day='2-digit' />
                </InvoiceDate>
                <InvoiceMeta>
                  <InvoiceAmount>
                    <Label>Invoice Amt</Label> <Currency value={invoice.TotalAmt} />
                  </InvoiceAmount>
                  <PaymentStatus>
                    <Label>Status</Label>{' '}
                    {isPaid ? <Status paid>Paid</Status> : <Status late={isLate}>{isLate ? 'Late' : 'Sent'}</Status>}
                  </PaymentStatus>
                  {partiallyPaid && (
                    <PaymentStatus>
                      <Label>Balance</Label> <Currency value={invoice.Balance} />
                    </PaymentStatus>
                  )}
                </InvoiceMeta>
              </div>
              {invoice.CustomerMemo && <Memo>{invoice.CustomerMemo.value}</Memo>}
              {!isPaid && (
                <DueDate>
                  Due <FormattedDate value={invoice.DueDate} year='numeric' month='short' day='2-digit' />
                </DueDate>
              )}
            </Invoice>
          </Li>
        );
      })}
    </Ul>
  );
}

InvoiceList.propTypes = {
  invoices: PropTypes.arrayOf(invoiceShape),
};

export default InvoiceList;

import React from 'react';
import PropTypes from 'prop-types';

function EmailHorizontalRule() {
  return (
    <p>
      <b>--------------------------------------------</b>
    </p>
  );
}

EmailHorizontalRule.propTypes = {};

export default EmailHorizontalRule;

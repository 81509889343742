import fetch from 'utils/fetch';

export async function getForecasts() {
  const res = await fetch(`/forecasting`);
  return res.json();
}

export async function upsertForecast({ id, data }) {
  const res = await fetch(`/forecasting/${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  return res.json();
}

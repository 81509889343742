import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { invoiceShape, projectShape } from 'utils/shapes/project';
import LoadingIndicator from 'components/LoadingIndicator';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import InvoiceList from './InvoiceList';

const Wrapper = styled.div``;

class ProjectInvoices extends Component {
  static propTypes = {
    project: projectShape,
    loading: PropTypes.bool,
    error: PropTypes.string,
    data: PropTypes.arrayOf(invoiceShape),
    load: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
  };

  componentDidMount() {
    // Load project notes.
    if (!this.props.data && !this.props.loading) {
      this.props.load();
    }
  }

  componentWillUnmount() {
    this.props.reset();
  }

  render() {
    const { loading, error, data } = this.props;

    if (loading) {
      return <LoadingIndicator />;
    }

    if (error) {
      return <ErrorMessage error={error} />;
    }

    return (
      <Wrapper>
        <InvoiceList invoices={data} />
      </Wrapper>
    );
  }
}

export default ProjectInvoices;

import React, { Component } from 'react';
import { renderToString } from 'react-dom/server';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import moment from 'moment';
import ContentEditable from 'react-contenteditable';
import { Button, Callout, Cell, Colors, Grid, GridContainer, Sizes, Switch } from 'react-foundation';
import ErrorMessage from 'components/ErrorMessage';
import { Link } from 'react-router-dom';
import H1 from 'components/H1';
import MainContainer from 'routes/ProjectPage/MainContainer';
import { projectShape } from 'utils/shapes/project';
import { projectStatsShape } from 'utils/shapes/stats';
import ProjectSelector from 'containers/ProjectSelector';
import {
  BILLING_CYCLE_MONTHLY,
  BILLING_CYCLE_SEMIMONTHLY,
  DATE_PRESET_MONTH,
  DATE_PRESET_SEMIMONTH,
  HOURS_BY_TASK_SORT_ALPHA,
  HOURS_BY_TASK_SORT_HOURS,
} from 'utils/constants';
import { getPreviousSemimonthRange, getSemimonthRange } from 'utils/dateUtils';
import { FormattedDate, IntlProvider } from 'react-intl';
import Toolbar from 'routes/ProjectPage/Toolbar';
import EmailContent from 'routes/Reports/routes/BudgetSummary/components/EmailContent';
import { replaceNodesInDocument, verifyElementsExistInDocument } from 'utils/domUtils';
import debounce from 'lodash/debounce';
import { v4 as uuidv4 } from 'uuid';
import TimeAgo from 'react-timeago';

const LOCAL_HISTORY_DEBOUNCE_MS = 5000;

const EditableWrapper = styled.div`
  padding: 20px;
  background-color: #eeeeee;
  border: 1px solid #d9d9d9;
  border-top: none;
`;

const EditableWrapperInner = styled.div`
  background-color: #ffffff;
  box-shadow: 0 0 0 0.75pt #d1d1d1, 0 0 3pt 0.75pt #ccc;

  .email-content {
    padding: 20px;
  }
`;

const ReportOptionsWrapper = styled.div`
  padding: 2rem;
`;

function getDateFiltersForBillingCycle(billingCycle, filters) {
  let end = moment(filters.endDate);
  const now = moment();

  if (end.isAfter(now)) {
    end = now;
  }

  const newFilters = { ...filters };
  let datePreset;

  // Calculate new date filter values.
  if (billingCycle === BILLING_CYCLE_SEMIMONTHLY) {
    const { startDate, endDate } = getSemimonthRange(end);

    newFilters.startDate = startDate;
    newFilters.endDate = endDate;
    datePreset = DATE_PRESET_MONTH;
  } else if (billingCycle === BILLING_CYCLE_MONTHLY) {
    const start = end.clone();
    start.startOf('month');
    end.endOf('month');

    newFilters.startDate = start.toISOString();
    newFilters.endDate = end.toISOString();
    datePreset = DATE_PRESET_SEMIMONTH;
  }

  return { newFilters, datePreset };
}

class BudgetSummary extends Component {
  static propTypes = {
    error: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    filters: PropTypes.shape({
      startDate: PropTypes.string,
      endDate: PropTypes.string,
    }),
    project: projectShape,
    overviewStats: PropTypes.shape({
      loading: PropTypes.bool,
      error: PropTypes.string,
      data: projectStatsShape,
    }),
    filteredStats: PropTypes.shape({
      loading: PropTypes.bool,
      error: PropTypes.string,
      data: projectStatsShape,
    }),
    previousPayPeriodStats: PropTypes.shape({
      loading: PropTypes.bool,
      error: PropTypes.string,
      data: projectStatsShape,
    }),
    retrieve: PropTypes.func.isRequired,
    getOverviewStats: PropTypes.func.isRequired,
    getFilteredStats: PropTypes.func.isRequired,
    getPreviousPayPeriodStats: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    resetFilteredStats: PropTypes.func.isRequired,
    reportFilters: PropTypes.shape({
      hoursByPerson: PropTypes.bool,
      hoursByTask: PropTypes.bool,
      hoursByTaskSort: PropTypes.oneOf([HOURS_BY_TASK_SORT_HOURS, HOURS_BY_TASK_SORT_ALPHA]),
    }),
    setHoursByTask: PropTypes.func.isRequired,
    setHoursByTaskSort: PropTypes.func.isRequired,
    setHoursByPerson: PropTypes.func.isRequired,
    setBillingCycle: PropTypes.func.isRequired,
    setDateFilters: PropTypes.func.isRequired,
    setDatePreset: PropTypes.func.isRequired,
  };

  state = {
    content: null,
    history: null,
    showHistory: false,
  };

  contentEditable = React.createRef();

  static renderEmailContentsToString(props) {
    const { project, overviewStats, filteredStats, filters, previousPayPeriodStats, reportFilters } = props;

    return renderToString(
      project && (
        <IntlProvider locale={'en-us'}>
          <EmailContent
            project={project}
            overviewStats={overviewStats}
            filteredStats={filteredStats}
            filters={filters}
            previousPayPeriodStats={previousPayPeriodStats}
            previousPayPeriodFilters={BudgetSummary.getPreviousPayPeriodFilters({ filters, reportFilters })}
            reportFilters={reportFilters}
          />
        </IntlProvider>
      )
    );
  }

  static buildQueryFilters(props) {
    const { startDate, endDate } = props.filters;
    const start = moment(startDate).format('YYYY-MM-DD');
    const end = moment(endDate).format('YYYY-MM-DD');
    const projectId = decodeURIComponent(props.match.params.projectId);

    return { projectId, start, end };
  }

  static getPreviousPayPeriodFilters({ filters, reportFilters }) {
    const { start, end } = BudgetSummary.getPreviousPayPeriod(filters.endDate, reportFilters.billingCycle);

    return { start: moment(start).format('YYYY-MM-DD'), end: moment(end).format('YYYY-MM-DD') };
  }

  static getPreviousPayPeriod(referenceDate, billingCycle) {
    let end = moment(referenceDate);
    const now = moment();

    if (end.isAfter(now)) {
      end = now;
    }

    // Calculate new date filter values.
    if (billingCycle === BILLING_CYCLE_SEMIMONTHLY) {
      const { startDate, endDate } = getPreviousSemimonthRange(end);

      return { start: startDate, end: endDate };
    }

    // Default to monthly.
    end.startOf('month').subtract(1, 'month');
    const start = end.clone();
    end.endOf('month');

    return { start: start.toISOString(), end: end.toISOString() };
  }

  static filterBilledTasks(tasks) {
    return tasks.filter((breakdown) => breakdown.task_name !== 'Internally Billed Client Work');
  }

  componentDidMount() {
    const { project, match } = this.props;
    if (!project && match.params.projectId) {
      this.loadData();
    }
  }

  componentWillUnmount() {
    this.props.reset();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.projectId !== this.props.match.params.projectId) {
      // Clear any custom content.
      this.resetContent();
      // Load data for project.
      this.loadData();
    } else if (
      prevProps.filters.startDate !== this.props.filters.startDate ||
      prevProps.filters.endDate !== this.props.filters.endDate
    ) {
      this.loadFilteredData();
    }

    if (prevProps.project?.billingCycle !== this.props.project?.billingCycle && this.props.project?.billingCycle) {
      this.onBillingCycleChange({ target: { value: this.props.project.billingCycle } });
    }
  }

  onHoursByPersonChange = (e) => {
    this.props.setHoursByPerson(e.target.checked);
  };

  onHoursByTaskChange = (e) => {
    this.props.setHoursByTask(e.target.checked);
  };

  onHoursByTaskSortChange = (e) => {
    this.props.setHoursByTaskSort(e.target.value);
  };

  onShowHistoryChange = (e) => {
    this.setState({ showHistory: e.target.checked });
  };

  onBillingCycleChange = (e) => {
    const { reportFilters, filters } = this.props;
    const value = e.target.value;

    if (value !== reportFilters.billingCycle) {
      this.props.setBillingCycle(value);
      let end = moment(filters.endDate);
      const now = moment();

      if (end.isAfter(now)) {
        end = now;
      }

      const newFilters = { ...filters };
      let datePreset;

      // Calculate new date filter values.
      if (value === BILLING_CYCLE_SEMIMONTHLY) {
        const { startDate, endDate } = getSemimonthRange(end);

        newFilters.startDate = startDate;
        newFilters.endDate = endDate;
        datePreset = DATE_PRESET_MONTH;
      } else if (value === BILLING_CYCLE_MONTHLY) {
        const start = end.clone();
        start.startOf('month');
        end.endOf('month');

        newFilters.startDate = start.toISOString();
        newFilters.endDate = end.toISOString();
        datePreset = DATE_PRESET_SEMIMONTH;
      }

      this.props.setDateFilters(newFilters.startDate, newFilters.endDate);
      if (datePreset) {
        this.props.setDatePreset(datePreset);
      }
    }
  };

  onContentChange = (e) => {
    const generatedContent = BudgetSummary.renderEmailContentsToString(this.props);
    const content = e.target.value;
    if (content !== generatedContent) {
      this.debouncedLocalHistoryAdd(content);
      this.setState({ content });
    }
  };

  resetContent = (e) => {
    this.setState({ content: null });
    this.addLocalHistory(null);
  };

  resetBudgetContent = (e) => {
    if (this.state.content) {
      const generatedContent = BudgetSummary.renderEmailContentsToString(this.props);

      const contentDoc = new DOMParser().parseFromString(this.state.content, 'text/html');

      const elementsToClone = ['budgetSnapshotSection', 'budgetDetailsSection'];

      if (verifyElementsExistInDocument(contentDoc, elementsToClone)) {
        const generatedDoc = new DOMParser().parseFromString(generatedContent, 'text/html');
        replaceNodesInDocument(contentDoc, generatedDoc, elementsToClone);
        this.setState({ content: contentDoc.body.innerHTML });
      } else {
      }
    } else {
      this.setState({ content: null });
    }
  };

  loadData(props) {
    if (!props) {
      props = this.props;
    }

    props.reset();

    this.setState({ history: this.loadLocalHistory() });

    const filters = BudgetSummary.buildQueryFilters(props);

    props.retrieve(filters.projectId);
    props.getOverviewStats(filters.projectId);
    this.loadFilteredData(props);
  }

  loadLocalHistory() {
    const historyKey = this.getLocalHistoryKey();
    const historyData = localStorage.getItem(historyKey);

    return historyData ? JSON.parse(historyData) : [];
  }

  addLocalHistory(content) {
    const historyKey = this.getLocalHistoryKey();
    const historyData = this.loadLocalHistory();
    historyData.unshift({
      id: uuidv4(),
      timestamp: new Date().toISOString(),
      content,
    });
    this.setState({ history: historyData });
    localStorage.setItem(historyKey, JSON.stringify(historyData));
  }

  debouncedLocalHistoryAdd = debounce(this.addLocalHistory, LOCAL_HISTORY_DEBOUNCE_MS);

  getLocalHistoryKey() {
    const projectId = decodeURIComponent(this.props.match.params.projectId);
    return `pc_budget_summary_history__${projectId}`;
  }

  restoreLocalHistory(id) {
    const { history } = this.state;
    const item = history.find((x) => x.id === id);
    if (item) {
      this.setState({ content: item.content });
      this.addLocalHistory(item.content);
    }
  }

  loadFilteredData(props) {
    if (!props) {
      props = this.props;
    }

    props.resetFilteredStats();

    const { projectId, start, end } = BudgetSummary.buildQueryFilters(props);

    props.getFilteredStats(projectId, start, end);

    const { start: prevStart, end: prevEnd } = BudgetSummary.getPreviousPayPeriodFilters(props);

    props.getPreviousPayPeriodStats(projectId, prevStart, prevEnd);
  }

  render() {
    const { project, reportFilters } = this.props;

    return (
      <div>
        <div>
          <GridContainer>
            <ProjectSelector baseLinkUri={'/reports/budget-summary/'} label={'Select a Project'} />
            {this.props.loading && (
              <Callout color={Colors.SECONDARY}>
                <p>Loading...</p>
              </Callout>
            )}
            {this.props.error && (
              <ErrorMessage
                title='Error loading Budget Summary for project'
                error={this.props.error}
                afterContent={
                  <Link to='/' className='btn btn-default'>
                    Back to list
                  </Link>
                }
              />
            )}
          </GridContainer>
          {project && (
            <React.Fragment>
              <GridContainer>
                <H1>{project['name']} Budget Summary</H1>
              </GridContainer>
              <Toolbar>
                <GridContainer>Copy and paste the generated budget summary below into your mail client.</GridContainer>
              </Toolbar>
              <MainContainer>
                <GridContainer>
                  <Grid>
                    <Cell small={9}>
                      <EditableWrapper>
                        <EditableWrapperInner>
                          <ContentEditable
                            innerRef={this.contentEditable}
                            html={this.state.content || BudgetSummary.renderEmailContentsToString(this.props)}
                            onChange={this.onContentChange}
                          />
                        </EditableWrapperInner>
                      </EditableWrapper>
                    </Cell>
                    <Cell small={3}>
                      <ReportOptionsWrapper>
                        <h4>Customize Report</h4>
                        <label htmlFor='billing_cycle'>
                          Billing Cycle
                          <select
                            id='billing_cycle'
                            value={reportFilters.billingCycle}
                            onChange={this.onBillingCycleChange}
                          >
                            <option value={BILLING_CYCLE_MONTHLY}>Monthly</option>
                            <option value={BILLING_CYCLE_SEMIMONTHLY}>Semimonthly</option>
                          </select>
                        </label>

                        <label htmlFor='hours_by_task'>
                          Hours Breakdown By Task
                          <Switch
                            id='hours_by_task'
                            input={{
                              defaultChecked: reportFilters.hoursByTask,
                              onChange: this.onHoursByTaskChange,
                            }}
                            active={{ text: 'Yes' }}
                            inactive={{ text: 'No' }}
                          />
                        </label>

                        {reportFilters.hoursByTask && (
                          <label htmlFor='hours_by_task_sort'>
                            Hours Breakdown By Task Sort
                            <select
                              id='hours_by_task_sort'
                              value={reportFilters.hoursByTaskSort}
                              onChange={this.onHoursByTaskSortChange}
                            >
                              <option value={HOURS_BY_TASK_SORT_HOURS}>By Hours Desc.</option>
                              <option value={HOURS_BY_TASK_SORT_ALPHA}>Alphabetically</option>
                            </select>
                          </label>
                        )}

                        <label htmlFor='hours_by_person'>
                          Hours Breakdown By Person
                          <Switch
                            id='hours_by_person'
                            input={{
                              defaultChecked: reportFilters.hoursByPerson,
                              onChange: this.onHoursByPersonChange,
                            }}
                            active={{ text: 'Yes' }}
                            inactive={{ text: 'No' }}
                          />
                        </label>

                        {this.state.history && this.state.history.length > 0 && (
                          <label htmlFor='show_history'>
                            Show History
                            <Switch
                              id='show_history'
                              input={{
                                defaultChecked: this.state.showHistory,
                                onChange: this.onShowHistoryChange,
                              }}
                              active={{ text: 'Yes' }}
                              inactive={{ text: 'No' }}
                            />
                          </label>
                        )}

                        {this.state.content && (
                          <div>
                            <hr />
                            <Button onClick={this.resetBudgetContent}>Regenerate Budget Data</Button>
                            <Button onClick={this.resetContent}>Regenerate Full Report</Button>
                            <p>
                              <small>
                                Note: You will lose any changes to the content when regenerating the report.
                              </small>
                            </p>
                          </div>
                        )}

                        {this.state.history && this.state.history.length > 0 && this.state.showHistory && (
                          <div>
                            <hr />
                            <h4>Local History</h4>
                            <ul>
                              {this.state.history.map((historyItem, i) => {
                                const versionNumber = this.state.history.length - i;
                                return (
                                  <li key={historyItem.id || historyItem.timestamp}>
                                    Version {versionNumber}{' '}
                                    <small>
                                      (<TimeAgo date={moment(historyItem.timestamp)} />)
                                    </small>
                                    <br />
                                    <Button size={Sizes.TINY} onClick={() => this.restoreLocalHistory(historyItem.id)}>
                                      Restore
                                    </Button>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        )}
                      </ReportOptionsWrapper>
                    </Cell>
                  </Grid>
                </GridContainer>
              </MainContainer>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default BudgetSummary;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  DATE_PRESET_CUSTOM,
  DATE_PRESET_MONTH,
  DATE_PRESET_QUARTER,
  DATE_PRESET_SEMIMONTH,
  DATE_PRESET_YEAR,
  DATE_PRESETS,
} from 'utils/constants';
import {
  DATE_PRESET_MESSAGES,
  DATE_PRESET_PREFIX_CURRENT,
  DATE_PRESET_PREFIX_NEXT,
  DATE_PRESET_PREFIX_PREVIOUS,
} from 'utils/messages';
import moment from 'moment/moment';
import ReactSVG from 'react-svg';
import iconCaretLeft from './icon-caret-left.svg';
import iconCaretRight from './icon-caret-right.svg';
import styled from 'styled-components/macro';
import { getNextSemimonthRange, getPreviousSemimonthRange, getSemimonthRange } from 'utils/dateUtils';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 9px;
`;

const Arrow = styled.a`
  margin-left: ${(props) => (props.isRight ? '0.8125rem' : '0')};
  margin-right: ${(props) => (props.isLeft ? '0.8125rem' : '0')};

  .icon-svg > div {
    display: flex;
    align-items: center;
  }
`;

const DateContainer = styled.span`
  &,
  .DateInput_input__small {
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.4px;
  }

  .DateInput__small {
    width: 110px;
    text-align: center;
  }
`;

const DropdownWrapper = styled.div`
  position: relative;

  .dropdown-pane {
    width: 150px;
    margin-top: 10px;
    padding: 0;
  }
`;

const QuickSelect = styled(DateContainer)`
  margin-right: 0.5em;
  color: ${(props) => props.theme.titleColorPrimary};
`;

const DateSelect = styled(DateContainer)`
  color: ${(props) => props.theme.colorGrayDark};
`;

const Ul = styled.ul`
  max-height: 400px;
  overflow-y: auto;
  margin: 0;
  list-style: none;

  ul {
    margin: 0;
    width: 100%;
  }

  li {
    list-style: none;

    a {
      display: block;
      width: 100%;
      padding: 0.5rem 2rem;
      border-bottom: 1px solid ${(props) => props.theme.cardBorderColor};
      color: ${(props) => props.theme.baseFontColor};

      &:last-of-type {
        border-bottom: none;
      }

      &:hover {
        color: #ffffff;
        background-color: ${(props) => props.theme.primaryFontColor};
      }
    }
  }
`;

/**
 * @return {String|null}
 */
function DateDisplay({ datePreset, startDate, endDate }) {
  const start = moment(startDate);
  const end = moment(endDate);

  switch (datePreset) {
    case DATE_PRESET_SEMIMONTH:
    case DATE_PRESET_MONTH:
      return `${start.format('DD')} - ${end.format('DD MMM YYYY')}`;

    case DATE_PRESET_QUARTER:
      return `${start.format('DD MMM')} - ${end.format('DD MMM YYYY')}`;

    case DATE_PRESET_YEAR:
      return start.format('YYYY');

    case DATE_PRESET_CUSTOM:
      return `${start.format('DD MMM YYYY')} - ${end.format('DD MMM YYYY')}`;

    default:
      return null;
  }
}

/**
 * @param {String} datePreset
 * @param {Moment} start
 * @param {Moment} end
 * @returns {String}
 */
function getDatePresetLabelPrefix(datePreset, start, end) {
  // Prefix only applies for semimonth, month, quarter, and year.
  if (![DATE_PRESET_SEMIMONTH, DATE_PRESET_MONTH, DATE_PRESET_QUARTER, DATE_PRESET_YEAR].includes(datePreset)) {
    return '';
  }

  const current = moment();

  // Check if current date is within the filter.
  if (current.isBetween(start, end, 'day', '[]')) {
    return DATE_PRESET_PREFIX_CURRENT;
  }

  const pluralPreset = `${datePreset}s`;

  if (current.isBefore(start)) {
    // See if current day is within previous window, in which case it's the "Next" window.
    if (current.isAfter(start.clone().subtract(1, pluralPreset), 'day')) {
      return DATE_PRESET_PREFIX_NEXT;
    }
  } else if (current.isBefore(end.clone().add(1, pluralPreset), 'day')) {
    // Current is after start and end date, but window is "Last".
    return DATE_PRESET_PREFIX_PREVIOUS;
  }

  return '';
}

function getNewDatesForPreset(datePreset, startDate, endDate) {
  // New dates only apply for semimonth, month, quarter, and year.
  if (![DATE_PRESET_SEMIMONTH, DATE_PRESET_MONTH, DATE_PRESET_QUARTER, DATE_PRESET_YEAR].includes(datePreset)) {
    return datePreset === DATE_PRESET_CUSTOM ? { startDate, endDate } : null;
  }

  const current = moment();

  if (DATE_PRESET_SEMIMONTH === datePreset) {
    return getSemimonthRange(current);
  }

  return {
    startDate: current.startOf(datePreset).toISOString(),
    endDate: current.endOf(datePreset).toISOString(),
  };
}

function getDatePresetLabel(datePreset, start, end) {
  return DATE_PRESET_MESSAGES[datePreset];
}

function DatePresetDisplay({ datePreset, startDate, endDate }) {
  const start = moment(startDate);
  const end = moment(endDate);

  const label = getDatePresetLabel(datePreset, start, end);
  const prefix = getDatePresetLabelPrefix(datePreset, start, end);

  return (
    <span>
      {prefix}
      {label}
    </span>
  );
}

class DateRangeFilter extends Component {
  static propTypes = {
    filters: PropTypes.shape({
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      datePreset: PropTypes.oneOf(DATE_PRESETS),
    }),
    setDateFilters: PropTypes.func.isRequired,
    setDatePreset: PropTypes.func.isRequired,
  };

  state = {
    isOpen: false,
  };

  constructor(props) {
    super(props);

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.toggle = this.toggle.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onPresetChange = this.onPresetChange.bind(this);
    this.previous = this.previous.bind(this);
    this.next = this.next.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  /**
   * Set the wrapper ref
   */
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  /**
   * Close if clicked on outside of element
   */
  handleClickOutside(event) {
    if (this.wrapperRef && this.state.isOpen && !this.wrapperRef.contains(event.target)) {
      this.close();
    }
  }

  open() {
    this.setState({ isOpen: true });
  }

  close() {
    this.setState({ isOpen: false });
  }

  toggle() {
    if (this.state.isOpen) {
      this.close();
    } else {
      this.open();
    }
  }

  shiftDateRange(next) {
    const { datePreset, startDate, endDate } = this.props.filters;
    const start = moment(startDate);
    const end = moment(endDate);

    if (DATE_PRESET_SEMIMONTH === datePreset) {
      const newDates = next ? getNextSemimonthRange(end) : getPreviousSemimonthRange(end);
      this.onChange(newDates);

      return;
    }

    const pluralPreset = `${datePreset}s`;

    if (next) {
      start.add(1, pluralPreset).startOf(datePreset);
      end.add(1, pluralPreset).endOf(datePreset);
    } else {
      start.subtract(1, pluralPreset).startOf(datePreset);
      end.subtract(1, pluralPreset).endOf(datePreset);
    }

    this.onChange({ startDate: start.toISOString(), endDate: end.toISOString() });
  }

  previous() {
    this.shiftDateRange(false);
  }

  next() {
    this.shiftDateRange(true);
  }

  onChange({ startDate, endDate }) {
    this.props.setDateFilters(startDate, endDate);
  }

  onPresetChange(preset) {
    const { startDate, endDate } = this.props.filters;
    const newDates = getNewDatesForPreset(preset, startDate, endDate);

    this.props.setDatePreset(preset);
    this.props.setDateFilters(newDates.startDate, newDates.endDate);

    this.close();
  }

  render() {
    const { filters, className = '', ...props } = this.props;

    return (
      <div ref={this.setWrapperRef} className={className} {...props}>
        <Wrapper>
          <Arrow isLeft onClick={this.previous}>
            <ReactSVG wrapperClassName='icon-svg icon-caret-left' path={iconCaretLeft} />
          </Arrow>
          <DropdownWrapper>
            <a onClick={this.toggle}>
              <QuickSelect>
                <DatePresetDisplay {...filters} />
              </QuickSelect>
            </a>
            {this.state.isOpen && (
              <div className={`dropdown-pane ${this.state.isOpen ? 'is-open' : ''}`.trim()}>
                <Ul>
                  {DATE_PRESETS.map((preset) => (
                    <li key={preset}>
                      <a onClick={this.onPresetChange.bind(this, preset)}>{DATE_PRESET_MESSAGES[preset]}</a>
                    </li>
                  ))}
                </Ul>
              </div>
            )}
          </DropdownWrapper>
          <DateSelect>
            <DateDisplay {...filters} />
          </DateSelect>
          <Arrow isRight onClick={this.next}>
            <ReactSVG wrapperClassName='icon-svg icon-caret-right' path={iconCaretRight} />
          </Arrow>
        </Wrapper>
      </div>
    );
  }
}

export default DateRangeFilter;

import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Callout, Cell, Colors, Grid, GridContainer } from 'react-foundation';
import DropdownButton from 'components/DropdownButton';
import { FORECASTING_FILTER_MESSAGES } from 'utils/messages';
import FontAwesome from 'react-fontawesome';
import {
  FORECASTING_FILTER_A,
  FORECASTING_FILTER_ACTUAL,
  FORECASTING_FILTER_B,
  FORECASTING_FILTERS,
} from 'utils/constants';
import LoadingIndicator from 'components/LoadingIndicator';
import styled from 'styled-components/macro';
import H1 from 'components/H1';
import { darken } from 'polished';
import ForecastingGrid from './ForecastingGrid';
import { useProjectList } from 'hooks/api/projectHooks';
import { useForecastList } from 'hooks/api/forecastingHooks';

const ProjectListContainer = styled(GridContainer)`
  margin-top: 2rem;
`;
const Ul = styled.ul`
  max-height: 400px;
  overflow-y: auto;
  margin: 0;
  list-style: none;

  ul {
    margin: 0;
    width: 100%;
  }

  li {
    list-style: none;

    a {
      display: block;
      width: 100%;
      padding: 0.5rem 2rem;
      border-bottom: 1px solid ${(props) => props.theme.cardBorderColor};
      color: ${(props) => props.theme.baseFontColor};

      &:last-of-type {
        border-bottom: none;
      }

      &:hover {
        color: #ffffff;
        background-color: ${(props) => props.theme.primaryFontColor};
      }
    }
  }
`;
const H1Dropdown = styled(H1)`
  display: flex;
  align-items: center;

  &:hover {
    color: ${(props) => darken(0.2, props.theme.titleColorPrimary)};
  }

  .fa {
    font-size: 0.6em;
    margin-left: 0.8rem;
  }
`;

Forecasting.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.string,
};

function Forecasting({ loading, error }) {
  const [{ data: allProjects, loading: projectsLoading, error: projectsError }] = useProjectList();
  const [{ data: forecasts }] = useForecastList();
  const [filter, setFilter] = useState(FORECASTING_FILTER_ACTUAL);
  const activeData = useMemo(() => {
    return forecasts ? forecasts.find((x) => x._id === filter) || { _id: filter } : null;
  }, [filter, forecasts]);

  return (
    <ProjectListContainer fluid>
      <GridContainer>
        <Grid gutters={'none'} className={'align-middle align-justify'}>
          <Cell small={4}>
            <DropdownButton
              label={
                <H1Dropdown>
                  {FORECASTING_FILTER_MESSAGES[filter]}
                  <FontAwesome name='chevron-down' />
                </H1Dropdown>
              }
              buttonComponent={'a'}
            >
              <Ul>
                {FORECASTING_FILTERS.map((filter) => (
                  <li key={filter}>
                    <a onClick={() => setFilter(filter)}>{FORECASTING_FILTER_MESSAGES[filter]}</a>
                  </li>
                ))}
              </Ul>
            </DropdownButton>
          </Cell>
          {/*<Cell small={4}>*/}
          {/*  /!*<DashboardDateRangeFilter className={'float-right'} />*!/*/}
          {/*</Cell>*/}
        </Grid>
      </GridContainer>
      {loading && (
        <Callout color={Colors.SECONDARY}>
          <p>Loading...</p>
        </Callout>
      )}
      {(error || projectsError) && (
        <Callout color={Colors.ALERT}>
          <h5>An error occurred while loading the page</h5>
          <p>{error || projectsError}</p>
        </Callout>
      )}
      {!allProjects || projectsLoading || !activeData ? (
        <LoadingIndicator />
      ) : (
        <ForecastingGrid id={filter} projects={allProjects} data={activeData} />
      )}
    </ProjectListContainer>
  );
}

export default Forecasting;

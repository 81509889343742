import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-foundation';
import FontAwesome from 'react-fontawesome';
import useFetch from 'hooks/useFetch';
import fetch from 'utils/fetch';
import useAction from 'hooks/useAction';
import { getSyncStatus, triggerResync } from 'api/syncApi';
import size from 'lodash/size';
import maxBy from 'lodash/maxBy';
import get from 'lodash/get';
import moment from 'moment';
import useInterval from 'hooks/useInterval';
import { ButtonSizes } from 'react-foundation/lib/enums';

const STATE_SENT = 'sent';
const STATE_RECEIVED = 'received';

const STATE_LABELS = {
  [STATE_SENT]: 'Queued & waiting for other jobs to finish',
  [STATE_RECEIVED]: 'Sync in progress',
};

const STATE_TIME_LABELS = {
  [STATE_SENT]: 'Queued',
  [STATE_RECEIVED]: 'Started',
};

const JOB_SYNC_INTERVAL_MILLIS = 10000;

function getJobKey(source, projectId) {
  return `${source}_sync_${projectId || 'ALL'}`;
}

const propTypes = {
  source: PropTypes.oneOf(['harvest', 'jira']).isRequired,
  projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node,
  className: PropTypes.string,
};

function SyncActionButton({ source, projectId = null, className, children, ...props }) {
  const jobKey = getJobKey(source, projectId);

  const [triggeredSync, setTriggeredSync] = useState(false);
  const [resyncingState, requestResync] = useAction(triggerResync);
  const [syncStatusState, requestSyncStatus] = useAction(getSyncStatus);

  useEffect(() => {
    // Load the sync status on render.
    requestSyncStatus(jobKey);
  }, []);

  useEffect(() => {
    if (triggeredSync && size(get(syncStatusState, 'data.items', [])) === 0) {
      setTriggeredSync(false);
    }
  }, [syncStatusState.data]);

  useInterval(() => {
    requestSyncStatus(jobKey);
  }, JOB_SYNC_INTERVAL_MILLIS);

  const handleResync = useCallback(() => {
    setTriggeredSync(true);
    requestResync({ source, projectIds: [projectId] });
  }, [requestResync, source, projectId]);

  const activeSyncs = get(syncStatusState, 'data.items', []);

  const isDisabled = size(activeSyncs) > 0 || resyncingState.loading || triggeredSync;

  return (
    <Button onClick={handleResync} disabled={isDisabled} isDisabled={isDisabled} className={className} {...props}>
      {isDisabled && (
        <span>
          <FontAwesome name={'spinner'} spin />{' '}
        </span>
      )}
      {children}
    </Button>
  );
}

SyncActionButton.propTypes = propTypes;

export default SyncActionButton;

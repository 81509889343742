import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Sizes } from 'react-foundation';
import { Field, reduxForm } from 'redux-form/immutable';
import { required } from 'utils/validators';

class CommentForm extends Component {
  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
  }

  submit(values) {
    return this.props.handleSubmit(values).then((_) => {
      this.props.reset();
    });
  }

  render() {
    const { handleSubmit, saving, invalid } = this.props;

    return (
      <div>
        <form onSubmit={this.submit}>
          <Field name='message' component='textarea' validate={[required()]} required />
          <Button size={Sizes.SMALL} isDisabled={saving || invalid} type='submit'>
            Submit
          </Button>
        </form>
      </div>
    );
  }
}

CommentForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  saving: PropTypes.bool,
};

export default reduxForm({
  form: 'comment',
})(CommentForm);

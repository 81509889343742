import Immutable from 'immutable';
import { combineReducers } from 'redux-immutable';
import { reducer as form } from 'redux-form/immutable';
import { LOCATION_CHANGE } from 'react-router-redux';
import project from './project';
import reports from './reports';
import admin from './admin';
import login from 'routes/LoginPage/reducer';

// react-router-redux routeReducer does not work with Immutable.js. We need to use a custom reducer.
const routerInitialState = Immutable.fromJS({
  locationBeforeTransitions: null,
});
const router = (state = routerInitialState, action) => {
  if (action.type === LOCATION_CHANGE) {
    return state.set('locationBeforeTransitions', action.payload);
  }

  return state;
};

export default combineReducers({ router, form, project, login, reports, admin });

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import orderBy from 'lodash/orderBy';
import {
  Inline,
  Pagination,
  PaginationItem,
  PaginationNext,
  PaginationPrevious,
  TabItem,
  TabPanel,
  Tabs,
  TabsContent,
} from 'react-foundation';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { toJS } from 'utils/toJS';
import { list, reset } from 'actions/project/list';
import { stripApiPath } from 'utils/helpers';
import MainContainer from 'routes/ProjectPage/MainContainer';
import {
  selectAllProjectStats,
  selectCompanyStats,
  selectDashboardView,
  selectData,
  selectDateFilters,
  selectError,
  selectLoading,
  selectOrderBy,
  selectProjectFilter,
} from 'routes/DashboardPage/selectors';
import {
  allProjectStatsReset,
  getAllProjectStats,
  getCompanyStats,
  setOrderBy,
  updateProjectFilter,
} from 'routes/DashboardPage/actions';
import find from 'lodash/find';
import moment from 'moment/moment';
import {
  PROJECT_LIST_SORT_DIRECTIONS,
  PROJECT_SORT_BILLABLE_HOURS,
  PROJECT_SORT_CPP,
  PROJECT_SORT_NAME,
  PROJECT_SORT_TICKET_QUALITY,
  PROJECT_SORT_WRITE_OFF_HOURS,
} from 'utils/constants';
import CompanyStatsBlock from 'routes/DashboardPage/components/CompanyStatsBlock';
import QuickActionsBlock from 'routes/DashboardPage/components/QuickActionsBlock';
import ProjectList from 'routes/DashboardPage/components/ProjectList';
import DashboardTabs from 'routes/DashboardPage/components/DashboardTabs';
import Forecasting from 'routes/DashboardPage/components/Forecasting/Forecasting';
import { getAllUserRoles, getUserData } from 'utils/auth';

function getPaginationLink(currentUri, page) {
  return encodeURIComponent(stripApiPath(currentUri.replace(/page=\d+/i, `page=${page}`), ''));
}

class DashboardPage extends Component {
  static propTypes = {
    error: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    data: PropTypes.object.isRequired,
    list: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    getCompanyStats: PropTypes.func.isRequired,
    companyStats: PropTypes.shape({
      loading: PropTypes.bool,
      error: PropTypes.string,
      data: PropTypes.object,
    }),
    getAllProjectStats: PropTypes.func.isRequired,
    resetAllProjectStats: PropTypes.func.isRequired,
    allProjectStats: PropTypes.shape({
      loading: PropTypes.bool,
      error: PropTypes.string,
      data: PropTypes.array,
    }),
    projectFilter: PropTypes.string,
    updateProjectFilter: PropTypes.func.isRequired,
    setOrderBy: PropTypes.func.isRequired,
    orderBy: PropTypes.shape({
      orderBy: PropTypes.string,
      direction: PropTypes.string,
    }),
    dashboardView: PropTypes.string,
  };

  static buildQueryFilters(props) {
    const { startDate, endDate } = props.filters;
    const start = moment(startDate).format('YYYY-MM-DD');
    const end = moment(endDate).format('YYYY-MM-DD');

    return { start, end };
  }

  componentDidMount() {
    if (!this.props.data['hydra:member']) {
      this.props.list(this.props.match.params.page && decodeURIComponent(this.props.match.params.page), {
        project: this.props.projectFilter,
      });
    }

    if (!this.props.companyStats.loading && !this.props.companyStats.data) {
      this.props.getCompanyStats();
    }

    if (!this.props.allProjectStats.loading && !this.props.allProjectStats.data) {
      this.loadAllProjectStats();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.match.params.page !== nextProps.match.params.page ||
      this.props.projectFilter !== nextProps.projectFilter
    ) {
      nextProps.list(nextProps.match.params.page && decodeURIComponent(nextProps.match.params.page), {
        project: nextProps.projectFilter,
      });
    }

    if (
      nextProps.filters.startDate !== this.props.filters.startDate ||
      nextProps.filters.endDate !== this.props.filters.endDate
    ) {
      this.loadAllProjectStats(nextProps);
    }
  }

  componentWillUnmount() {
    // this.props.reset();
  }

  loadAllProjectStats(props) {
    if (!props) {
      props = this.props;
    }

    props.resetAllProjectStats();

    const { start, end } = DashboardPage.buildQueryFilters(props);

    props.getAllProjectStats(start, end);
  }

  updateProjectFilter(filter) {
    if (filter !== this.props.projectFilter) {
      this.props.updateProjectFilter(filter);
      this.props.list(this.props.match.params.page && decodeURIComponent(this.props.match.params.page), {
        project: filter,
      });
    }
  }

  updateOrderBy(orderBy) {
    if (orderBy !== this.props.orderBy.orderBy) {
      this.props.setOrderBy(orderBy, PROJECT_LIST_SORT_DIRECTIONS[orderBy]);
    }
  }

  render() {
    const { companyStats } = this.props;

    const projectsSorted =
      this.props.data['hydra:member'] &&
      orderBy(
        this.props.data['hydra:member'],
        (item) => {
          // Sort the project listing based on sort option selected.
          if (this.props.orderBy.orderBy === PROJECT_SORT_NAME) {
            return item.name.toLowerCase();
          }

          if (this.props.orderBy.orderBy === PROJECT_SORT_CPP) {
            return item.health.cost_per_point;
          }

          const projectStats =
            this.props.allProjectStats.data &&
            find(this.props.allProjectStats.data, (stats) => stats.project['@id'] === item['@id']);
          switch (this.props.orderBy.orderBy) {
            case PROJECT_SORT_TICKET_QUALITY:
              return (
                (projectStats && projectStats.jiraStats && projectStats.jiraStats.ticket_quality.good_ticket_ratio) || 0
              );
            case PROJECT_SORT_BILLABLE_HOURS:
              return (
                (projectStats &&
                  projectStats.glanceStats.budget_breakdown &&
                  (projectStats.glanceStats.budget_breakdown.invoiced.hours ||
                    projectStats.glanceStats.budget_breakdown.internal.hours)) ||
                0
              );
            case PROJECT_SORT_WRITE_OFF_HOURS:
              return (projectStats && projectStats.glanceStats.budget_breakdown.write_off.hours) || 0;
          }

          return item.name.toLowerCase();
        },
        this.props.orderBy.direction
      );

    return (
      <div>
        <MainContainer>
          <QuickActionsBlock />
          <CompanyStatsBlock companyStats={companyStats} />
          <DashboardTabs
            projectOverview={
              <ProjectList
                projectFilter={this.props.projectFilter}
                loading={this.props.loading}
                error={this.props.error}
                projectsSorted={projectsSorted}
                allProjectStats={this.props.allProjectStats}
                updateProjectFilter={this.updateProjectFilter.bind(this)}
              />
            }
            forecasting={
              <Forecasting projects={projectsSorted} loading={this.props.loading} error={this.props.error} />
            }
          />
        </MainContainer>
      </div>
    );
  }

  pagination() {
    const view = this.props.data['hydra:view'];
    if (!view) return;

    const total = this.props.data['hydra:totalItems'];
    const perPage = 30;

    const pages = Math.ceil(total / perPage);

    const {
      'hydra:first': first,
      'hydra:previous': previous,
      'hydra:next': next,
      'hydra:last': last,
      '@id': current,
    } = view;

    const curPageMatch = /page=(\d+)/i;
    const matches = current.match(curPageMatch);
    const curPage = parseInt(matches[1]);

    return (
      <Pagination aria-label='Pagination' isCentered>
        <PaginationPrevious isDisabled={curPage === 1}>
          {curPage !== 1 ? (
            <Link to={!previous || previous === first ? '.' : encodeURIComponent(stripApiPath(previous))}>
              Previous <Inline showForSr>page</Inline>
            </Link>
          ) : (
            <span>
              Previous <Inline showForSr>page</Inline>
            </span>
          )}
        </PaginationPrevious>
        {Array.from(Array(pages).keys()).map((page) => (
          <PaginationItem isCurrent={page + 1 === curPage}>
            {curPage !== page + 1 ? (
              <Link to={getPaginationLink(current, page + 1)}>{page + 1}</Link>
            ) : (
              <span>{page + 1}</span>
            )}
          </PaginationItem>
        ))}
        <PaginationNext isDisabled={curPage === pages}>
          {curPage !== pages ? (
            <Link to={next ? encodeURIComponent(stripApiPath(next, '')) : '#'} aria-label='Next page'>
              Next <Inline showForSr>page</Inline>
            </Link>
          ) : (
            <span>
              Next <Inline showForSr>page</Inline>
            </span>
          )}
        </PaginationNext>
      </Pagination>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  data: selectData,
  error: selectError,
  loading: selectLoading,
  companyStats: selectCompanyStats,
  allProjectStats: selectAllProjectStats,
  projectFilter: selectProjectFilter,
  filters: selectDateFilters,
  orderBy: selectOrderBy,
  dashboardView: selectDashboardView,
});

const mapDispatchToProps = (dispatch) => {
  return {
    updateProjectFilter: (filter) => dispatch(updateProjectFilter(filter)),
    list: (page, filters = null) => dispatch(list(page, filters)),
    getCompanyStats: () => dispatch(getCompanyStats()),
    getAllProjectStats: (startDate, endDate) => dispatch(getAllProjectStats(startDate, endDate)),
    resetAllProjectStats: () => dispatch(allProjectStatsReset()),
    setOrderBy: (orderBy, direction = 'ASC') => dispatch(setOrderBy(orderBy, direction)),
    reset: () => {
      dispatch(reset());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(toJS(DashboardPage));

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import moment from 'moment';
import {
  getPresetRange,
  RANGE_PRESET_CUSTOM,
  RANGE_PRESET_LAST_MONTH,
  RANGE_PRESET_LAST_QUARTER,
  RANGE_PRESET_LAST_SEMI_MONTH,
  RANGE_PRESET_LAST_YEAR,
  RANGE_PRESET_THIS_MONTH,
  RANGE_PRESET_THIS_QUARTER,
  RANGE_PRESET_THIS_SEMI_MONTH,
  RANGE_PRESET_THIS_YEAR,
} from 'utils/dateUtils';
import { Cell, Grid } from 'react-foundation';

const options = [
  { value: RANGE_PRESET_THIS_SEMI_MONTH, label: 'This semimonth' },
  { value: RANGE_PRESET_LAST_SEMI_MONTH, label: 'Last semimonth' },
  { value: RANGE_PRESET_THIS_MONTH, label: `This month (${moment().format('MMM')})` },
  { value: RANGE_PRESET_LAST_MONTH, label: `Last month (${moment().subtract(1, 'months').format('MMM')})` },
  { value: RANGE_PRESET_THIS_QUARTER, label: 'This quarter' },
  { value: RANGE_PRESET_LAST_QUARTER, label: 'Last quarter' },
  { value: RANGE_PRESET_THIS_YEAR, label: 'This year' },
  { value: RANGE_PRESET_LAST_YEAR, label: 'Last year' },
  { value: RANGE_PRESET_CUSTOM, label: 'Custom' },
];

export default function DateRangeSelect({ value, onChange, initialPreset }) {
  const [preset, setPreset] = useState(options.find((x) => x.value === (initialPreset || RANGE_PRESET_THIS_MONTH)));
  const initialDateRange = useMemo(() => getPresetRange(initialPreset || RANGE_PRESET_THIS_MONTH), [initialPreset]);

  useEffect(() => {
    const newDateRange = getPresetRange(initialPreset || RANGE_PRESET_THIS_MONTH);
    onChange && onChange(newDateRange);
  }, [initialPreset, onChange]);

  const handlePresetChange = useCallback(
    (newValue) => {
      setPreset(newValue);
      const newDateRange = getPresetRange(newValue.value);
      onChange && onChange(newDateRange);
    },
    [onChange]
  );

  const handleFromChange = useCallback(
    (newValue) => {
      onChange &&
        onChange({
          startDate: newValue,
          endDate: value?.endDate,
        });
    },
    [onChange, value]
  );

  const handleToChange = useCallback(
    (newValue) => {
      onChange &&
        onChange({
          startDate: value?.startDate,
          endDate: newValue,
        });
    },
    [onChange, value]
  );

  return (
    <Grid gutters='margin'>
      <Cell small={12} large={4}>
        <label>
          Range
          <Select
            name='dateRangePresetSelector'
            options={options}
            className='basic-multi-select'
            classNamePrefix='select'
            value={preset}
            onChange={handlePresetChange}
            required
          />
        </label>
      </Cell>
      <Cell small={6} large={4}>
        <label>
          From
          <input
            type='date'
            value={value?.startDate || initialDateRange.startDate}
            onChange={(e) => handleFromChange(moment(e.target.value).format('YYYY-MM-DD'))}
            readOnly={preset.value !== RANGE_PRESET_CUSTOM}
            required
          />
        </label>
      </Cell>
      <Cell small={6} large={4}>
        <label>
          To
          <input
            type='date'
            value={value?.endDate || initialDateRange.endDate}
            onChange={(e) => handleToChange(moment(e.target.value).format('YYYY-MM-DD'))}
            readOnly={preset.value !== RANGE_PRESET_CUSTOM}
            required
          />
        </label>
      </Cell>
    </Grid>
  );
}

import {
  DATE_PRESET_CUSTOM,
  DATE_PRESET_MONTH,
  DATE_PRESET_QUARTER,
  DATE_PRESET_SEMIMONTH,
  DATE_PRESET_YEAR,
  FORECASTING_FILTER_A,
  FORECASTING_FILTER_ACTUAL,
  FORECASTING_FILTER_B,
  PROJECT_FILTER_ACTIVE,
  PROJECT_FILTER_ALL,
  PROJECT_FILTER_INTERNAL,
  PROJECT_FILTER_KEY,
  PROJECT_SORT_BILLABLE_HOURS,
  PROJECT_SORT_CPP,
  PROJECT_SORT_NAME,
  PROJECT_SORT_TICKET_QUALITY,
  PROJECT_SORT_WRITE_OFF_HOURS,
} from 'utils/constants';

export const DATE_PRESET_PREFIX_CURRENT = 'This ';
export const DATE_PRESET_PREFIX_PREVIOUS = 'Last ';
export const DATE_PRESET_PREFIX_NEXT = 'Next ';

export const DATE_PRESET_MESSAGES = {
  [DATE_PRESET_SEMIMONTH]: 'Semimonth',
  [DATE_PRESET_MONTH]: 'Month',
  [DATE_PRESET_YEAR]: 'Year',
  [DATE_PRESET_QUARTER]: 'Quarter',
  [DATE_PRESET_CUSTOM]: 'Custom',
};

export const PROJECT_LIST_FILTER_MESSAGES = {
  [PROJECT_FILTER_ALL]: 'All Projects',
  [PROJECT_FILTER_ACTIVE]: 'Active Projects',
  [PROJECT_FILTER_KEY]: 'Key Projects',
  [PROJECT_FILTER_INTERNAL]: 'Internal Projects',
};

export const PROJECT_LIST_SORT_MESSAGES = {
  [PROJECT_SORT_NAME]: 'Name',
  [PROJECT_SORT_CPP]: 'Cost Per Point',
  [PROJECT_SORT_TICKET_QUALITY]: 'Ticket Quality',
  [PROJECT_SORT_BILLABLE_HOURS]: 'Billable Hours',
  [PROJECT_SORT_WRITE_OFF_HOURS]: 'Writeoff Hours',
};

export const FORECASTING_FILTER_MESSAGES = {
  [FORECASTING_FILTER_ACTUAL]: 'Prediction',
  [FORECASTING_FILTER_A]: 'Forecasting A',
  [FORECASTING_FILTER_B]: 'Forecasting B',
};

import { connect } from 'react-redux';
import Admin from '../components';
import { createStructuredSelector } from 'reselect';
import { selectHarvestResyncLoading, selectJiraResyncLoading, selectSyncStatus } from '../selectors';
import { getSyncStatus, resyncHarvest, resyncJira } from '../reducer';
import { toJS } from '../../../utils/toJS';

const mapStateToProps = createStructuredSelector({
  syncStatus: selectSyncStatus,
  harvestLoading: selectHarvestResyncLoading,
  jiraLoading: selectJiraResyncLoading,
});

const mapDispatchToProps = {
  getSyncStatus,
  resyncHarvest,
  resyncJira,
};

export default connect(mapStateToProps, mapDispatchToProps)(toJS(Admin));

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import orderBy from 'lodash/orderBy';
import DataGrid from 'components/DataGrid/DataGrid';
import { ValueFormatterParams } from 'ag-grid-community';
import { PROJECT_SORT_CPP, PROJECT_SORT_NAME } from 'utils/constants';
import GridActions from 'routes/DashboardPage/components/Forecasting/GridActions';
import { v4 as uuidv4 } from 'uuid';
import { useUpsertForecast } from 'hooks/api/forecastingHooks';
import { GridContainer } from 'react-foundation';

const DEFAULT_RATE = 165;

function buildEmptyRow(monthColumns) {
  return buildRow({ '@id': `custom__${uuidv4()}`, name: 'New project row', hourlyRate: DEFAULT_RATE }, monthColumns);
}

function buildRow(project, monthColumns, data = {}) {
  return {
    id: project['@id'],
    name: project.name,
    hourlyRate: project.hourlyRate,
    ...monthColumns.reduce((cols, col) => {
      cols[`${col}_hours`] = 0;
      cols[`${col}_dollars`] = 0;
      return cols;
    }, {}),
    ...(data[project['@id']] || {}),
  };
}

function buildRowData(projects, monthColumns, data = {}) {
  return projects.map((project) => buildRow(project, monthColumns, data));
}

function updateRowData(rowData, monthColumns) {
  return rowData.map((row) => ({
    ...monthColumns.reduce((cols, col) => {
      cols[`${col}_hours`] = 0;
      cols[`${col}_dollars`] = 0;
      return cols;
    }, {}),
    ...row,
  }));
}

function sortProjects(projects = [], order = PROJECT_SORT_NAME) {
  return orderBy(
    projects,
    (item) => {
      // Sort the project listing based on sort option selected.
      if (order === PROJECT_SORT_NAME) {
        return item.name.toLowerCase();
      }

      if (order === PROJECT_SORT_CPP) {
        return item.health?.cost_per_point;
      }

      return item.name.toLowerCase();
    },
    'ASC'
  );
}

function currencyFormatter(currency, sign) {
  if (isNaN(currency)) {
    return currency;
  }

  const sansDec = parseFloat(currency).toFixed(0);
  const formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return sign + `${formatted}`;
}

// TODO: make this dynamic.
function getMonthColumns() {
  return [
    '2023-01',
    '2023-02',
    '2023-03',
    '2023-04',
    '2023-05',
    '2023-06',
    '2023-07',
    '2023-08',
    '2023-09',
    '2023-10',
    '2023-11',
    '2023-12',
  ];
}

/**
 *
 * @param {ValueFormatterParams} params
 * @returns {*|string}
 */
function currencyColValueFormatter(params) {
  return currencyFormatter(params.value, '$');
}

ForecastingGrid.propTypes = {
  id: PropTypes.string,
  projects: PropTypes.array,
  data: PropTypes.object,
};

function ForecastingGrid({ id, projects, data }) {
  const gridRef = useRef();
  const sortedProjects = useMemo(() => sortProjects(projects), [projects]);
  const monthColumns = useMemo(() => getMonthColumns(), []);

  const [forecastResult, upsertForecast] = useUpsertForecast();

  const [rowData, setRowData] = useState(() => {
    if (data && data.rowData) {
      return updateRowData(data.rowData, monthColumns);
    }

    return buildRowData(sortedProjects, monthColumns, data);
  });

  useEffect(() => {
    if (data && data.rowData) {
      setRowData(updateRowData(data.rowData, monthColumns));
    } else {
      setRowData(buildRowData(sortedProjects, monthColumns, data));
    }
  }, [data]);

  const [columnDefs] = useState([
    {
      field: 'name',
      minWidth: 250,
      rowDrag: true,
      aggFunc: () => 'TOTALS',
      pinned: 'left',
    },
    {
      field: 'hourlyRate',
      headerName: 'Rate',
      type: 'numericColumn',
      aggFunc: 'avg',
      valueFormatter: currencyColValueFormatter,
      pinned: 'left',
    },
    ...monthColumns.map((col) => ({
      headerName: col,
      children: [
        {
          field: `${col}_hours`,
          headerName: 'Hours',
          type: 'numericColumn',
          aggFunc: 'sum',
          valueSetter: (params) => {
            const newValInt = parseInt(params.newValue);
            const valueChanged = params.data[`${col}_hours`] !== newValInt;
            if (valueChanged) {
              params.data[`${col}_hours`] = newValInt;
            }
            return valueChanged;
          },
        },
        {
          field: `${col}_dollars`,
          headerName: 'Dollars',
          type: 'numericColumn',
          editable: false,
          valueGetter: (params) => (params.data?.[`${col}_hours`] || 0) * (params.data?.hourlyRate || 0),
          valueFormatter: currencyColValueFormatter,
          aggFunc: 'sum',
        },
      ],
    })),
  ]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 100,
      editable: true,
      resizable: true,
      sortable: true,
    };
  }, []);

  const handleCellValueChange = useCallback(
    async (event) => {
      const updatedData = rowData.map((row) => {
        if (row.id === event.data.id) {
          row[event.column.colId] = event.value;
        }

        return row;
      });

      await upsertForecast({ id, data: { _id: id, rowData: updatedData } });
      setRowData(updatedData);
    },
    [id, rowData, upsertForecast]
  );

  const handleAddRow = useCallback(
    async (event) => {
      const { api } = gridRef.current || {};
      if (!api) {
        return;
      }

      const newRow = buildEmptyRow(monthColumns);

      api.applyTransaction({ add: [newRow] });

      const updatedData = [...rowData, newRow];
      await upsertForecast({ id, data: { _id: id, rowData: updatedData } });
      setRowData(updatedData);
    },
    [gridRef, monthColumns, rowData]
  );

  const handleDeleteSelectedRows = useCallback(
    async (event) => {
      const { api } = gridRef.current || {};
      if (!api) {
        return;
      }

      const selectedRows = api.getSelectedRows();

      if (selectedRows.length > 0) {
        api.applyTransaction({ remove: selectedRows });

        const updatedData = rowData.filter((x) => !selectedRows.find((selected) => selected.id === x.id));
        await upsertForecast({ id, data: { _id: id, rowData: updatedData } });
        setRowData(updatedData);
      }
    },
    [gridRef, rowData]
  );

  return (
    <div>
      <GridContainer>
        <GridActions onAddRow={handleAddRow} onDeleteSelectedRows={handleDeleteSelectedRows} />
      </GridContainer>
      <GridContainer>
        <DataGrid
          gridRef={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onCellValueChanged={handleCellValueChange}
          undoRedoCellEditingLimit={20}
          rowSelection='multiple'
          undoRedoCellEditing
          enableCellChangeFlash
          enableRangeSelection
          enableFillHandle
          allowContextMenuWithControlKey
          rowDragManaged
          groupIncludeTotalFooter
        />
        <GridActions onAddRow={handleAddRow} onDeleteSelectedRows={handleDeleteSelectedRows} />
      </GridContainer>
    </div>
  );
}

export default ForecastingGrid;

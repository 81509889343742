import LabelAndValue from 'routes/DashboardPage/components/LabelAndValue';
import Currency from 'components/Currency/Currency';
import React from 'react';
import PropTypes from 'prop-types';

export default function HoursSplitBreakdown({ data: { invoiced, write_off, internal } }) {
  return (
    <>
      {invoiced && (
        <LabelAndValue label='Billable:'>
          <Currency value={invoiced.dollars} />
        </LabelAndValue>
      )}
      {write_off && (
        <LabelAndValue label='Write-Off:'>
          <Currency value={write_off.dollars} />
        </LabelAndValue>
      )}
      {internal && (
        <LabelAndValue label='Internal:'>
          <Currency value={internal.dollars} />
        </LabelAndValue>
      )}
    </>
  );
}

HoursSplitBreakdown.propTypes = {
  data: PropTypes.shape({
    invoiced: PropTypes.shape({ hours: PropTypes.number, dollars: PropTypes.number }),
    write_off: PropTypes.shape({ hours: PropTypes.number, dollars: PropTypes.number }),
    internal: PropTypes.shape({ hours: PropTypes.number, dollars: PropTypes.number }),
  }),
};

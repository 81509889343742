import React from 'react';
import PropTypes from 'prop-types';

const JIRA_URL = 'https://caxyinteractive.atlassian.net/browse/';

const propTypes = {};

const defaultProps = {};

function TicketListItems({ status, issues, jiraLinks, showAsterisks }) {
  if (!issues || issues.length === 0) {
    return null;
  }

  return issues.map((issue) => (
    <li key={issue.id}>
      <strong>{status.toUpperCase()}: </strong>
      {jiraLinks ? (
        <span>
          [
          <a href={JIRA_URL + issue.key} target='_blank'>
            {issue.key}
          </a>
          ]
        </span>
      ) : (
        <span>[{issue.key}]</span>
      )}{' '}
      {issue.fields.summary} {showAsterisks && <strong>***</strong>}
    </li>
  ));
}

TicketListItems.propTypes = propTypes;
TicketListItems.defaultProps = defaultProps;

export default TicketListItems;

import { useQuery } from '@apollo/client';
import INVOICE_NOTES_QUERY from 'hooks/graphql/useQueryInvoiceNotes/invoiceNoteQuery';

export default function useQueryInvoiceNotes(input) {
  return useQuery(INVOICE_NOTES_QUERY, {
    variables: {
      input,
    },
  });
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Colors } from 'react-foundation';
import Button from 'components/Button';

const Wrapper = styled.div`
  .dropdown-pane {
    z-index: 9000;
  }
`;

class DropdownButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  /**
   * Set the wrapper ref
   */
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  /**
   * Close if clicked on outside of element
   */
  handleClickOutside(event) {
    if (this.wrapperRef && this.state.isOpen && !this.wrapperRef.contains(event.target)) {
      this.close();
    }
  }

  open() {
    this.setState({ isOpen: true });
    if (this.props.onOpen) {
      this.props.onOpen();
    }
  }

  close() {
    this.setState({ isOpen: false });
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  toggle() {
    if (this.state.isOpen) {
      this.close();
    } else {
      this.open();
    }
  }

  render() {
    const { children, label, buttonComponent: ButtonComponent, buttonProps, className } = this.props;

    return (
      <div ref={this.setWrapperRef} className={className}>
        <Wrapper>
          <ButtonComponent {...buttonProps} onClick={this.toggle}>
            {label}
          </ButtonComponent>
          {this.state.isOpen && (
            <div className={`dropdown-pane ${this.state.isOpen ? 'is-open' : ''}`.trim()} onClick={this.close}>
              {children}
            </div>
          )}
        </Wrapper>
      </div>
    );
  }
}

DropdownButton.defaultProps = {
  buttonComponent: Button,
  buttonProps: {
    color: Colors.SECONDARY,
    isHollow: true,
    isDropdown: true,
  },
};

DropdownButton.propTypes = {
  label: PropTypes.node.isRequired,
  buttonComponent: PropTypes.node,
  buttonProps: PropTypes.object,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
};

export default DropdownButton;

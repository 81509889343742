import useAction from 'hooks/useAction';
import { useEffect } from 'react';
import { getForecasts, upsertForecast } from 'api/forecastingApi';

/**
 *
 * @returns {({data: unknown, loading: boolean, error: unknown}|(function(*=): Promise<void>)|*)[]}
 */
export function useForecastList() {
  const [state, action] = useAction(getForecasts);

  useEffect(() => {
    action();
  }, [action]);

  return [state, action];
}

export function useUpsertForecast() {
  return useAction(upsertForecast);
}

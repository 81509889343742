import fetch from 'utils/fetch';
import jwtDecode from 'jwt-decode';

const AUTH_TOKEN_NAME = 'pulsecheck_auth_token';

export function getAuthToken() {
  return localStorage.getItem(AUTH_TOKEN_NAME);
}

export function setAuthToken(token) {
  return localStorage.setItem(AUTH_TOKEN_NAME, token);
}

export function clearAuthToken() {
  return localStorage.removeItem(AUTH_TOKEN_NAME);
}

export function isAuthenticated() {
  return !!getAuthToken();
}

export function getUserData() {
  const token = getAuthToken();
  if (!token) {
    return;
  }

  return jwtDecode(token);
}

export function getAllUserRoles() {
  const data = getUserData();
  if (!data) {
    return [];
  }

  const { allRoles, roles } = data;

  return allRoles || roles || [];
}

export function isPermitted(role) {
  const userRoles = getAllUserRoles();

  return userRoles.includes(role);
}

export function authenticate(username, password) {
  return fetch('/api/login_check', {
    body: JSON.stringify({ username, password }),
    method: 'POST',
  })
    .then((response) => response.json())
    .then((data) => {
      setAuthToken(data.token);

      return data.token;
    });
}

import fetch from 'utils/fetch';

export const PROJECT_LIST_DATA_RESET = 'app/DashboardPage/PROJECT_LIST_DATA_RESET';

export const COMPANY_STATS_LOADING = 'app/DashboardPage/COMPANY_STATS_LOADING';
export const COMPANY_STATS_SUCCESS = 'app/DashboardPage/COMPANY_STATS_SUCCESS';
export const COMPANY_STATS_ERROR = 'app/DashboardPage/COMPANY_STATS_ERROR';
export const COMPANY_STATS_RESET = 'app/DashboardPage/COMPANY_STATS_RESET';

export const ALL_PROJECT_STATS_LOADING = 'app/DashboardPage/ALL_PROJECT_STATS_LOADING';
export const ALL_PROJECT_STATS_SUCCESS = 'app/DashboardPage/ALL_PROJECT_STATS_SUCCESS';
export const ALL_PROJECT_STATS_ERROR = 'app/DashboardPage/ALL_PROJECT_STATS_ERROR';
export const ALL_PROJECT_STATS_RESET = 'app/DashboardPage/ALL_PROJECT_STATS_RESET';

export const UPDATE_PROJECT_FILTER = 'app/DashboardPage/UPDATE_PROJECT_FILTER';

export const SET_DATE_FILTERS = 'app/DashboardPage/SET_DATE_FILTERS';
export const SET_DATE_PRESET = 'app/DashboardPage/SET_DATE_PRESET';

export const SET_ORDER_BY = 'app/DashboardPage/SET_ORDER_BY';

export const SET_DASHBOARD_VIEW = 'app/DashboardPage/SET_DASHBOARD_VIEW';

export const VIEW_PROJECT_PAGE = 'app/global/VIEW_PROJECT_PAGE';
export const REMOVE_RECENT_PROJECT = 'app/global/REMOVE_RECENT_PROJECT';

export function resetData() {
  return { type: PROJECT_LIST_DATA_RESET };
}

export function viewProjectPage(projectId) {
  return { type: VIEW_PROJECT_PAGE, payload: projectId };
}

export function removeRecentProject(projectId) {
  return { type: REMOVE_RECENT_PROJECT, payload: projectId };
}

export function companyStatsError(error) {
  return { type: COMPANY_STATS_ERROR, payload: error };
}

export function companyStatsLoading(loading) {
  return { type: COMPANY_STATS_LOADING, payload: loading };
}

export function companyStatsSuccess(payload) {
  return { type: COMPANY_STATS_SUCCESS, payload };
}

export function companyStatsReset() {
  return { type: COMPANY_STATS_RESET };
}

export function getCompanyStats() {
  return (dispatch) => {
    dispatch(companyStatsLoading(true));

    return fetch(`/company_stats`)
      .then((response) => response.json())
      .then((data) => {
        dispatch(companyStatsLoading(false));
        dispatch(companyStatsSuccess(data['hydra:member'][0]));
      })
      .catch((e) => {
        dispatch(companyStatsLoading(false));
        dispatch(companyStatsError(e.message));
      });
  };
}

export function allProjectStatsError(error) {
  return { type: ALL_PROJECT_STATS_ERROR, payload: error };
}

export function allProjectStatsLoading(loading) {
  return { type: ALL_PROJECT_STATS_LOADING, payload: loading };
}

export function allProjectStatsSuccess(payload) {
  return { type: ALL_PROJECT_STATS_SUCCESS, payload };
}

export function allProjectStatsReset() {
  return { type: ALL_PROJECT_STATS_RESET };
}

export function updateProjectFilter(filter) {
  return { type: UPDATE_PROJECT_FILTER, payload: filter };
}

export function setDateFilters(startDate, endDate) {
  return {
    type: SET_DATE_FILTERS,
    payload: { startDate, endDate },
  };
}

export function setDatePreset(preset) {
  return {
    type: SET_DATE_PRESET,
    payload: preset,
  };
}

export function setOrderBy(orderBy, direction = 'asc') {
  return {
    type: SET_ORDER_BY,
    payload: { orderBy, direction },
  };
}

export function setDashboardView(view) {
  return {
    type: SET_DASHBOARD_VIEW,
    payload: view,
  };
}

export function getAllProjectStats(startDate, endDate) {
  return (dispatch) => {
    dispatch(allProjectStatsLoading(true));

    const query = `?start_date=${startDate}&end_date=${endDate}`;

    return fetch(`/project_stats${query}`)
      .then((response) => response.json())
      .then((data) => {
        dispatch(allProjectStatsLoading(false));
        dispatch(allProjectStatsSuccess(data['hydra:member']));
      })
      .catch((e) => {
        dispatch(allProjectStatsLoading(false));
        dispatch(allProjectStatsError(e.message));
      });
  };
}

export function reset() {
  return { type: COMPANY_STATS_RESET };
}

import { createSelector } from 'reselect';

const getShowDomain = (state) => state.getIn(['project', 'show']);

export const selectError = createSelector(getShowDomain, (show) => show.get('error'));
export const selectLoading = createSelector(getShowDomain, (show) => show.get('loading'));
export const selectRetrieved = createSelector(getShowDomain, (show) => show.get('retrieved'));
export const selectFilters = createSelector(getShowDomain, (show) => show.get('filters'));
export const selectOverviewStats = createSelector(getShowDomain, (show) => show.get('overviewStats'));
export const selectFilteredStats = createSelector(getShowDomain, (show) => show.get('filteredStats'));

import fetch from 'utils/fetch';
import queryString from 'query-string';

export async function getSyncStatus(jobKey) {
  const res = await fetch(`/sync/status/${jobKey}`);

  return res.json();
}

export async function triggerResync({ source, projectIds = null }) {
  let query = '';

  if (projectIds && Array.isArray(projectIds) && projectIds.length > 0) {
    query = '?' + queryString.stringify({ project_ids: projectIds }, { arrayFormat: 'bracket' });
  }

  const res = await fetch(`/sync/${source}${query}`);

  return res.json();
}

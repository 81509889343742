import React, { Component } from 'react';
import styled from 'styled-components/macro';
import { Menu, MenuItem, TabPanel } from 'react-foundation';
import TeamMembers from 'containers/ProjectSidebar/TeamMembers';
import { projectShape } from 'utils/shapes/project';
import ProjectNotesContainer from 'routes/ProjectPage/containers/ProjectNotesContainer';
import InvoicesContainer from 'routes/InvoicesContainer';

const Wrapper = styled.section`
  min-width: 279px;
  height: 100%;
  border-left: solid 2px #f4f1ef;
  position: relative;
  left: 0;
  top: -61px;
  z-index: 1;
`;

const StyledMenu = styled(Menu)`
  width: 100%;
  height: 61px;
  padding: 0 12px;
  align-items: flex-end;
`;

const StyledMenuItem = styled(MenuItem)`
  &,
  a {
    font-family: ${(props) => props.theme.titleFontFamily};
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.3px;
    color: ${(props) => props.theme.colorGrayDark};
    text-transform: uppercase;
  }

  a {
    padding: 0.8rem 1rem;
  }
`;

const StyledTabPanel = styled(TabPanel)`
  padding: 0;
  max-width: 100%;
`;

const SidebarContent = styled.div`
  padding: 20px 25px;
`;

class ProjectSidebar extends Component {
  static propTypes = {
    project: projectShape,
  };

  state = {
    activeTab: 2,
  };

  constructor(props) {
    super(props);

    this.activateTab = this.activateTab.bind(this);
  }

  activateTab(activeTab) {
    this.setState({ activeTab });
  }

  render() {
    const { project } = this.props;
    const { activeTab } = this.state;
    return (
      <Wrapper>
        <StyledMenu>
          <StyledMenuItem isActive={activeTab === 1}>
            <a onClick={this.activateTab.bind(this, 1)}>Invoices</a>
          </StyledMenuItem>
          <StyledMenuItem isActive={activeTab === 2}>
            <a onClick={this.activateTab.bind(this, 2)}>Team</a>
          </StyledMenuItem>
          <StyledMenuItem isActive={activeTab === 3}>
            <a onClick={this.activateTab.bind(this, 3)}>Notes</a>
          </StyledMenuItem>
        </StyledMenu>
        <SidebarContent>
          <StyledTabPanel isActive={activeTab === 1}>
            <InvoicesContainer project={project} />
          </StyledTabPanel>
          <StyledTabPanel isActive={activeTab === 2}>
            <TeamMembers project={project} />
          </StyledTabPanel>
          <StyledTabPanel isActive={activeTab === 3}>
            <ProjectNotesContainer project={project} />
          </StyledTabPanel>
        </SidebarContent>
      </Wrapper>
    );
  }
}

export default ProjectSidebar;

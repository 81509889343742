import React, { Component } from 'react';
import H1 from 'components/H1';
import { GridContainer, Link } from 'react-foundation';
import { API_HOST } from 'config/_entrypoint';
import { HARVEST_SYNC_JOB_KEY_ALL, JIRA_SYNC_JOB_KEY_ALL } from '../reducer';
import size from 'lodash/size';
import JobSyncButton from './JobSyncButton';
import PropTypes from 'prop-types';

class Admin extends Component {
  static propTypes = {
    resyncHarvest: PropTypes.func.isRequired,
    resyncJira: PropTypes.func.isRequired,
    harvestLoading: PropTypes.bool,
    jiraLoading: PropTypes.bool,
    syncStatus: PropTypes.objectOf(
      PropTypes.shape({
        loading: PropTypes.bool,
        error: PropTypes.string,
        data: PropTypes.shape({
          items: PropTypes.objectOf(
            PropTypes.shape({
              received: PropTypes.string,
              sent: PropTypes.string,
            })
          ),
        }),
      })
    ),
    children: PropTypes.any,
  };

  static jobSyncs = [HARVEST_SYNC_JOB_KEY_ALL, JIRA_SYNC_JOB_KEY_ALL];

  static jobSyncIntervalMillis = 10000;

  syncStatusIntervals = {};

  componentDidMount() {
    const { syncStatus, getSyncStatus } = this.props;

    // Load the status of each job if it's not already loaded in state.
    Admin.jobSyncs.forEach((jobKey) => {
      if (!syncStatus[jobKey] || (!syncStatus[jobKey].data && !syncStatus[jobKey].loading)) {
        getSyncStatus(jobKey);
      }
    });

    this.checkForSyncStatusIntervals();
  }

  componentDidUpdate(prevProps) {
    this.checkForSyncStatusIntervals();
  }

  componentWillUnmount() {
    Object.keys(this.syncStatusIntervals).forEach((key) => {
      this.clearSyncStatusInterval(key);
    });
  }

  checkForSyncStatusIntervals() {
    const { syncStatus, getSyncStatus } = this.props;

    Object.keys(syncStatus).forEach((jobKey) => {
      // If we have an interval, but there are no more jobs, cancel the interval.
      if (this.syncStatusIntervals[jobKey] && syncStatus[jobKey].data && size(syncStatus[jobKey].data.items) === 0) {
        this.clearSyncStatusInterval(jobKey);
      }

      // If we don't have an interval, but the job is currently syncing, add interval.
      if (!this.syncStatusIntervals[jobKey] && syncStatus[jobKey].data && size(syncStatus[jobKey].data.items) > 0) {
        this.syncStatusIntervals[jobKey] = setInterval(() => {
          getSyncStatus(jobKey);
        }, Admin.jobSyncIntervalMillis);
      }
    });
  }

  clearSyncStatusInterval(key) {
    clearInterval(this.syncStatusIntervals[key]);
    delete this.syncStatusIntervals[key];
  }

  render() {
    const { syncStatus, harvestLoading, resyncHarvest, jiraLoading, resyncJira } = this.props;

    return (
      <div>
        <GridContainer>
          <H1>Admin Dashboard</H1>
          <h4>Sonata Admin</h4>
          <Link href={`${API_HOST}/admin`} target='_blank'>
            Sonata Admin
          </Link>
          <br />
          <h4>Resync External Data Sources</h4>
          <GridContainer>
            <JobSyncButton
              label='Harvest'
              onResync={resyncHarvest}
              syncStatus={syncStatus && syncStatus[HARVEST_SYNC_JOB_KEY_ALL]}
              loading={harvestLoading}
            >
              Resync Harvest Data
            </JobSyncButton>
            <JobSyncButton
              label='JIRA'
              onResync={resyncJira}
              syncStatus={syncStatus && syncStatus[JIRA_SYNC_JOB_KEY_ALL]}
              loading={jiraLoading}
            >
              Resync JIRA Data
            </JobSyncButton>
          </GridContainer>
        </GridContainer>
      </div>
    );
  }
}

Admin.propTypes = {
  resyncHarvest: PropTypes.func.isRequired,
  resyncJira: PropTypes.func.isRequired,
  harvestLoading: PropTypes.bool,
  jiraLoading: PropTypes.bool,
  syncStatus: PropTypes.objectOf(
    PropTypes.shape({
      loading: PropTypes.bool,
      error: PropTypes.string,
      data: PropTypes.shape({
        items: PropTypes.arrayOf(
          PropTypes.shape({
            received: PropTypes.string,
            sent: PropTypes.string,
          })
        ),
      }),
    })
  ),
  children: PropTypes.any,
};

export default Admin;

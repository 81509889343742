import styled, { css } from 'styled-components/macro';
import { ifProp } from 'styled-tools';
import theme from 'theme';

const Banner = styled.div`
  width: 100%;
  height: ${(props) => (props.tall ? '330px' : '169px')};
  background-color: ${theme.colorGrayLight};
  padding: ${(props) => (props.tall ? '40px 0' : '22px 0')};

  ${ifProp(
    'secondary',
    css`
      background-color: ${theme.colorBlue};
    `
  )}
`;

export default Banner;

import React from 'react';
import PropTypes from 'prop-types';
import { Colors } from 'react-foundation';
import { clearAuthToken } from 'utils/auth';
import { logout } from 'containers/App/actions';
import Button from 'components/Button';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

function LogoutContainer({ logout }) {
  return (
    <Button color={Colors.SECONDARY} isHollow onClick={logout}>
      Logout
    </Button>
  );
}

LogoutContainer.propTypes = {
  logout: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  logout: () => {
    clearAuthToken();
    dispatch(logout());
    dispatch(push('/login'));
  },
});

export default connect(null, mapDispatchToProps)(LogoutContainer);

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Button } from 'react-foundation';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;

GridActions.propTypes = {
  onAddRow: PropTypes.func.isRequired,
  onDeleteSelectedRows: PropTypes.func.isRequired,
};

function GridActions({ onAddRow, onDeleteSelectedRows }) {
  return (
    <Wrapper>
      <Button onClick={onAddRow}>Add Row</Button>
      <Button onClick={onDeleteSelectedRows}>Delete Selected Row(s)</Button>
    </Wrapper>
  );
}

export default GridActions;

import styled from 'styled-components/macro';

const Toolbar = styled.div.attrs({
  className: 'clearfix',
})`
  width: 100%;
  min-height: 52px;
  background-color: #ffffff;
  border-top: solid 2px #f4f1ef;
  border-bottom: solid 2px #f4f1ef;
  padding: 6px 0;

  ul {
    margin: 0;
    padding: 0;

    &,
    li {
      list-style: none;
    }

    li {
      float: left;
      padding: 8px 15px;

      &:not(:last-of-type) {
        border-right: solid 2px #f4f1ef;
      }
    }
  }
`;

export default Toolbar;

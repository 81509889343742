import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { GridContainer } from 'react-foundation';
import LoginForm from 'routes/LoginPage/LoginForm';
import { selectError, selectLoading } from 'routes/LoginPage/selectors';
import { loginUser } from 'routes/LoginPage/actions';
import { toJS } from 'utils/toJS';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';

class LoginPage extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    error: PropTypes.string,
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { handleSubmit, loading, error } = this.props;
    return (
      <GridContainer>
        {error && <ErrorMessage error={error} />}
        <LoginForm loading={loading} onSubmit={handleSubmit} />
      </GridContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: selectLoading(state),
  error: selectError(state),
});

const mapDispatchToProps = (dispatch) => ({
  handleSubmit: (values) => {
    dispatch(loginUser(values.get('email'), values.get('password')));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(toJS(LoginPage));

import React from 'react';
import PropTypes from 'prop-types';
import { Callout, Colors } from 'react-foundation';

function Warnings({ warnings, title = 'Warning', afterContent = null }) {
  if (!warnings || warnings.length === 0) {
    return null;
  }

  return (
    <Callout color={Colors.WARNING}>
      <h5>
        <span className='fa fa-exclamation-triangle' aria-hidden='true' /> {title}
      </h5>
      {warnings.map((warning, index) => (
        <p key={index}>{warning}</p>
      ))}
      {afterContent}
    </Callout>
  );
}

Warnings.propTypes = {
  warnings: PropTypes.arrayOf(PropTypes.node),
  title: PropTypes.node,
  afterContent: PropTypes.node,
};

export default Warnings;

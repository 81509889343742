import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import BudgetSummary from '../routes/BudgetSummary';
import DailyRecap from '../routes/DailyRecap';
import MonthlyBillableReport from '../routes/MonthlyBillableReport';

function Reports({ match }) {
  return (
    <div>
      <Switch>
        <Route
          path={`${match.url}/budget-summary/:projectId?`}
          component={BudgetSummary}
          exact={true}
          key='reports-budget-summary'
        />
        ,
        <Route
          path={`${match.url}/daily-recap/:projectId?`}
          component={DailyRecap}
          exact={true}
          key='reports-daily-recap'
        />
        ,
        <Route
          path={`${match.url}/billable-report/monthly`}
          component={MonthlyBillableReport}
          exact={true}
          key='reports-billable-monthly'
        />
        ,
        <Route render={() => <h1>Report Not Found</h1>} />
      </Switch>
    </div>
  );
}

Reports.propTypes = {};

export default Reports;

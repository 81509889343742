import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { list, reset } from 'actions/project/list';
import { stripApiPath } from 'utils/helpers';
import {
  Callout,
  Cell,
  Colors,
  Grid,
  GridContainer,
  Inline,
  Pagination,
  PaginationItem,
  PaginationNext,
  PaginationPrevious,
} from 'react-foundation';
import H1 from 'components/H1';
import MainContainer from 'routes/ProjectPage/MainContainer';

const warningStates = [
  { differential: 0.7, color: 'darkred' },
  { differential: 0.5, color: 'red' },
  { differential: 0.2, color: 'orange' },
  { differential: 0.1, color: 'yellow' },
  { differential: 0.05, color: 'blue' },
  { differential: 0, color: 'gray' },
  { differential: -0.05, color: 'pink' },
  { differential: -0.1, color: 'green' },
];

const Table = styled.table`
  border-radius: 3px;
  table-layout: fixed;

  thead {
    th {
      background-color: #f4f1ef;
      color: ${(props) => props.theme.subTitleColor};
      font-family: ${(props) => props.theme.titleFontFamily};
      font-size: 14px;
      font-weight: 500;
      line-height: 1.07;
      letter-spacing: 0.4px;
      text-align: center;
      text-transform: uppercase;
      padding: 15px 28px;
    }
  }

  th,
  td {
    border: solid 1px #e1e1e1;
    text-align: center;
  }

  td {
    background: #ffffff;
  }
`;

const LabelCell = styled.td`
  font-size: 14px;
  color: #808080;
`;

const ValueCell = styled.td`
  font-size: 21px;
  color: ${(props) => props.theme.primaryFontColor};
  font-family: ${(props) => props.theme.titleFontFamily};
`;

function getPaginationLink(currentUri, page) {
  return encodeURIComponent(stripApiPath(currentUri.replace(/page=\d+/i, `page=${page}`), ''));
}

function getProjectHealthState({ health, budgetType }) {
  if (!health || !health.budget_used_percent) {
    return undefined;
  }

  if (budgetType && !!budgetType.billableBeyondBudget) {
    return 'lightgray';
  }

  const { budget_used_percent, project_complete_percent } = health;

  if (!project_complete_percent) {
    return budget_used_percent > 1 ? 'red' : 'white';
  }

  const diff = budget_used_percent - project_complete_percent;

  // If over-budget, we don't need to look at project complete really.
  for (let i = 0; i < warningStates.length; i++) {
    const state = warningStates[i];

    if (diff >= state.differential) {
      return state.color;
    }
  }

  return warningStates[warningStates.length - 1].color;
}

const chartData = [
  {
    value: 80000,
    color: '#084159',
    highlight: '#084159',
    label: 'Invoiced',
  },
  {
    value: 7000,
    color: '#f99b1c',
    highlight: '#f99b1c',
    label: 'Caxy Write-Off',
  },
  {
    value: 3000,
    color: '#df1e36',
    highlight: '#df1e36',
    label: 'Caxy Internal',
  },
];

const aprilChartData = [
  {
    value: 120000,
    color: '#084159',
    highlight: '#084159',
    label: 'Invoiced',
  },
  {
    value: 10000,
    color: '#f99b1c',
    highlight: '#f99b1c',
    label: 'Caxy Write-Off',
  },
  {
    value: 20000,
    color: '#df1e36',
    highlight: '#df1e36',
    label: 'Caxy Internal',
  },
];

class List extends Component {
  static propTypes = {
    error: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    data: PropTypes.object.isRequired,
    list: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.list(this.props.match.params.page && decodeURIComponent(this.props.match.params.page));
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.page !== nextProps.match.params.page)
      nextProps.list(nextProps.match.params.page && decodeURIComponent(nextProps.match.params.page));
  }

  componentWillUnmount() {
    // this.props.reset();
  }

  render() {
    return (
      <div>
        <MainContainer>
          <GridContainer>
            <GridContainer>
              <H1>Team</H1>
            </GridContainer>
            {this.props.loading && (
              <Callout color={Colors.SECONDARY}>
                <p>Loading...</p>
              </Callout>
            )}
            {this.props.error && (
              <Callout color={Colors.ALERT}>
                <h5>An error occurred while loading the page</h5>
                <p>{this.props.error}</p>
              </Callout>
            )}
            <Grid gutters={'margin'}>
              <Cell small={4}>
                <Table
                  style={{
                    boxShadow: '0 1px 10px rgba(0,0,0,.05)',
                    border: '1px solid #efefef',
                  }}
                >
                  <thead>
                    <tr>
                      <th colSpan='3' style={{ backgroundColor: 'white' }}>
                        <img
                          src='https://proxy.harvestfiles.com/production_harvestapp_public/uploads/users/avatar/001/764/811/normal.png?1501777153'
                          alt='Hannah Deason-Schroeder'
                        />
                        <br />
                        <br />
                        Hannah Deason
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class='sc-dxgOiQ knedPp'>Projects</td>
                      <td class='sc-dxgOiQ knedPp'>Forecast</td>
                      <td class='sc-dxgOiQ knedPp'>Actual</td>
                    </tr>
                    <tr>
                      <td class='sc-ckVGcZ goVDDm'>WCUX</td>
                      <td class='sc-ckVGcZ goVDDm'>30%</td>
                      <td class='sc-ckVGcZ goVDDm'>22%</td>
                    </tr>
                    <tr>
                      <td class='sc-ckVGcZ goVDDm'>CARA UPDATES</td>
                      <td class='sc-ckVGcZ goVDDm'>55%</td>
                      <td class='sc-ckVGcZ goVDDm'>60%</td>
                    </tr>
                    <tr>
                      <td class='sc-ckVGcZ goVDDm'>PPDT</td>
                      <td class='sc-ckVGcZ goVDDm'>33%</td>
                      <td class='sc-ckVGcZ goVDDm'>12%</td>
                    </tr>
                    <th colspan='3' class='sc-iwsKbI iyNBqF'>
                      110% Planned, 85% Resourced
                    </th>
                  </tbody>
                </Table>
              </Cell>
              <Cell small={4}>
                <Table
                  style={{
                    boxShadow: '0 1px 10px rgba(0,0,0,.05)',
                    border: '1px solid #efefef',
                  }}
                >
                  <thead>
                    <tr>
                      <th colSpan='3' style={{ backgroundColor: 'white' }}>
                        <img
                          src='https://proxy.harvestfiles.com/production_harvestapp_public/uploads/users/avatar/001/764/811/normal.png?1501777153'
                          alt='Hannah Deason-Schroeder'
                        />
                        <br />
                        <br />
                        Hannah Deason
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class='sc-dxgOiQ knedPp'>Projects</td>
                      <td class='sc-dxgOiQ knedPp'>Forecast</td>
                      <td class='sc-dxgOiQ knedPp'>Actual</td>
                    </tr>
                    <tr>
                      <td class='sc-ckVGcZ goVDDm'>WCUX</td>
                      <td class='sc-ckVGcZ goVDDm'>30%</td>
                      <td class='sc-ckVGcZ goVDDm'>22%</td>
                    </tr>
                    <tr>
                      <td class='sc-ckVGcZ goVDDm'>CARA UPDATES</td>
                      <td class='sc-ckVGcZ goVDDm'>55%</td>
                      <td class='sc-ckVGcZ goVDDm'>60%</td>
                    </tr>
                    <tr>
                      <td class='sc-ckVGcZ goVDDm'>PPDT</td>
                      <td class='sc-ckVGcZ goVDDm'>33%</td>
                      <td class='sc-ckVGcZ goVDDm'>12%</td>
                    </tr>
                    <th colspan='3' class='sc-iwsKbI iyNBqF'>
                      110% Planned, 85% Resourced
                    </th>
                  </tbody>
                </Table>
              </Cell>
              <Cell small={4}>
                <Table
                  style={{
                    boxShadow: '0 1px 10px rgba(0,0,0,.05)',
                    border: '1px solid #efefef',
                  }}
                >
                  <thead>
                    <tr>
                      <th colSpan='3' style={{ backgroundColor: 'white' }}>
                        <img
                          src='https://proxy.harvestfiles.com/production_harvestapp_public/uploads/users/avatar/001/764/811/normal.png?1501777153'
                          alt='Hannah Deason-Schroeder'
                        />
                        <br />
                        <br />
                        Hannah Deason
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class='sc-dxgOiQ knedPp'>Projects</td>
                      <td class='sc-dxgOiQ knedPp'>Forecast</td>
                      <td class='sc-dxgOiQ knedPp'>Actual</td>
                    </tr>
                    <tr>
                      <td class='sc-ckVGcZ goVDDm'>WCUX</td>
                      <td class='sc-ckVGcZ goVDDm'>30%</td>
                      <td class='sc-ckVGcZ goVDDm'>22%</td>
                    </tr>
                    <tr>
                      <td class='sc-ckVGcZ goVDDm'>CARA UPDATES</td>
                      <td class='sc-ckVGcZ goVDDm'>55%</td>
                      <td class='sc-ckVGcZ goVDDm'>60%</td>
                    </tr>
                    <tr>
                      <td class='sc-ckVGcZ goVDDm'>PPDT</td>
                      <td class='sc-ckVGcZ goVDDm'>33%</td>
                      <td class='sc-ckVGcZ goVDDm'>12%</td>
                    </tr>
                    <th colspan='3' class='sc-iwsKbI iyNBqF'>
                      110% Planned, 85% Resourced
                    </th>
                  </tbody>
                </Table>
              </Cell>
              <Cell small={4}>
                <Table
                  style={{
                    boxShadow: '0 1px 10px rgba(0,0,0,.05)',
                    border: '1px solid #efefef',
                  }}
                >
                  <thead>
                    <tr>
                      <th colSpan='3' style={{ backgroundColor: 'white' }}>
                        <img
                          src='https://proxy.harvestfiles.com/production_harvestapp_public/uploads/users/avatar/001/764/811/normal.png?1501777153'
                          alt='Hannah Deason-Schroeder'
                        />
                        <br />
                        <br />
                        Hannah Deason
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class='sc-dxgOiQ knedPp'>Projects</td>
                      <td class='sc-dxgOiQ knedPp'>Forecast</td>
                      <td class='sc-dxgOiQ knedPp'>Actual</td>
                    </tr>
                    <tr>
                      <td class='sc-ckVGcZ goVDDm'>WCUX</td>
                      <td class='sc-ckVGcZ goVDDm'>30%</td>
                      <td class='sc-ckVGcZ goVDDm'>22%</td>
                    </tr>
                    <tr>
                      <td class='sc-ckVGcZ goVDDm'>CARA UPDATES</td>
                      <td class='sc-ckVGcZ goVDDm'>55%</td>
                      <td class='sc-ckVGcZ goVDDm'>60%</td>
                    </tr>
                    <tr>
                      <td class='sc-ckVGcZ goVDDm'>PPDT</td>
                      <td class='sc-ckVGcZ goVDDm'>33%</td>
                      <td class='sc-ckVGcZ goVDDm'>12%</td>
                    </tr>
                    <th colspan='3' class='sc-iwsKbI iyNBqF'>
                      110% Planned, 85% Resourced
                    </th>
                  </tbody>
                </Table>
              </Cell>
              <Cell small={4}>
                <Table
                  style={{
                    boxShadow: '0 1px 10px rgba(0,0,0,.05)',
                    border: '1px solid #efefef',
                  }}
                >
                  <thead>
                    <tr>
                      <th colSpan='3' style={{ backgroundColor: 'white' }}>
                        <img
                          src='https://proxy.harvestfiles.com/production_harvestapp_public/uploads/users/avatar/001/764/811/normal.png?1501777153'
                          alt='Hannah Deason-Schroeder'
                        />
                        <br />
                        <br />
                        Hannah Deason
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class='sc-dxgOiQ knedPp'>Projects</td>
                      <td class='sc-dxgOiQ knedPp'>Forecast</td>
                      <td class='sc-dxgOiQ knedPp'>Actual</td>
                    </tr>
                    <tr>
                      <td class='sc-ckVGcZ goVDDm'>WCUX</td>
                      <td class='sc-ckVGcZ goVDDm'>30%</td>
                      <td class='sc-ckVGcZ goVDDm'>22%</td>
                    </tr>
                    <tr>
                      <td class='sc-ckVGcZ goVDDm'>CARA UPDATES</td>
                      <td class='sc-ckVGcZ goVDDm'>55%</td>
                      <td class='sc-ckVGcZ goVDDm'>60%</td>
                    </tr>
                    <tr>
                      <td class='sc-ckVGcZ goVDDm'>PPDT</td>
                      <td class='sc-ckVGcZ goVDDm'>33%</td>
                      <td class='sc-ckVGcZ goVDDm'>12%</td>
                    </tr>
                    <th colspan='3' class='sc-iwsKbI iyNBqF'>
                      110% Planned, 85% Resourced
                    </th>
                  </tbody>
                </Table>
              </Cell>
              <Cell small={4}>
                <Table
                  style={{
                    boxShadow: '0 1px 10px rgba(0,0,0,.05)',
                    border: '1px solid #efefef',
                  }}
                >
                  <thead>
                    <tr>
                      <th colSpan='3' style={{ backgroundColor: 'white' }}>
                        <img
                          src='https://proxy.harvestfiles.com/production_harvestapp_public/uploads/users/avatar/001/764/811/normal.png?1501777153'
                          alt='Hannah Deason-Schroeder'
                        />
                        <br />
                        <br />
                        Hannah Deason
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class='sc-dxgOiQ knedPp'>Projects</td>
                      <td class='sc-dxgOiQ knedPp'>Forecast</td>
                      <td class='sc-dxgOiQ knedPp'>Actual</td>
                    </tr>
                    <tr>
                      <td class='sc-ckVGcZ goVDDm'>WCUX</td>
                      <td class='sc-ckVGcZ goVDDm'>30%</td>
                      <td class='sc-ckVGcZ goVDDm'>22%</td>
                    </tr>
                    <tr>
                      <td class='sc-ckVGcZ goVDDm'>CARA UPDATES</td>
                      <td class='sc-ckVGcZ goVDDm'>55%</td>
                      <td class='sc-ckVGcZ goVDDm'>60%</td>
                    </tr>
                    <tr>
                      <td class='sc-ckVGcZ goVDDm'>PPDT</td>
                      <td class='sc-ckVGcZ goVDDm'>33%</td>
                      <td class='sc-ckVGcZ goVDDm'>12%</td>
                    </tr>
                    <th colspan='3' class='sc-iwsKbI iyNBqF'>
                      110% Planned, 85% Resourced
                    </th>
                  </tbody>
                </Table>
              </Cell>
              <Cell small={4}>
                <Table
                  style={{
                    boxShadow: '0 1px 10px rgba(0,0,0,.05)',
                    border: '1px solid #efefef',
                  }}
                >
                  <thead>
                    <tr>
                      <th colSpan='3' style={{ backgroundColor: 'white' }}>
                        <img
                          src='https://proxy.harvestfiles.com/production_harvestapp_public/uploads/users/avatar/001/764/811/normal.png?1501777153'
                          alt='Hannah Deason-Schroeder'
                        />
                        <br />
                        <br />
                        Hannah Deason
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class='sc-dxgOiQ knedPp'>Projects</td>
                      <td class='sc-dxgOiQ knedPp'>Forecast</td>
                      <td class='sc-dxgOiQ knedPp'>Actual</td>
                    </tr>
                    <tr>
                      <td class='sc-ckVGcZ goVDDm'>WCUX</td>
                      <td class='sc-ckVGcZ goVDDm'>30%</td>
                      <td class='sc-ckVGcZ goVDDm'>22%</td>
                    </tr>
                    <tr>
                      <td class='sc-ckVGcZ goVDDm'>CARA UPDATES</td>
                      <td class='sc-ckVGcZ goVDDm'>55%</td>
                      <td class='sc-ckVGcZ goVDDm'>60%</td>
                    </tr>
                    <tr>
                      <td class='sc-ckVGcZ goVDDm'>PPDT</td>
                      <td class='sc-ckVGcZ goVDDm'>33%</td>
                      <td class='sc-ckVGcZ goVDDm'>12%</td>
                    </tr>
                    <th colspan='3' class='sc-iwsKbI iyNBqF'>
                      110% Planned, 85% Resourced
                    </th>
                  </tbody>
                </Table>
              </Cell>
              <Cell small={4}>
                <Table
                  style={{
                    boxShadow: '0 1px 10px rgba(0,0,0,.05)',
                    border: '1px solid #efefef',
                  }}
                >
                  <thead>
                    <tr>
                      <th colSpan='3' style={{ backgroundColor: 'white' }}>
                        <img
                          src='https://proxy.harvestfiles.com/production_harvestapp_public/uploads/users/avatar/001/764/811/normal.png?1501777153'
                          alt='Hannah Deason-Schroeder'
                        />
                        <br />
                        <br />
                        Hannah Deason
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class='sc-dxgOiQ knedPp'>Projects</td>
                      <td class='sc-dxgOiQ knedPp'>Forecast</td>
                      <td class='sc-dxgOiQ knedPp'>Actual</td>
                    </tr>
                    <tr>
                      <td class='sc-ckVGcZ goVDDm'>WCUX</td>
                      <td class='sc-ckVGcZ goVDDm'>30%</td>
                      <td class='sc-ckVGcZ goVDDm'>22%</td>
                    </tr>
                    <tr>
                      <td class='sc-ckVGcZ goVDDm'>CARA UPDATES</td>
                      <td class='sc-ckVGcZ goVDDm'>55%</td>
                      <td class='sc-ckVGcZ goVDDm'>60%</td>
                    </tr>
                    <tr>
                      <td class='sc-ckVGcZ goVDDm'>PPDT</td>
                      <td class='sc-ckVGcZ goVDDm'>33%</td>
                      <td class='sc-ckVGcZ goVDDm'>12%</td>
                    </tr>
                    <th colspan='3' class='sc-iwsKbI iyNBqF'>
                      110% Planned, 85% Resourced
                    </th>
                  </tbody>
                </Table>
              </Cell>
              <Cell small={4}>
                <Table
                  style={{
                    boxShadow: '0 1px 10px rgba(0,0,0,.05)',
                    border: '1px solid #efefef',
                  }}
                >
                  <thead>
                    <tr>
                      <th colSpan='3' style={{ backgroundColor: 'white' }}>
                        <img
                          src='https://proxy.harvestfiles.com/production_harvestapp_public/uploads/users/avatar/001/764/811/normal.png?1501777153'
                          alt='Hannah Deason-Schroeder'
                        />
                        <br />
                        <br />
                        Hannah Deason
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class='sc-dxgOiQ knedPp'>Projects</td>
                      <td class='sc-dxgOiQ knedPp'>Forecast</td>
                      <td class='sc-dxgOiQ knedPp'>Actual</td>
                    </tr>
                    <tr>
                      <td class='sc-ckVGcZ goVDDm'>WCUX</td>
                      <td class='sc-ckVGcZ goVDDm'>30%</td>
                      <td class='sc-ckVGcZ goVDDm'>22%</td>
                    </tr>
                    <tr>
                      <td class='sc-ckVGcZ goVDDm'>CARA UPDATES</td>
                      <td class='sc-ckVGcZ goVDDm'>55%</td>
                      <td class='sc-ckVGcZ goVDDm'>60%</td>
                    </tr>
                    <tr>
                      <td class='sc-ckVGcZ goVDDm'>PPDT</td>
                      <td class='sc-ckVGcZ goVDDm'>33%</td>
                      <td class='sc-ckVGcZ goVDDm'>12%</td>
                    </tr>
                    <th colspan='3' class='sc-iwsKbI iyNBqF'>
                      110% Planned, 85% Resourced
                    </th>
                  </tbody>
                </Table>
              </Cell>
              <Cell small={4}>
                <Table
                  style={{
                    boxShadow: '0 1px 10px rgba(0,0,0,.05)',
                    border: '1px solid #efefef',
                  }}
                >
                  <thead>
                    <tr>
                      <th colSpan='3' style={{ backgroundColor: 'white' }}>
                        <img
                          src='https://proxy.harvestfiles.com/production_harvestapp_public/uploads/users/avatar/001/764/811/normal.png?1501777153'
                          alt='Hannah Deason-Schroeder'
                        />
                        <br />
                        <br />
                        Hannah Deason
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class='sc-dxgOiQ knedPp'>Projects</td>
                      <td class='sc-dxgOiQ knedPp'>Forecast</td>
                      <td class='sc-dxgOiQ knedPp'>Actual</td>
                    </tr>
                    <tr>
                      <td class='sc-ckVGcZ goVDDm'>WCUX</td>
                      <td class='sc-ckVGcZ goVDDm'>30%</td>
                      <td class='sc-ckVGcZ goVDDm'>22%</td>
                    </tr>
                    <tr>
                      <td class='sc-ckVGcZ goVDDm'>CARA UPDATES</td>
                      <td class='sc-ckVGcZ goVDDm'>55%</td>
                      <td class='sc-ckVGcZ goVDDm'>60%</td>
                    </tr>
                    <tr>
                      <td class='sc-ckVGcZ goVDDm'>PPDT</td>
                      <td class='sc-ckVGcZ goVDDm'>33%</td>
                      <td class='sc-ckVGcZ goVDDm'>12%</td>
                    </tr>
                    <th colspan='3' class='sc-iwsKbI iyNBqF'>
                      110% Planned, 85% Resourced
                    </th>
                  </tbody>
                </Table>
              </Cell>
              <Cell small={4}>
                <Table
                  style={{
                    boxShadow: '0 1px 10px rgba(0,0,0,.05)',
                    border: '1px solid #efefef',
                  }}
                >
                  <thead>
                    <tr>
                      <th colSpan='3' style={{ backgroundColor: 'white' }}>
                        <img
                          src='https://proxy.harvestfiles.com/production_harvestapp_public/uploads/users/avatar/001/764/811/normal.png?1501777153'
                          alt='Hannah Deason-Schroeder'
                        />
                        <br />
                        <br />
                        Hannah Deason
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class='sc-dxgOiQ knedPp'>Projects</td>
                      <td class='sc-dxgOiQ knedPp'>Forecast</td>
                      <td class='sc-dxgOiQ knedPp'>Actual</td>
                    </tr>
                    <tr>
                      <td class='sc-ckVGcZ goVDDm'>WCUX</td>
                      <td class='sc-ckVGcZ goVDDm'>30%</td>
                      <td class='sc-ckVGcZ goVDDm'>22%</td>
                    </tr>
                    <tr>
                      <td class='sc-ckVGcZ goVDDm'>CARA UPDATES</td>
                      <td class='sc-ckVGcZ goVDDm'>55%</td>
                      <td class='sc-ckVGcZ goVDDm'>60%</td>
                    </tr>
                    <tr>
                      <td class='sc-ckVGcZ goVDDm'>PPDT</td>
                      <td class='sc-ckVGcZ goVDDm'>33%</td>
                      <td class='sc-ckVGcZ goVDDm'>12%</td>
                    </tr>
                    <th colspan='3' class='sc-iwsKbI iyNBqF'>
                      110% Planned, 85% Resourced
                    </th>
                  </tbody>
                </Table>
              </Cell>
              <Cell small={4}>
                <Table
                  style={{
                    boxShadow: '0 1px 10px rgba(0,0,0,.05)',
                    border: '1px solid #efefef',
                  }}
                >
                  <thead>
                    <tr>
                      <th colSpan='3' style={{ backgroundColor: 'white' }}>
                        <img
                          src='https://proxy.harvestfiles.com/production_harvestapp_public/uploads/users/avatar/001/764/811/normal.png?1501777153'
                          alt='Hannah Deason-Schroeder'
                        />
                        <br />
                        <br />
                        Hannah Deason
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class='sc-dxgOiQ knedPp'>Projects</td>
                      <td class='sc-dxgOiQ knedPp'>Forecast</td>
                      <td class='sc-dxgOiQ knedPp'>Actual</td>
                    </tr>
                    <tr>
                      <td class='sc-ckVGcZ goVDDm'>WCUX</td>
                      <td class='sc-ckVGcZ goVDDm'>30%</td>
                      <td class='sc-ckVGcZ goVDDm'>22%</td>
                    </tr>
                    <tr>
                      <td class='sc-ckVGcZ goVDDm'>CARA UPDATES</td>
                      <td class='sc-ckVGcZ goVDDm'>55%</td>
                      <td class='sc-ckVGcZ goVDDm'>60%</td>
                    </tr>
                    <tr>
                      <td class='sc-ckVGcZ goVDDm'>PPDT</td>
                      <td class='sc-ckVGcZ goVDDm'>33%</td>
                      <td class='sc-ckVGcZ goVDDm'>12%</td>
                    </tr>
                    <th colspan='3' class='sc-iwsKbI iyNBqF'>
                      110% Planned, 85% Resourced
                    </th>
                  </tbody>
                </Table>
              </Cell>
              <Cell small={4}>
                <Table
                  style={{
                    boxShadow: '0 1px 10px rgba(0,0,0,.05)',
                    border: '1px solid #efefef',
                  }}
                >
                  <thead>
                    <tr>
                      <th colSpan='3' style={{ backgroundColor: 'white' }}>
                        <img
                          src='https://proxy.harvestfiles.com/production_harvestapp_public/uploads/users/avatar/001/764/811/normal.png?1501777153'
                          alt='Hannah Deason-Schroeder'
                        />
                        <br />
                        <br />
                        Hannah Deason
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class='sc-dxgOiQ knedPp'>Projects</td>
                      <td class='sc-dxgOiQ knedPp'>Forecast</td>
                      <td class='sc-dxgOiQ knedPp'>Actual</td>
                    </tr>
                    <tr>
                      <td class='sc-ckVGcZ goVDDm'>WCUX</td>
                      <td class='sc-ckVGcZ goVDDm'>30%</td>
                      <td class='sc-ckVGcZ goVDDm'>22%</td>
                    </tr>
                    <tr>
                      <td class='sc-ckVGcZ goVDDm'>CARA UPDATES</td>
                      <td class='sc-ckVGcZ goVDDm'>55%</td>
                      <td class='sc-ckVGcZ goVDDm'>60%</td>
                    </tr>
                    <tr>
                      <td class='sc-ckVGcZ goVDDm'>PPDT</td>
                      <td class='sc-ckVGcZ goVDDm'>33%</td>
                      <td class='sc-ckVGcZ goVDDm'>12%</td>
                    </tr>
                    <th colspan='3' class='sc-iwsKbI iyNBqF'>
                      110% Planned, 85% Resourced
                    </th>
                  </tbody>
                </Table>
              </Cell>
              <Cell small={4}>
                <Table
                  style={{
                    boxShadow: '0 1px 10px rgba(0,0,0,.05)',
                    border: '1px solid #efefef',
                  }}
                >
                  <thead>
                    <tr>
                      <th colSpan='3' style={{ backgroundColor: 'white' }}>
                        <img
                          src='https://proxy.harvestfiles.com/production_harvestapp_public/uploads/users/avatar/001/764/811/normal.png?1501777153'
                          alt='Hannah Deason-Schroeder'
                        />
                        <br />
                        <br />
                        Hannah Deason
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class='sc-dxgOiQ knedPp'>Projects</td>
                      <td class='sc-dxgOiQ knedPp'>Forecast</td>
                      <td class='sc-dxgOiQ knedPp'>Actual</td>
                    </tr>
                    <tr>
                      <td class='sc-ckVGcZ goVDDm'>WCUX</td>
                      <td class='sc-ckVGcZ goVDDm'>30%</td>
                      <td class='sc-ckVGcZ goVDDm'>22%</td>
                    </tr>
                    <tr>
                      <td class='sc-ckVGcZ goVDDm'>CARA UPDATES</td>
                      <td class='sc-ckVGcZ goVDDm'>55%</td>
                      <td class='sc-ckVGcZ goVDDm'>60%</td>
                    </tr>
                    <tr>
                      <td class='sc-ckVGcZ goVDDm'>PPDT</td>
                      <td class='sc-ckVGcZ goVDDm'>33%</td>
                      <td class='sc-ckVGcZ goVDDm'>12%</td>
                    </tr>
                    <th colspan='3' class='sc-iwsKbI iyNBqF'>
                      110% Planned, 85% Resourced
                    </th>
                  </tbody>
                </Table>
              </Cell>
              <Cell small={4}>
                <Table
                  style={{
                    boxShadow: '0 1px 10px rgba(0,0,0,.05)',
                    border: '1px solid #efefef',
                  }}
                >
                  <thead>
                    <tr>
                      <th colSpan='3' style={{ backgroundColor: 'white' }}>
                        <img
                          src='https://proxy.harvestfiles.com/production_harvestapp_public/uploads/users/avatar/001/764/811/normal.png?1501777153'
                          alt='Hannah Deason-Schroeder'
                        />
                        <br />
                        <br />
                        Hannah Deason
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class='sc-dxgOiQ knedPp'>Projects</td>
                      <td class='sc-dxgOiQ knedPp'>Forecast</td>
                      <td class='sc-dxgOiQ knedPp'>Actual</td>
                    </tr>
                    <tr>
                      <td class='sc-ckVGcZ goVDDm'>WCUX</td>
                      <td class='sc-ckVGcZ goVDDm'>30%</td>
                      <td class='sc-ckVGcZ goVDDm'>22%</td>
                    </tr>
                    <tr>
                      <td class='sc-ckVGcZ goVDDm'>CARA UPDATES</td>
                      <td class='sc-ckVGcZ goVDDm'>55%</td>
                      <td class='sc-ckVGcZ goVDDm'>60%</td>
                    </tr>
                    <tr>
                      <td class='sc-ckVGcZ goVDDm'>PPDT</td>
                      <td class='sc-ckVGcZ goVDDm'>33%</td>
                      <td class='sc-ckVGcZ goVDDm'>12%</td>
                    </tr>
                    <th colspan='3' class='sc-iwsKbI iyNBqF'>
                      110% Planned, 85% Resourced
                    </th>
                  </tbody>
                </Table>
              </Cell>
            </Grid>
            {this.pagination()}
          </GridContainer>
        </MainContainer>
      </div>
    );
  }

  pagination() {
    const view = this.props.data['hydra:view'];
    if (!view) return;

    const total = this.props.data['hydra:totalItems'];
    const perPage = 30;

    const pages = Math.ceil(total / perPage);

    const {
      'hydra:first': first,
      'hydra:previous': previous,
      'hydra:next': next,
      'hydra:last': last,
      '@id': current,
    } = view;

    const curPageMatch = /page=(\d+)/i;
    const matches = current.match(curPageMatch);
    const curPage = parseInt(matches[1]);

    return (
      <Pagination aria-label='Pagination' isCentered>
        <PaginationPrevious isDisabled={curPage === 1}>
          {curPage !== 1 ? (
            <Link to={!previous || previous === first ? '.' : encodeURIComponent(stripApiPath(previous))}>
              Previous <Inline showForSr>page</Inline>
            </Link>
          ) : (
            <span>
              Previous <Inline showForSr>page</Inline>
            </span>
          )}
        </PaginationPrevious>
        {Array.from(Array(pages).keys()).map((page) => (
          <PaginationItem isCurrent={page + 1 === curPage}>
            {curPage !== page + 1 ? (
              <Link to={getPaginationLink(current, page + 1)}>{page + 1}</Link>
            ) : (
              <span>{page + 1}</span>
            )}
          </PaginationItem>
        ))}
        <PaginationNext isDisabled={curPage === pages}>
          {curPage !== pages ? (
            <Link to={next ? encodeURIComponent(stripApiPath(next, '')) : '#'} aria-label='Next page'>
              Next <Inline showForSr>page</Inline>
            </Link>
          ) : (
            <span>
              Next <Inline showForSr>page</Inline>
            </span>
          )}
        </PaginationNext>
      </Pagination>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.project.list.data,
    error: state.project.list.error,
    loading: state.project.list.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    list: (page) => dispatch(list(page)),
    reset: () => {
      dispatch(reset());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(List);

import { createSelector, createStructuredSelector } from 'reselect';
import { getProjectDomain } from 'selectors';

const getDomain = createSelector(getProjectDomain, (state) => state.get('notes'));

export const selectError = createSelector(getDomain, (state) => state.get('error'));
export const selectLoading = createSelector(getDomain, (state) => state.get('loading'));
export const selectData = createSelector(getDomain, (state) => state.get('data'));
export const selectSaving = createSelector(getDomain, (state) => state.get('saving'));
export const selectCreateError = createSelector(getDomain, (state) => state.get('createError'));

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import ProjectInvoices from 'components/ProjectInvoices';
import { toJS } from 'utils/toJS';
import { selectError, selectData, selectLoading } from 'routes/InvoicesContainer/selectors';
import { load, reset } from 'routes/InvoicesContainer/actions';

const mapStateToProps = (state, ownProps) => ({
  project: ownProps.project,
  data: selectData(state),
  error: selectError(state),
  loading: selectLoading(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  load: () => dispatch(load(ownProps.project.id)),
  reset: () => dispatch(reset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(toJS(ProjectInvoices));

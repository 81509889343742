import React from 'react';
import PropTypes from 'prop-types';
import { Callout, Colors } from 'react-foundation';

function ErrorMessage({ error, title = 'An error has occurred', afterContent = null }) {
  return (
    <Callout color={Colors.ALERT}>
      <h5>
        <span className='fa fa-exclamation-triangle' aria-hidden='true' /> {title}
      </h5>
      <p>{error}</p>
      {afterContent}
    </Callout>
  );
}

ErrorMessage.propTypes = {
  error: PropTypes.node.isRequired,
  title: PropTypes.node,
  afterContent: PropTypes.node,
};

export default ErrorMessage;

import { combineReducers } from 'redux-immutable';
import { fromJS } from 'immutable';
import { REQUEST_ERROR, REQUEST_LOADING, REQUEST_SUCCESS, RESET } from 'routes/InvoicesContainer/actions';

export function error(state = null, action) {
  switch (action.type) {
    case REQUEST_ERROR:
      return action.error;

    case RESET:
      return null;

    default:
      return state;
  }
}

export function loading(state = false, action) {
  switch (action.type) {
    case REQUEST_LOADING:
      return action.loading;

    case RESET:
      return false;

    default:
      return state;
  }
}

export function data(state = null, action) {
  switch (action.type) {
    case REQUEST_SUCCESS:
      return fromJS(action.payload);

    case RESET:
      return null;

    default:
      return state;
  }
}

export default combineReducers({ error, loading, data });

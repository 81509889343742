import React from 'react';
import { projectShape } from 'utils/shapes/project';
import styled from 'styled-components/macro';
import ReactSVG from 'react-svg';
import iconEmail from './icon-email.svg';
import ProfileImage from 'components/ProfileImage';
import TeamMemberRow from 'components/TeamMemberRow';

const PersonDetails = styled.div`
  flex: 1 0 auto;
`;

const PersonName = styled.h5`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.2px;
  margin-bottom: 0;
  color: ${(props) => props.theme.titleColorPrimaryPrimary};
`;

const Roles = styled.ul`
  list-style: none;
  margin-left: 0;
  margin-bottom: 0;
  padding-left: 0;

  li {
    font-size: 11px;
    font-weight: 500;
    letter-spacing: -0.1px;
    color: ${(props) => props.theme.colorGrayWarm};
    text-transform: uppercase;
  }
`;

const Email = styled.p`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.2px;
  color: ${(props) => props.theme.colorGrayDark};
  margin-bottom: 0;

  .icon-svg {
    display: inline-block;
    margin-right: 0.375em;
  }
`;

const StyledSVG = styled(ReactSVG)`
  display: inline-block;
`;

function TeamMembers({ project }) {
  if (!project.projectAssignments || project.projectAssignments.length === 0) {
    return <p>No team members assigned to this project.</p>;
  }

  return project.projectAssignments.map(({ person, roles }) => (
    <TeamMemberRow>
      <ProfileImage person={person} />
      <PersonDetails>
        <PersonName>{`${person.first_name} ${person.last_name}`}</PersonName>
        <Roles>
          {roles.map((role) => (
            <li>{role.name}</li>
          ))}
        </Roles>
        <Email>
          <a href={`mailto:${person.email}`}>
            <StyledSVG path={iconEmail} wrapperClassName='icon-email icon-svg' />
            {person.email}
          </a>
        </Email>
      </PersonDetails>
    </TeamMemberRow>
  ));
}

TeamMembers.propTypes = {
  project: projectShape.isRequired,
};

export default TeamMembers;

import { createSelector } from 'reselect';

const rootDomain = (state) => state.get('admin');

export const selectSyncStatus = createSelector(rootDomain, (admin) => admin.get('syncStatus'));
export const selectHarvestResync = createSelector(rootDomain, (admin) => admin.get('harvestResync'));
export const selectHarvestResyncLoading = createSelector(
  selectHarvestResync,
  (subState) => subState && subState.get('loading')
);
export const selectJiraResync = createSelector(rootDomain, (admin) => admin.get('jiraResync'));
export const selectJiraResyncLoading = createSelector(
  selectJiraResync,
  (subState) => subState && subState.get('loading')
);

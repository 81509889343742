import React from 'react';
import { createStructuredSelector } from 'reselect';
import { toJS } from 'utils/toJS';
import { connect } from 'react-redux';
import { setDateFilters, setDatePreset } from '../reducer';
import { selectDateFilters } from '../selectors';
import DateRangeFilter from 'components/DateRangeFilter';

const mapStateToProps = createStructuredSelector({
  filters: selectDateFilters,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setDateFilters: (startDate, endDate) => dispatch(setDateFilters(startDate, endDate)),
    setDatePreset: (preset) => dispatch(setDatePreset(preset)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(toJS(DateRangeFilter));

import { Callout, Colors, GridContainer } from 'react-foundation';
import H1 from 'components/H1';
import React from 'react';
import useQueryProject from 'hooks/graphql/useQueryProject/useQueryProject';
import ErrorMessage from 'components/ErrorMessage';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import CreateInvoice from 'routes/ProjectPage/routes/Invoices/components/CreateInvoice/CreateInvoice';
import Divider from 'components/Divider/Divider';

const PageWrapper = styled.div`
  margin-top: 1rem;
`;

export default function Invoices({ match, ...otherProps }) {
  const projectId = parseInt(decodeURIComponent(match.params.id), 10);
  const { data, loading, error } = useQueryProject(projectId);

  if (loading) {
    return (
      <div>
        <Callout color={Colors.SECONDARY}>
          <p>Loading...</p>
        </Callout>
      </div>
    );
  }

  if (error) {
    return (
      <ErrorMessage
        title='Error loading project invoices'
        error={error.message}
        afterContent={
          <Link to={`/projects/show/${projectId}`} className='btn btn-default'>
            Back to project page
          </Link>
        }
      />
    );
  }

  const project = data?.project;

  if (!project) {
    return null;
  }

  return (
    <PageWrapper>
      <GridContainer>
        <Link to={`/projects/show/${projectId}`} className='btn btn-default mt-4'>
          &larr; Back to project page
        </Link>
        <H1>{project.name} Invoices</H1>
      </GridContainer>
      <Divider />
      <GridContainer>
        <CreateInvoice project={project} />
      </GridContainer>
    </PageWrapper>
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';

const defaultProps = {
  style: 'decimal',
  useGrouping: true,
  minimumIntegerDigits: 1,
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
};

function NumberStat({ value, suffix = null, ...otherProps }) {
  const props = { ...defaultProps, ...otherProps };
  return (
    <span>
      <FormattedNumber value={value} {...props} />
      {suffix}
    </span>
  );
}

NumberStat.propTypes = {
  value: PropTypes.any.isRequired,
  suffix: PropTypes.node,
};

export default NumberStat;

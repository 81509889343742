import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { commentShape, projectShape } from 'utils/shapes/project';
import LoadingIndicator from 'components/LoadingIndicator';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import CommentForm from './CommentForm';
import CommentList from './CommentList';

const Wrapper = styled.div``;

class ProjectNotes extends Component {
  static propTypes = {
    project: projectShape,
    loading: PropTypes.bool,
    error: PropTypes.string,
    data: PropTypes.arrayOf(commentShape),
    load: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    submitComment: PropTypes.func.isRequired,
    saving: PropTypes.bool,
    createError: PropTypes.string,
  };

  componentDidMount() {
    // Load project notes.
    if (!this.props.data && !this.props.loading) {
      this.props.load();
    }
  }

  componentWillUnmount() {
    this.props.reset();
  }

  render() {
    const { loading, error, data, saving, createError, submitComment } = this.props;

    if (loading) {
      return <LoadingIndicator />;
    }

    if (error || createError) {
      return <ErrorMessage error={error || createError} />;
    }

    return (
      <Wrapper>
        <CommentForm onSubmit={submitComment} saving={saving} formError={createError} />
        <CommentList comments={data} />
      </Wrapper>
    );
  }
}

export default ProjectNotes;

import { combineReducers } from 'redux-immutable';
import { fromJS } from 'immutable';
import {
  CREATE_ERROR,
  CREATE_SUCCESS,
  REQUEST_ERROR,
  REQUEST_LOADING,
  REQUEST_SAVING,
  REQUEST_SUCCESS,
  RESET,
} from 'routes/ProjectPage/containers/ProjectNotesContainer/actions';

export function error(state = null, action) {
  switch (action.type) {
    case REQUEST_ERROR:
      return action.error;

    case RESET:
      return null;

    default:
      return state;
  }
}

export function loading(state = false, action) {
  switch (action.type) {
    case REQUEST_LOADING:
      return action.loading;

    case RESET:
      return false;

    default:
      return state;
  }
}

export function saving(state = false, action) {
  switch (action.type) {
    case REQUEST_SAVING:
      return fromJS(action.saving);

    case RESET:
      return false;

    default:
      return state;
  }
}

export function data(state = null, action) {
  switch (action.type) {
    case REQUEST_SUCCESS:
      return fromJS(action.payload);

    case CREATE_SUCCESS:
      return state.unshift(fromJS(action.payload));

    case RESET:
      return null;

    default:
      return state;
  }
}

export function createError(state = null, action) {
  switch (action.type) {
    case CREATE_ERROR:
      return fromJS(action.error);

    case RESET:
      return null;

    default:
      return state;
  }
}

export default combineReducers({ error, loading, data, saving, createError });

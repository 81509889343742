import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import H4 from 'components/H4';
import { ifProp } from 'styled-tools';
import HtmlTooltip from 'routes/DashboardPage/components/HtmlTooltip';
import InfoIcon from '@material-ui/icons/Info';
import Box from '@material-ui/core/Box';

const BannerStatWrapper = styled.div`
  width: 100%;
`;

const StatContainer = styled.div`
  width: 100%;
  min-height: 95px;
  border-radius: 3px;
  background-color: #ffffff;

  ${ifProp(
    '$numberStat',
    css`
      display: flex;
      flex-direction: column;
      justify-content: center;
    `
  )}
`;

const StatMain = styled.div`
  width: 100%;
  font-family: ${(props) => props.theme.titleFontFamily};
  font-size: ${ifProp('$numberStat', '2.25rem', '1.75rem')};
  font-weight: 500;
  letter-spacing: 0.8px;
  text-align: center;
  color: ${(props) => (props.red ? '#ba1933' : '#084159')};
  padding: ${(props) => (props.chart ? '10px 0 0 0' : '10px 0')};
  text-transform: uppercase;
  border-bottom: ${ifProp('$divider', 'solid 1px #f4f1ef', 'none')};
`;

const StatSecondary = styled.div`
  width: 100%;
  font-family: ${(props) => props.theme.titleFontFamily};
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.4px;
  text-align: center;
  padding: 10px 0;
  color: #f99b1c;
  text-transform: uppercase;
`;

function BannerStat({
  title,
  main,
  secondary,
  tooltip,
  red = false,
  chart = false,
  secondaryTitleStyle = false,
  numberStat = false,
}) {
  return (
    <BannerStatWrapper>
      <H4 center white={secondaryTitleStyle}>
        <Box display='flex' alignItems='center' justifyContent='center'>
          {title}
          {tooltip && (
            <Box ml={1} pt={0.25}>
              <HtmlTooltip title={tooltip} arrow placement='right' interactive>
                <InfoIcon fontSize='inherit' />
              </HtmlTooltip>
            </Box>
          )}
        </Box>
      </H4>
      <StatContainer $numberStat={numberStat}>
        <StatMain red={red} chart={chart} $divider={!!secondary} $numberStat={numberStat}>
          {main}
        </StatMain>
        {secondary && <StatSecondary>{secondary}</StatSecondary>}
      </StatContainer>
    </BannerStatWrapper>
  );
}

BannerStat.propTypes = {
  title: PropTypes.string,
  main: PropTypes.node,
  secondary: PropTypes.node,
  tooltip: PropTypes.node,
  red: PropTypes.bool,
  chart: PropTypes.bool,
  secondaryTitleStyle: PropTypes.bool,
  numberStat: PropTypes.bool,
};

export default BannerStat;

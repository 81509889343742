import React from 'react';
import DashboardPage from './DashboardPage';
import ProjectPage from './ProjectPage';
import PeoplePage from './PeoplePage';
import AuthRoute from 'components/AuthRoute';
import Invoices from 'routes/ProjectPage/routes/Invoices';

export default [
  <AuthRoute path='/projects/show/:id/invoices' component={Invoices} exact={true} key='invoices' />,
  <AuthRoute path='/projects/show/:id' component={ProjectPage} exact={true} key='show' />,
  <AuthRoute path='/team' component={PeoplePage} exact={true} key='team' />,
  <AuthRoute path='/' component={DashboardPage} exact={true} strict={true} key='list' />,
];

import React from 'react';
import { isAuthenticated } from 'utils/auth';
import styled from 'styled-components/macro';
import { GridContainer, Menu, TopBar, Colors, TopBarLeft, MenuItem, Grid, TopBarRight } from 'react-foundation';
import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import Button from 'components/Button';
import logo from './logo.svg';
import LogoutContainer from 'routes/LogoutContainer';
import ProjectSelector from 'containers/ProjectSelector';
import ReportSelector from 'containers/ReportSelector';

const StyledTopBar = styled(TopBar)`
  border-bottom: 1px solid #e1e1e1;

  &,
  ul {
    background-color: white;
  }
`;

const StyledGridContainer = styled(GridContainer)`
  width: 100%;
`;

const LogoLink = styled(Link)`
  .menu & {
    padding-left: 0;
  }
`;

const ButtonMenu = styled(Menu)`
  li {
    margin-right: 11px;

    &:last-of-type {
      margin-right: 0;
    }
  }
`;

function Header({}) {
  const authenticated = isAuthenticated();
  return (
    <StyledTopBar>
      <StyledGridContainer>
        <Grid>
          <TopBarLeft>
            <Menu>
              <MenuItem>
                <LogoLink to='/'>
                  <img src={logo} alt='Caxy Pulse Check' />
                </LogoLink>
              </MenuItem>
            </Menu>
          </TopBarLeft>
          {authenticated && (
            <TopBarRight>
              <ButtonMenu>
                <MenuItem>
                  <ReportSelector />
                </MenuItem>
                <MenuItem>
                  <ProjectSelector />
                </MenuItem>
                <MenuItem>
                  <LinkContainer to='/admin-dashboard'>
                    <Button color={Colors.SECONDARY} isHollow>
                      Admin
                    </Button>
                  </LinkContainer>
                </MenuItem>
                <MenuItem>
                  <LogoutContainer />
                </MenuItem>
              </ButtonMenu>
            </TopBarRight>
          )}
        </Grid>
      </StyledGridContainer>
    </StyledTopBar>
  );
}

Header.propTypes = {};

export default Header;

import React from 'react';
import { isAuthenticated } from 'utils/auth';
import { Redirect, Route } from 'react-router-dom';

function AuthRoute({ component: Component, condition = true, redirect = '/login', ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() === condition ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: redirect,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

export default AuthRoute;

import { string, bool, shape, arrayOf, number, oneOfType, object, oneOf } from 'prop-types';
import { clientContactShape, userShape } from 'utils/shapes/person';
import { BILLING_CYCLE_MONTHLY, BILLING_CYCLE_SEMIMONTHLY } from 'utils/constants';

export const clientShape = shape({
  id: number,
  type: string,
  name: string,
  isActive: bool,
});

export const budgetTypeShape = shape({
  id: number,
  name: string,
  description: string,
  billableBeyondBudget: bool,
});

export const resourceLinkShape = shape({
  id: number,
  linkText: string,
  url: string,
});

export const projectShape = shape({
  status: string,
  billingCycle: oneOf([BILLING_CYCLE_MONTHLY, BILLING_CYCLE_SEMIMONTHLY]),
  jiraProject: string,
  client: oneOfType([string, clientShape]),
  budgetType: oneOfType([string, budgetTypeShape]),
  internallyBilledHours: number,
  targetLaunchDate: string,
  resourceLinks: oneOfType([arrayOf(shape(resourceLinkShape)), arrayOf(string), arrayOf(object)]),
  projectAssignments: arrayOf(string),
  kickoffDate: string,
  name: string,
  code: string,
  isBillable: bool,
  isFixedFee: bool,
  hourlyRate: number,
  budget: number,
  costBudget: number,
  fee: number,
  startsOn: string,
  endsOn: string,
  currentBudget: number,
  billableBudget: number,
  budgetSummaryContacts: arrayOf(clientContactShape),
  dailyRecapContacts: arrayOf(clientContactShape),
});

export const commentShape = shape({
  message: string,
  createdAt: string,
  updatedAt: string,
  createdBy: userShape,
  updatedBy: userShape,
  project: projectShape,
});

export const invoiceShape = shape({});
